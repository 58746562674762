import React, { CSSProperties, ReactNode, useRef, useState, MouseEventHandler } from "react";

import { GtTooltip, TooltipContentType } from "./gt-tooltip";
import Popper from "popper.js";

interface GtTooltipHOCProps {
    children: ReactNode;
    tooltipContent: TooltipContentType;
    placement?: Popper.Placement;
    style?: CSSProperties;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const TooltipHOC = ({ children, tooltipContent, placement, style = {}, onClick = undefined }: GtTooltipHOCProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => setIsHovered(true);

    const handleMouseLeave = () => setIsHovered(false);

    return (
        <>
            <GtTooltip targetRef={ref} isOpen={isHovered} tooltipContent={tooltipContent} placement={placement} />
            <div onClick={onClick} style={style} ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {children}
            </div>
        </>
    );
};
