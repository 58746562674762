import React, { createContext, useContext, useState } from "react";
import { TMode } from "../content";

export const ElnTemplatesContext = createContext<any>(null);

export const ElnTemplatesContextProvider = ({ children }) => {
    const [selectedElnTemplates, setSelectedElnTemplates] = useState<any[]>([]);
    const [spreadsheetConfig, setSpreadsheetConfig] = useState<ISpreadsheetConfig>();
    const [mode, setMode] = useState<TMode>("view");

    return (
        <ElnTemplatesContext.Provider
            value={{ selectedElnTemplates, setSelectedElnTemplates, spreadsheetConfig, setSpreadsheetConfig, mode, setMode }}
        >
            {children}
        </ElnTemplatesContext.Provider>
    );
};

export const useElnTemplates = (): IUseElnTemplates => {
    const { selectedElnTemplates, setSelectedElnTemplates, spreadsheetConfig, setSpreadsheetConfig, mode, setMode } =
        useContext(ElnTemplatesContext);

    return { selectedElnTemplates, setSelectedElnTemplates, spreadsheetConfig, setSpreadsheetConfig, mode, setMode };
};

interface IUseElnTemplates {
    selectedElnTemplates: any[];
    setSelectedElnTemplates: React.Dispatch<React.SetStateAction<any[]>>;
    spreadsheetConfig: ISpreadsheetConfig;
    setSpreadsheetConfig: React.Dispatch<React.SetStateAction<ISpreadsheetConfig>>;
    mode: TMode;
    setMode: React.Dispatch<React.SetStateAction<TMode>>;
}

interface ISpreadsheetConfig {
    columns: number;
    rows: number;
}
