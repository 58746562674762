export const onSelection = (event: any, dataSet: any, selectedRecords: any[], onSelect: (arg: any[]) => void, idSelector: string) => {
    const { originalEvent, value } = event;

    if (Array.isArray(value)) {
        if ((value.length == 1 && selectedRecords && value[0]?.[idSelector] == selectedRecords[0]?.[idSelector]) || !onSelect) return;

        if (originalEvent.shiftKey) {
            const firstRecordIndex = dataSet.indexOf(value[0]);
            const lastRecordIndex = dataSet.indexOf(value[value.length - 1]);

            if (firstRecordIndex < lastRecordIndex) {
                const recordsToSave = dataSet.filter((record, index) => {
                    if (index >= firstRecordIndex && index <= lastRecordIndex) return record;
                });
                onSelect(recordsToSave);
                return;
            }
        }
        onSelect(value);
    } else {
        onSelect(!!value ? [value] : []);
    }
};
