import React, { Children, SyntheticEvent, useState } from "react";
import { createPortal } from "react-dom";
import { Form } from "reactstrap";

import CustomModalRequirement from "../custom-modal-requirement/custom-modal-requirement";
import { IRequirements } from "../../pages/interfaces/IRequirements";
import { IReqParams } from "../../pages/interfaces/IReqParams";
import requirementStatus from "../../utils/requirementStatus";
import { CustomFormContextProvider } from "./context/context";

interface IProps {
    children?: React.ReactNode | React.ReactNode[];
    permCode: string;
    onSubmit: (e: SyntheticEvent, reqParams?: object) => void;
    autoComplete?: string;
    disabled?: string;
    className?: string;
    disableTransactions?: boolean;
    innerRef?: React.MutableRefObject<HTMLFormElement | null>;
    cyId?: string;
}

const CustomForm = ({ children, permCode, onSubmit, autoComplete, disabled, className, disableTransactions = false, innerRef }: IProps) => {
    const [toggleReq, setToggleReq] = useState<boolean>(false);
    const [requirements, setRequirements] = useState({} as IRequirements);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    //! THIS FUNCTION CREATES MEMORY LEAK
    // the problem is that we pass closing function (handleReload or/and closeModal) in onSubmit
    // which unmounts the components before it can set isBusy to false
    // solution is to pass closing function as a prop to this component
    // and fire it if onSubmit returns positive response
    const handleSubmit = async (e: SyntheticEvent, reqParams: IReqParams = {}) => {
        e.preventDefault();

        setIsBusy(true);
        const req: IRequirements = requirementStatus(permCode);
        if (!disableTransactions) {
            if (!req.requirePassword && !req.requireComment) {
                await onSubmit(e, { ...reqParams, permCode });
            } else {
                setToggleReq(true);
            }
            setRequirements(req);
        } else {
            await onSubmit(e, { ...reqParams, permCode });
        }
        setIsBusy(false);
    };

    const childrenWithProps = Children.map(children, (child) => {
        if (!!(child as React.ReactElement<any>)?.props?.okName) {
            return React.cloneElement(child as React.ReactElement<any>, { isBusy });
        }
        return child;
    });

    return (
        <Form
            onSubmit={handleSubmit}
            autoComplete={autoComplete ? autoComplete : "on"}
            disabled={disabled ? disabled : "enabled"}
            className={className}
            innerRef={innerRef}
        >
            <CustomFormContextProvider>
                {childrenWithProps}
                <React.Fragment>
                    {toggleReq &&
                        createPortal(
                            <CustomModalRequirement
                                toggleModal={setToggleReq}
                                isOpen={toggleReq}
                                requirements={requirements}
                                submit={onSubmit}
                                permCode={permCode}
                            />,
                            document.body
                        )}
                </React.Fragment>
            </CustomFormContextProvider>
        </Form>
    );
};

export default CustomForm;
