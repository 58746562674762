import React, { useState, useEffect, useRef, MutableRefObject } from "react";

import { useTranslation } from "react-i18next";

import { IntersectionTabs } from "./responsive-menu/intersectionTabs";
import hasPermission from "../../utils/hasPermissions";
import Content404 from "../../pages/404/content";
import Icon from "components/icon";
import Tabs from "../tabs";

import "./custom-tabs.scss";

const defaultContent = () => (
    <div className="mt-10">
        <Content404 />
    </div>
);

interface ITab {
    name: string;
    permCode?: string;
    editable?: boolean;
    disabled?: boolean;
    hide?: boolean;
    cyId?: string;
}

type TabType = ITab | boolean | undefined;

interface ICustomTabsProps {
    tabs: TabType[];
    tabContent: any;
    selectedTabId?: any;
    value?: string;
    handleChange?: Function;
    handleTabSelection?: Function;
    className?: string;
    contentClassName?: string;
    disabled?: boolean;
    notTranslated?: boolean;
    fillerContent?: any;
    disableFullscreen?: boolean;
    refWrapper?: MutableRefObject<HTMLDivElement | null>;
}

const CustomTabs = (props: ICustomTabsProps) => {
    let {
        tabs,
        tabContent = defaultContent,
        value = undefined,
        handleChange,
        className,
        contentClassName,
        disableFullscreen = false,
        refWrapper = undefined,
    } = props;

    const [validTabs, setValidTabs] = useState<any[]>(
        (tabs as ITab[]).filter((tab) => !tab?.hide && (!tab?.permCode || hasPermission(tab?.permCode)))
    );

    useEffect(() => {
        setValidTabs((tabs as ITab[]).filter((tab) => !tab?.hide && (!tab?.permCode || hasPermission(tab?.permCode))));
    }, [JSON.stringify(tabs)]);

    const [unControlledSelectedTab, setSelectedTab] = useState<string | undefined>(
        value || validTabs.find((tab) => tab?.editable)?.name || validTabs[0]?.name
    );
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const selectedTab = value || unControlledSelectedTab;

    const { t } = useTranslation();

    const handleClick = (tab) => {
        props.handleTabSelection && props.handleTabSelection(tab?.name);
        !!handleChange ? handleChange(tab?.name) : setSelectedTab(tab?.name);
    };

    // State and ref for help context menu
    const [contextMenuOpen, setContextMenuOpen] = useState<number | undefined>(undefined);
    const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const tabRef = useRef<HTMLDivElement>(null);

    // Code to display context menu START
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (tabRef.current && !tabRef.current.contains(e.target)) {
                setContextMenuOpen(undefined);
            }
        };

        const eventTypes = ["contextmenu", "click"];
        eventTypes.forEach((eventType) => {
            document.addEventListener(eventType, handleOutsideClick);
        });

        return () => {
            eventTypes.forEach((eventType) => {
                document.removeEventListener(eventType, handleOutsideClick);
            });
        };
    }, []);

    useEffect(() => {
        contextMenuOpen && setContextMenuOpen(undefined);
    }, [selectedTab]);

    const handleTabContextMenu = (e, i) => {
        e.preventDefault();
        const position = e.target.getBoundingClientRect();
        setPosition({ x: e.pageX - position.x, y: e.pageY - position.y });
        setContextMenuOpen(i);
    };

    const handleFullScreen = () => setIsFullscreen((prev) => !prev);

    return (
        <div className={`tabs-container ${className ? className : ""} ${isFullscreen ? "fullscreen" : ""}`}  data-cy="gt-tabs">
            <div className="tabs-nav" ref={tabRef}>
                <IntersectionTabs
                    validTabs={validTabs}
                    contextMenuOpen={contextMenuOpen}
                    isFullscreen={isFullscreen}
                    handleFullScreen={handleFullScreen}
                    setContextMenuOpen={setContextMenuOpen}
                    disableFullscreen={disableFullscreen}
                    position={position}
                    selectedTab={selectedTab}
                    disabled={props.disabled}
                    handleTabContextMenu={handleTabContextMenu}
                    notTranslated={props.notTranslated}
                    handleClick={handleClick}
                />
                <div className="tabs-filler">{props.fillerContent}</div>
            </div>
            <div className={props.disabled ? "tabs-wrapper-disabled tabs-wrapper" : "tabs-wrapper"} ref={refWrapper}>
                <Tabs.Content
                    activeTab={selectedTab}
                    className={`tabs-content ${props.disabled ? "tabs-disabled" : ""} ${contentClassName ? contentClassName : ""}`}
                >
                    <Tabs.Pane tabId={selectedTab}>{tabContent(selectedTab)}</Tabs.Pane>
                </Tabs.Content>
            </div>
        </div>
    );
};

export default CustomTabs;

export const BackButton = ({ onClick }) => (
    <div className="back-button" onClick={onClick}>
        <Icon name="chevron-left" />
    </div>
);
