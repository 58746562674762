/**
 * External Dependencies
 */
import React, { useState } from "react";

import { Label, FormGroup, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import SuperscriptDisplay from "../../components/superscript/superscript-display";
import { InformationBadge } from "../information-badge/information-badge";
import { useCustomForm } from "../custom-form/context/context";
import { sortObj } from "../../utils/sortObj";
import { HideButton } from "./hide-button";

/**
 * Component
 */
const customStyles = {
    control: (css, state) => {
        return {
            ...css,
            borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            "&:hover": {
                borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            },
            boxShadow: state.isFocused ? "0 0 0 0.2rem #2fc78740" : "",
            paddingLeft: "8px",
        };
    },
    option: (css, state) => {
        let bgc = "";

        if (state.isSelected) {
            bgc = "#725ec3";
        } else if (state.isFocused) {
            bgc = "#2fc78740";
        }

        return {
            ...css,
            backgroundColor: bgc,
            paddingTop: "3px",
            paddingBottom: "3px",
        };
    },
    multiValueLabel: (css) => {
        return {
            ...css,
            color: "#545b61",
            backgroundColor: "#eeeeef",
        };
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
};

// this is a select which supports multiselect logic and have not so dummy handleChange
const CustomSelect = (props) => {
    let {
        t,
        errors,
        className,
        labelName,
        labelSm,
        colSm,
        superscript,
        hideLabel,
        isMulti,
        options,
        value,
        placeholder,
        disabled,
        sortBy,
        informationBadge,
        informationBadgeProps,
        isValueRequired,
        required = false,
        translateOptions = false,
        ...childProps
    } = props;
    const { isHideMode } = useCustomForm();
    const [isHidden, setIsHidden] = useState<boolean>(false);

    options = sortBy ? options?.sort((a, b) => sortObj(a, b, sortBy.key, sortBy.ascending)) : options;

    if (superscript) {
        options = options.map((o, i) => ({
            label: (
                <div>
                    <SuperscriptDisplay key={`opt-${i}`} value={o.label} />
                </div>
            ),
            stringValue: o.label.replace(/[↑↓→←]/gi, ""), // '↑', '↓', '→', '←'
            value: o.value,
        }));
    }

    if (isValueRequired && value) {
        value =
            value?.length == 1
                ? [{ value: value[0].value, label: translateOptions ? t(value[0].label) : value[0].label, isFixed: true }]
                : value.map((v) => ({ value: v.value, label: translateOptions ? t(v.label) : v.label }));
    }

    if (!superscript && translateOptions) options = options?.map((option) => ({ label: t(option.label), value: option.value }));

    if (isHidden && !isHideMode) return <></>;

    return (
        <>
            {labelName ? (
                <FormGroup row>
                    {hideLabel ? (
                        ""
                    ) : (
                        <Label for="typeSelect" sm={labelSm || 3}>
                            {required && "* "}
                            {t(labelName)}:
                        </Label>
                    )}
                    <Col sm={colSm}>
                        {informationBadge && <InformationBadge {...informationBadgeProps} selector={name} />}
                        {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                        <Select
                            isDisabled={disabled}
                            value={value}
                            {...childProps}
                            styles={customStyles}
                            className={`${className || ""} ${errors ? "custom-gt-select is-invalid" : ""}`}
                            isMulti={isMulti || true}
                            options={options}
                            placeholder={placeholder ? `${t(placeholder)}...` : `${t(labelName)}...`}
                        />
                        {errors && <Label className="invalid-feedback">{t(errors)}</Label>}
                    </Col>
                </FormGroup>
            ) : (
                <>
                    <Select
                        {...childProps}
                        styles={customStyles}
                        className={`${className || ""} ${errors ? "custom-gt-select is-invalid" : ""}`}
                        isMulti={isMulti || true}
                        options={options}
                        placeholder={placeholder ? `${t(placeholder)}...` : ""}
                        isDisabled={disabled}
                    />
                    {errors && <Label className="invalid-feedback">{t(errors)}</Label>}
                </>
            )}
        </>
    );
};

export default withTranslation()(CustomSelect);
