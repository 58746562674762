import "./globals";
import "./methods";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import axios from "axios";

import { stopReportingRuntimeErrors } from "react-error-overlay";

// translation
import { I18nextProvider } from "react-i18next";
import "./utils/prototype-functions";
import i18n from "./i18n";

axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}/admin/api`;
//axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}:${
    //window.location.hostname.includes("demo") ? process.env.REACT_APP_BACK_REMOTE_PORT : process.env.REACT_APP_BACK_PORT
//}/api`;

axios.defaults.headers.common["accept-language"] = i18n.language;
axios.defaults.headers.common["lng"] = i18n.language;
axios.defaults.withCredentials = true;

if (process.env.NODE_ENV === "production") {
    stopReportingRuntimeErrors(); // disables error overlays
}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>,
    document.getElementById("app")
);
