import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";

import { findNextElement, goToNextInput } from "../helpers/goToNextInput";
import { PrimeSelect } from "../../prime-select/prime-select";
import { getNestedObjValue } from "../helpers/primeHelpers";
import { formatDate } from "../../../utils/formatDate";
import { IEditDateInputProps } from "../interfaces";

// table edit component, you send it in the column as editBody prop
export const EditDateInput = (props: IEditDateInputProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        value = undefined,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-date-${fieldName}-${row.id}`}>
            {value ? value : getNestedObjValue(row, fieldName.split("."))}
        </div>
    );
};

const EnabledInput = (props: IEditDateInputProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState<boolean>(false);

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        editUniqueUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        mapPatchResponse = undefined,
        //common
        placeholder = "Enter value...",
        nextRecordOnEnter = false,
        //unique
        showTime = false,
    } = props;

    const { row, fieldName, handleEditSubmit, ref, isBusy, handleFocusSelectRecord, rowIndex } = props.inputData;

    const _value = value || row[fieldName] || "";
    const _name = name || fieldName;

    useEffect(() => {
        const element = document.getElementById(`prime-input-date-${fieldName}-${rowIndex}`);

        // this event has to be blocked, because context menu and calendar was opening at the same time
        const blockRightClick = (event: MouseEvent) => {
            if (event.button != 0) {
                event.stopPropagation();
                event.preventDefault();
            }
        };

        if (element) element.addEventListener("mousedown", blockRightClick);

        return () => {
            if (element) element.removeEventListener("mousedown", blockRightClick);
        };
    }, []);

    const selectTemplate = (e) => {
        let { options, value } = e;
        options = options.map((o) => ({ ...o, label: t(o.label) }));

        return (
            <PrimeSelect
                className="prime-calendar-select"
                value={value}
                options={options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
            />
        );
    };

    const handleOnChange = async (e) => {
        // rowValue is needed for checking if value before change is different then value on blur'e
        let _e = { target: { name: e.target.name, value: !!e.target.value ? formatDate(e.target.value) : null, oldValue: _value } };
        let params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, e.target) : extraEditParams;

        const response = await handleEditSubmit({
            row: row,
            e: _e,
            secondPartUrl: editUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            mapPatchResponse: mapPatchResponse,
            editUniqueUrl: editUniqueUrl,
        });

        if (response?.status?.toString()[0] == "2") {
            error && setError(false);
            if (nextRecordOnEnter) {
                const elements = findNextElement("enter", ref, fieldName);
                goToNextInput(elements);
            }
        } else {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
    };

    return (
        <Calendar
            style={style}
            id={`prime-input-date-${fieldName}-${rowIndex}`}
            key={`prime-input-date-${fieldName}-${row.id}`}
            className={`prime-table-cell-edit ${className} ${error ? "error" : ""}`}
            disabled={isBusy}
            name={_name}
            value={new Date(_value)}
            placeholder={t(placeholder)}
            onChange={handleOnChange}
            onShow={() => handleFocusSelectRecord(row)}
            yearRange="2020:2030"
            dateFormat="dd/mm/yy"
            monthNavigator
            yearNavigator
            monthNavigatorTemplate={selectTemplate}
            yearNavigatorTemplate={selectTemplate}
            showTime={showTime}
            showSeconds={showTime}
        />
    );
};
