import React from "react";

import { EditAdvancedRemarks } from "./advanced-remarks/edit-advanced-remarks";
import { EditColorInput } from "./edit-color-input/edit-color-input";
import { EditTextareaInput } from "./edit-textarea-input";
import { EditCheckboxInput } from "./edit-checkbox-input";
import { EditSelectInput } from "./edit-select-input";
import { EditDateInput } from "./edit-date-input";
import { EditTextInput } from "./edit-text-input";

import * as I from "../interfaces";

interface ITextareaInputConfig extends Omit<I.IEditTextareaInputProps, "inputData"> {}
interface ISelectInputConfig extends Omit<I.IEditSelectInputProps, "inputData"> {}
interface ICheckboxInputConfig extends Omit<I.IEditCheckboxInputProps, "inputData"> {}
interface IDateInputConfig extends Omit<I.IEditDateInputProps, "inputData"> {}
interface ITextInputConfig extends Omit<I.IEditTextInputProps, "inputData"> {}
interface IColorInputConfig extends Omit<I.IEditColorProps, "inputData"> {}
interface IAdvancedRemarksConfig extends Omit<I.IEditAdvancedRemarksProps, "inputData"> {}
interface IMetaDataInputConfig
    extends Omit<I.IEditSelectInputProps, "inputData" | "value" | "name" | "CustomPlaceholder" | "inputEnabled"> {}

export const textarea = (inputData: I.IPrimeEditData<any>, inputConfig?: ITextareaInputConfig) => (
    <EditTextareaInput {...inputConfig} inputData={inputData} />
);

export const date = (inputData: I.IPrimeEditData<any>, inputConfig?: IDateInputConfig) => (
    <EditDateInput {...inputConfig} inputData={inputData} />
);

export const select = (inputData: I.IPrimeEditData<any>, inputConfig?: ISelectInputConfig) => (
    <EditSelectInput {...inputConfig} inputData={inputData} />
);

export const text = (inputData: I.IPrimeEditData<any>, inputConfig?: ITextInputConfig) => (
    <EditTextInput {...inputConfig} inputData={inputData} />
);

export const checkbox = (inputData: I.IPrimeEditData<any>, inputConfig?: ICheckboxInputConfig) => (
    <EditCheckboxInput {...inputConfig} inputData={inputData} />
);

export const color = (inputData: I.IPrimeEditData<any>, inputConfig?: IColorInputConfig) => (
    <EditColorInput {...inputConfig} inputData={inputData} />
);

export const advancedRemarks = (inputData, inputConfig?: IAdvancedRemarksConfig) => (
    <EditAdvancedRemarks {...inputConfig} inputData={inputData} />
);

export const metaData = (inputData: I.IPrimeEditData<any>, inputConfig?: IMetaDataInputConfig) => {
    return (
        <EditSelectInput
            {...inputConfig}
            inputData={inputData}
            value={inputData.row.metadataTemplate?.id}
            name="template"
            CustomPlaceholder={() => <>{inputData.row.metadataTemplate?.name}</>}
        />
    );
};
