import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import React, { CSSProperties, FocusEvent, ReactElement } from "react";

import { InformationBadge, InformationBadgeProps } from "../information-badge/information-badge";
import SuperscriptDisplay from "../superscript/superscript-display";
import LabelError from "../label-error/label-error";

import "./styles.scss";

interface IPrimeSelectProps {
    children?: ReactElement;
    className?: string;
    panelClassName?: string;
    style?: CSSProperties;
    rowStyle?: CSSProperties;
    labelClassName?: string;
    rowClassName?: string;
    value: string | number | undefined;
    name?: string;
    onFocus?: (e: FocusEvent<HTMLElement>) => void;
    onChange: (e: DropdownChangeParams) => void;
    options: any;
    optionLabel?: string;
    optionValue?: string;
    label?: string;
    placeholder?: string;
    filterEnabled?: boolean;
    filterBy?: string;
    showClear?: boolean;
    filterInputAutoFocus?: boolean;
    superscript?: boolean;
    disabled?: boolean;
    id?: string;
    colSm?: number;
    labelSm?: number;
    errors?: string | string[];
    informationBadge?: boolean;
    informationBadgeProps?: InformationBadgeProps;
    emptyMessage?: string;
}

export const PrimeSelect = (props: IPrimeSelectProps) => {
    const { t } = useTranslation();

    const {
        children,
        className = "",
        panelClassName = "",
        style = {},
        rowStyle = {},
        labelClassName = "",
        rowClassName = "",
        value = undefined,
        name = undefined,
        onFocus = undefined,
        onChange,
        optionLabel = "label",
        optionValue = "value",
        label = "",
        placeholder = undefined,
        filterEnabled = true,
        filterBy = undefined,
        showClear = true,
        filterInputAutoFocus = false,

        superscript = false,
        disabled = false,
        id = name,
        labelSm = 3,
        colSm = 12 - labelSm,
        errors,
        informationBadge,
        informationBadgeProps,
        emptyMessage,
    } = props;

    let { options = [] } = props;

    if (superscript) {
        options = options.map((o, i) => ({
            [optionLabel]: (
                <div>
                    <SuperscriptDisplay key={`opt-${i}`} value={o[optionLabel]} />
                </div>
            ),
            stringValue: o[optionLabel].replace(/[↑↓→←]/gi, ""), // '↑', '↓', '→', '←'
            [optionValue]: o[optionValue],
        }));
    }

    return (
        <Row className={`input-row prime-select ${rowClassName}`} style={rowStyle}>
            {label && (
                <Label for={id} className={`${labelClassName}`} sm={labelSm}>
                    {t(label)}
                </Label>
            )}

            {informationBadge && <InformationBadge {...informationBadgeProps} selector={name} />}
            <Col sm={label ? colSm : 12}>
                <Dropdown
                    className={`prime-select ${className}`}
                    panelClassName={panelClassName}
                    id={id}
                    disabled={disabled}
                    name={name}
                    options={options}
                    value={value}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    style={{ width: "100%", ...style }}
                    onFocus={onFocus}
                    onChange={onChange}
                    placeholder={label ? `${t(label)}...` : placeholder ? t(placeholder) : `${t("Select")}...`}
                    showClear={showClear}
                    filter={filterEnabled}
                    filterBy={filterBy ? filterBy : superscript ? "stringValue" : optionLabel}
                    filterInputAutoFocus={filterInputAutoFocus}
                    emptyMessage={emptyMessage}
                />
                {children}
                {errors && <LabelError id={id} error={errors} />}
            </Col>
        </Row>
    );
};
