// external
import React, { Fragment, useState } from "react";

import CreatableSelect from "react-select/creatable";
import { Label, FormGroup, Col } from "reactstrap";
import { withTranslation } from "react-i18next";

import Icon from "../icon";

import "./custom-modal-multi-creatable-select.scss";
import "./custom-modal-select.styles.scss";
import { useCustomForm } from "../custom-form/context/context";
import { HideButton } from "./hide-button";

type OptionType = { [key: string]: any };
type ActionType = "clear" | "create-option" | "deselect-option" | "pop-value" | "remove-value" | "select-option" | "set-value";
interface ICustomModalMultiCreatableSelect {
    colSm?: number;
    labelSm?: number;
    labelName?: string;
    errors?: {};
    className?: string;
    name?: string;
    placeholder?: string;
    handleCreateOption?: (e: any, name?: string) => void;
    handleDeleteOption?: (args?: any) => void;
    isMulti?: boolean;
    hideLabel?: boolean;
    t: Function;
    options: any[];
    onChange?: (e: OptionType[], actionMeta: IActionMeta) => void;
    value?: null | OptionType;
    childProps?: any;
    disabled?: boolean;
    autofocus?: boolean;
    required?: boolean;
}

export interface IActionMeta {
    action: ActionType;
    option: OptionType;
    name?: string;
    removedValue?: OptionType;
    removedValues?: ReadonlyArray<OptionType>;
}

const CustomModalMultiCreatableSelect = (props: ICustomModalMultiCreatableSelect) => {
    const {
        t,
        colSm,
        labelSm,
        labelName,
        errors,
        className,
        name,
        placeholder,
        handleCreateOption,
        handleDeleteOption,
        isMulti = true,
        hideLabel = false,
        options,
        onChange,
        value,
        disabled = false,
        autofocus = false,
        required = false,
        ...childProps
    } = props;

    const { isHideMode } = useCustomForm();
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const CustomOptions = ({ innerRef, innerProps, isDisabled, children, data, isSelected }) =>
        !isDisabled ? (
            <div ref={innerRef} {...innerProps} className={`deleteable-option ${isSelected ? "selected-opt" : ""}`}>
                {children}
                <Icon
                    name="x"
                    className="btn btn-custom-round mr-20 mt-5"
                    style={{ float: "right" }}
                    onClick={() => handleDeleteOption && handleDeleteOption(data)}
                />
            </div>
        ) : null;
    if (isHidden && !isHideMode) return <></>;
    return (
        <Fragment>
            <FormGroup row>
                {!hideLabel && (
                    <Label sm={labelSm || 3}>
                        {required && "* "}
                        {t(labelName)}:
                    </Label>
                )}
                <Col sm={colSm}>
                    {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                    <CreatableSelect
                        className={`creatable-select ${className || ""} ${errors ? "custom-gt-select is-invalid" : ""}`}
                        isClearable
                        styles={customStyles}
                        name={name}
                        placeholder={placeholder ? `${t(placeholder)}...` : `${t(labelName)}...`}
                        onCreateOption={(e) => handleCreateOption && handleCreateOption(e, name)}
                        components={handleDeleteOption && { Option: CustomOptions }}
                        formatCreateLabel={(v) => <span style={{ color: "#2fc787" }}>{`${t("Add")} "${v}"`}</span>}
                        isMulti={isMulti || true}
                        options={options}
                        onChange={onChange && onChange}
                        value={value}
                        autoFocus={autofocus}
                        isDisabled={disabled}
                        {...childProps}
                    />
                    {errors && <Label className="invalid-feedback">{t(errors)}</Label>}
                </Col>
            </FormGroup>
        </Fragment>
    );
};

export default withTranslation()(CustomModalMultiCreatableSelect);

export const customStyles = {
    indicatorsContainer: (css, state) => {
        return {
            ...css,
            div: {
                marginTop: "-2px",
                padding: state.selectProps.notLabeled ? "0px" : "8px",
            },
        };
    },
    valueContainer: (css, state) => {
        return state.selectProps.notLabeled
            ? {
                  ...css,
                  maxHeight: "27px",
                  paddingLeft: "17px",
              }
            : {
                  ...css,
                  paddingLeft: "17px",
              };
    },
    singleValue: (css) => {
        return { ...css, color: "unset" };
    },
    control: (css, state) => {
        let newCSS = {
            ...css,
            borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            "&:hover": {
                borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            },
            boxShadow: state.isFocused ? "0 0 0 0.2rem #2fc78740" : "",
        };

        if (state.selectProps.notLabeled) {
            newCSS = {
                ...newCSS,
                minHeight: "27px",
                height: "27px",
            };
        }

        return newCSS;
    },
    option: (css, state) => {
        let bgc = "";

        if (state.isSelected) {
            bgc = "#725ec3";
        } else if (state.isFocused) {
            bgc = "#2fc78740";
        }

        return {
            ...css,
            backgroundColor: bgc,
            paddingTop: "3px",
            paddingBottom: "3px",
        };
    },

    menu: (css) => {
        return { ...css, zIndex: 2 };
    },
};
