/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { XCircle } from "react-feather";

/**
 * Component
 */
class Content extends Component {
    render() {
        return (
            <Fragment>
                <div>
                    <div className="display-1 mb-10 mt-10"> 
                        404
                    </div>
                    <div className="display-4 mb-30"> 
                        <Trans>Page Not Found</Trans>
                    </div>
                </div>
                {/* <Link to="/" className="btn btn-brand"><Trans>Back Home</Trans></Link> */}
            </Fragment>
        );
    }
}

export default connect( ( { settings } ) => (
    {
        settings,
    }
) )( Content );
