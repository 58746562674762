export const hasRemarks = (status: string): string => {
    switch (status) {
        case "active":
            return "pi pi-flag prime-edit-remark-icon active ";
        case "withdrawn":
            return "pi pi-flag prime-edit-remark-icon";
        case "empty":
            return "pi pi-ellipsis-h prime-edit-more-icon";
        default:
            return "";
    }
};
