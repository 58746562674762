import React, { SyntheticEvent, useState } from "react";
import { Col, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";

import CustomModalHeader from "../../components/custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../components/custom-modal-elements/custom-modal-footer";
import CustomModalInput from "../custom-modal-elements/custom-modal-input";

import i18n from "../../i18n";
import { IErrors } from "../../pages/interfaces/IErrors";
import { IRequirements } from "../../pages/interfaces/IRequirements";
import { IReqParams } from "../../pages/interfaces/IReqParams";
import CustomForm from "../custom-form/custom-form";

interface IProps {
    isOpen: boolean;
    requirements: IRequirements;
    permCode: string;
    submit: (e: SyntheticEvent, reqParams: IReqParams) => void;
    toggleModal: (state: boolean) => void;
    onClose?: () => void;
}

const CustomModalRequirement = ({ isOpen, requirements, permCode, submit, toggleModal, onClose }: IProps) => {
    const [errors, setErrors] = useState({} as IErrors);
    const [password, setPassword] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [modalOpen, setModalOpen] = useState<boolean>(isOpen);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmitData = async (e) => {
        e.preventDefault();
        e.persist();

        const reqParams: IReqParams = {
            password,
            comment,
            permCode,
        };

        if (!handleValidation()) return;

        setIsLoading(true);

        const resp: any = await submit(e, reqParams);

        if (typeof resp == "object" && (resp.hasOwnProperty("reqPassword") || resp.hasOwnProperty("reqComment")))
            setErrors(resp), setIsLoading(false);
        else {
            setIsLoading(false);
            setModalOpen(false);
            setTimeout(() => {
                toggleModal(false);
                // timeout = 150 is important due to transition modal
            }, 150);
        }
    };

    const handleChangeInput = ({ target: { value, name } }) => {
        switch (name) {
            case "password":
                setPassword(value);
                break;
            case "comment":
                setComment(value);
                break;

            default:
                break;
        }
    };

    const handleValidation = () => {
        let isValid = true;

        if (requirements.requirePassword && !password.length) {
            setErrors((prevState) => ({ ...prevState, reqPassword: "Password is required" }));
            isValid = false;
        } else setErrors((prevState) => ({ ...prevState, reqPassword: "" }));

        if (requirements.requireComment && !comment.length) {
            setErrors((prevState) => ({ ...prevState, reqComment: "Comment is required" }));
            isValid = false;
        } else setErrors((prevState) => ({ ...prevState, reqComment: "" }));

        return isValid;
    };

    const handleCloseModal = async () => {
        setIsLoading(false);
        onClose && onClose();
        setModalOpen(false);
        setTimeout(() => {
            toggleModal(false);
            // timeout = 150 is important due to transition modal
        }, 150);
    };

    return (
        <React.Fragment>
            <Modal isOpen={modalOpen} toggle={handleCloseModal} fade id="custom-modal-requirement">
                <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData}>
                    <CustomModalHeader handlecloseModal={handleCloseModal} name="Transaction authorization" />
                    <ModalBody>
                        <FormGroup>
                            {requirements.requirePassword && (
                                <CustomModalInput
                                    type="password"
                                    name="password"
                                    labelText="Password"
                                    forLabel="password"
                                    value={password}
                                    handleChange={handleChangeInput}
                                    errors={errors.reqPassword}
                                    autofocus
                                />
                            )}
                            {requirements.requireComment && (
                                <FormGroup row>
                                    <Label sm={3} for="comment">
                                        {i18n.t("Comment")}:
                                    </Label>
                                    <Col>
                                        <Input
                                            name="comment"
                                            id="comment"
                                            type="textarea"
                                            autoComplete="off"
                                            placeholder={i18n.t("Comment") + "..."}
                                            className={`${errors?.reqComment?.length ? "is-invalid" : ""}`}
                                            value={comment}
                                            onChange={handleChangeInput}
                                        />
                                        {errors?.reqComment?.length ? (
                                            <div className="invalid-feedback">{i18n.t(errors.reqComment)}</div>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <CustomModalFooter closeModal={handleCloseModal} cancelName="Cancel" okName="Authorize" isBusy={isLoading} />
                </CustomForm>
            </Modal>
        </React.Fragment>
    );
};

export default CustomModalRequirement;
