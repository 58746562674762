import React, { Component } from "react";
import axios from "axios";

import { withTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import { Link } from "react-router-dom";
import _ from "lodash";

import SuperscriptDisplay from "../superscript/superscript-display";
import addToast from "../../utils/addToast";
import Dropdown from "../bs-dropdown";
import { Collapse } from "reactstrap";
import Icon from "../icon";

import "./style.scss";

interface IShowAlerts {
    [key: string]: boolean;
}

interface IAlerts {
    [key: string]: IAlert[];
}

interface IAlert {
    content: any;
    id: number;
    instanceId: number;
    isActive: boolean;
    modelName: string;
    type: string;
    user: number;
}

class NotificationsDropdown extends Component<any, any> {
    constructor(props) {
        super(props);
        let baseUrl = axios.defaults.baseURL || "";
        baseUrl = baseUrl.split("//") as any;
        const url = baseUrl[baseUrl.length - 1].replace("/api", "").replace("/admin", "");
        const webSocketProtocol = window.location.protocol.includes("https") ? "wss" : "ws";

        let notificationsWebSocket;
        try {
            notificationsWebSocket = new WebSocket(`${webSocketProtocol}://${url}/ws/notifications/`);
            notificationsWebSocket.onerror = function (event) {
                console.error("WebSocket error observed:", event);
            };
        } catch (e) {
            console.warn("Initialization of notification websocket failed", e);
        }

        this.state = {
            notificationsSocket: notificationsWebSocket,
            alerts: {} as IAlerts,
            showAlerts: {} as IShowAlerts,
            isOpen: false,
        };
    }

    componentDidMount() {
        axios.get("user-notifications").then((response) => {
            const groupedData = _.groupBy(response.data, "modelName");
            const showAlerts = Object.fromEntries(Object.keys(groupedData).map((x) => [x, false]));
            this.setState({ alerts: groupedData, showAlerts });
        });

        if (this.state.notificationsSocket) {
            this.state.notificationsSocket.onmessage = (e) => {
                let notification = JSON.parse(e?.data);
                switch (notification.content.type) {
                    case "deleteAlert":
                        this.setState((prevState) => ({
                            ...prevState,
                            alerts: {
                                ...prevState.alerts,
                                [notification.modelName]: prevState?.alerts[notification.modelName].filter(
                                    (x) => x.id != notification.content.notificationId
                                ),
                            },
                        }));
                        break;

                    case "addAlert":
                    case "groupAlert":
                        let notificationAlreadyDisplayed;
                        let newAlerts = Object.keys(this.state?.alerts)?.length
                            ? this.state?.alerts?.[notification?.modelName]
                                ? JSON.parse(JSON.stringify(this.state?.alerts?.[notification?.modelName]))
                                : []
                            : [];
                        notificationAlreadyDisplayed = newAlerts.some((x) => x.id == notification.id);
                        if (notificationAlreadyDisplayed) return;

                        addToast({
                            title: notification.content.payload?.title,
                            content: notification.content.payload?.details,
                            color: "#725ec3",
                        });
                        newAlerts.unshift(notification);

                        this.setState((prevState) => ({
                            ...prevState,
                            alerts: {
                                ...prevState.alerts,
                                [notification.modelName]: newAlerts,
                            },
                        }));
                        break;

                    default:
                        console.warn("Unsupported notification type:", notification);
                }
            };

            this.state.notificationsSocket.onclose = function (e) {
                console.error("NotificationsSocket closed unexpectedly");
            };
        }
    }

    componentWillUnmount() {
        if (this.state.notificationsSocket) {
            this.state.notificationsSocket.close();
        }
    }

    onMouseEnter = () => {
        if (!this.state.isOpen) this.setState({ isOpen: true });
    };

    onMouseLeave = () => {
        if (this.state.isOpen) this.setState({ isOpen: false, showAlerts: {} });
    };

    getUrlFromModel = (modelName) => {
        switch (modelName) {
            case "Active tasks":
                return "/orders/preview";
            case "Pending tasks":
                return "/orders/preview";
            case "Material":
                return "/inventory";
            case "Inventory":
                return "/inventory";
            case "Test report":
                return "/orders/test-reports";
            case "Document":
                return "/cdr/document-repository";
            default:
                return modelName?.replaceAll(" ", "-").toLowerCase();
        }
    };

    dismissNotification = async (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            alerts: {
                ...prevState.alerts,
                [item.modelName]: prevState.alerts[item.modelName].filter((notification) => notification.id != item.id),
            },
        }));
        try {
            await axios.delete(`user-notifications/${item.id}`);
        } catch {}
    };

    render() {
        return (
            <Dropdown
                tag="li"
                direction="up"
                toggle={() => {}}
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                dropdownOpen={this.state.isOpen}
                showTriangle
            >
                <Dropdown.Toggle
                    tag="a"
                    // href="#"
                    className={classnames("dropdown-item", this.props.isMobile ? "nav-link" : "")}
                >
                    {this.props.isMobile ? (
                        <>
                            <Icon name="bell" />
                            <span>{this.props.t("Notifications")}</span>
                            {Object.keys(this.state.alerts).length > 0 ? (
                                <span className="badge badge-circle badge-brand">
                                    {_.sum(Object.values(this.state.alerts).map((alert: any) => alert.length))}
                                </span>
                            ) : null}
                        </>
                    ) : (
                        <span className="btn btn-custom-round">
                            <Icon name="bell" className="mr-0" />
                            {Object.keys(this.state.alerts).length > 0 ? (
                                <span className="badge badge-circle badge-brand">
                                    {_.sum(Object.values(this.state.alerts).map((alert: any) => alert.length))}
                                </span>
                            ) : null}
                        </span>
                    )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    right
                    tag="div"
                    className="dropdown-notification nav dropdown-menu pt-0 pb-0 pr-0 pl-0 flex-column"
                    modifiers={{ offset: { offset: "0,12" } }}
                >
                    <div className="rui-navbar-dropdown-title mb-10 pl-25 pt-25">
                        <div className="d-flex align-items-center">
                            <h2 className="h4 mb-0 mr-auto">{this.props.t("Notifications")}</h2>
                        </div>
                    </div>
                    <ul className="notifications-list nav rui-navbar-dropdown-notice mt-0 pt-0 d-flex flex-column overflow-y-auto flex-nowrap">
                        {Object.entries(this.state.alerts).map(([key, alert]: any) => (
                            <li key={key}>
                                <div className="media media-filled mnl-30 mnr-30 clickable">
                                    <span
                                        className="media-link"
                                        onClick={
                                            alert.length && !this.state.showAlerts[key]
                                                ? () =>
                                                      this.setState((prevState) => ({
                                                          showAlerts: { ...prevState.showAlerts, [key]: true },
                                                      }))
                                                : () =>
                                                      this.setState((prevState) => ({
                                                          showAlerts: { ...prevState.showAlerts, [key]: false },
                                                      }))
                                        }
                                    >
                                        <span
                                            className={
                                                alert.length ? "media-img notifications-exists" : "media-img notifications-non-exists"
                                            }
                                        >
                                            {alert.length}
                                        </span>
                                        <span className="media-body">
                                            <span className={`media-title ${alert.length ? "font-weight-bold" : ""}`}>
                                                {this.props.t(key)}
                                            </span>
                                            {!this.state.showAlerts[key] && (
                                                <small className="media-subtittle text-black-50">
                                                    {alert.length
                                                        ? this.props.t("Click to see more.")
                                                        : this.props.t("No new notifications.")}
                                                </small>
                                            )}
                                        </span>
                                    </span>
                                </div>

                                <Collapse isOpen={this.state.showAlerts[key]}>
                                    <ul className="dropdown-notification-height notifications-list nav rui-navbar-dropdown-notice mt-0 pt-0 d-flex flex-column  flex-nowrap">
                                        {alert.map((item, i) => (
                                            <li key={item.id} className="w-100">
                                                <div className="media media-filled mnl-30 mnr-30">
                                                    <Link
                                                        to={{
                                                            pathname: item?.url
                                                                ? `/${item?.url}`
                                                                : item?.content?.payload?.url
                                                                ? `/${item?.content?.payload?.url}`
                                                                : `${this.getUrlFromModel(item?.modelName)}`,
                                                            state: { instanceId: item?.instanceId },
                                                        }}
                                                        className="media-link"
                                                    >
                                                        <span className="media-img">{item.content?.payload?.count || "1"}</span>
                                                        <span className="media-body">
                                                            <span className="media-title">
                                                                <SuperscriptDisplay value={this.props.t(item.content.payload.title)} />
                                                                <Icon
                                                                    className="dismiss-btn"
                                                                    name="X"
                                                                    onClick={(e) => this.dismissNotification(e, item)}
                                                                ></Icon>
                                                            </span>
                                                            <small className="media-subtitle">
                                                                <SuperscriptDisplay value={this.props.t(item.content.payload.details)} />
                                                            </small>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </Collapse>
                            </li>
                        ))}
                    </ul>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
export default withTranslation()(NotificationsDropdown);
