import React, { createContext, useContext, useState } from "react";

import { IData } from "hooks/useGetData";;
import { ISample } from "../../ReleaseOrders/interfaces/interfaces";
import { editTabs, EditTabValuesType } from "../utils/edit-tabs";
import { orderModes, OrderModesTypes } from "../../utils/modes";
import { useFormData } from "../../../../hooks/useFormData";
import { useGetData } from "../../../../hooks/useGetData";
import { IOrder } from "../../interface";

export const OrderEditContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.edit;
export const OrderEditContextProvider = ({ children }) => {
    const [selectedOrders, setSelectedOrders] = useState<IOrder[]>([]);
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
    const [selectedTab, setSelectedTab] = useState<EditTabValuesType>(editTabs.samples);

    const [refreshOrdersData, setRefreshOrdersData] = useState<Function | undefined>(undefined);
    const [refreshSampleData, setRefreshSampleData] = useState<Function | undefined>(undefined);

    const samplesObj = useGetData<any>({
        url: selectedOrders.length == 1 ? `/samples` : undefined,
        initParams: "?pageSize=10&page=1",
        triggerValues: [selectedOrders],
        additionalParams: `&orderId=${selectedOrders[0]?.id}`,
        handleUnmount: () => setSelectedSamples([]),
    });

    const { formData: allReportTypes } = useFormData<string[], any>({
        url: "/bs/testPlans/test_plan_report_types",
        mapFormData: (data) => data.reportTypes.map((rt) => rt.value),
    });

    return (
        <OrderEditContext.Provider
            value={{
                selectedOrders,
                setSelectedOrders,
                selectedSamples,
                setSelectedSamples,
                allReportTypes,
                refreshOrdersData,
                setRefreshOrdersData,
                refreshSampleData,
                setRefreshSampleData,
                selectedTab,
                setSelectedTab,
                mode,
                samplesObj,
            }}
        >
            {children}
        </OrderEditContext.Provider>
    );
};

export const useOrderEdit = (): IUseOrderEdit => {
    const {
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        allReportTypes,
        refreshOrdersData,
        setRefreshOrdersData,
        refreshSampleData,
        setRefreshSampleData,
        selectedTab,
        setSelectedTab,
        mode,
        samplesObj,
    } = useContext(OrderEditContext);

    return {
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        allReportTypes,
        refreshOrdersData,
        setRefreshOrdersData,
        refreshSampleData,
        setRefreshSampleData,
        selectedTab,
        setSelectedTab,
        mode,
        samplesObj,
    };
};

interface IUseOrderEdit {
    selectedOrders: IOrder[];
    setSelectedOrders: (arg: IOrder[]) => void;
    selectedSamples: ISample[];
    setSelectedSamples: (arg: ISample[]) => void;
    allReportTypes: string[] | undefined;
    refreshOrdersData: Function;
    setRefreshOrdersData: Function;
    refreshSampleData: Function;
    setRefreshSampleData: Function;
    selectedTab: EditTabValuesType;
    setSelectedTab: (arg: EditTabValuesType) => void;
    mode: "edit";
    samplesObj: {
        data: IData<any>;
        setData: React.Dispatch<React.SetStateAction<IData<any>>>;
        refreshData: Function;
        handleReload: (params: any, refresh?: any) => void;
    };
}
