import React, { ClipboardEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { getNestedObjValue } from "../helpers/primeHelpers";
import { ReqParamsHOC } from "../helpers/req-params-hoc";
import { moveKeyPress } from "../helpers/moveKeyPress";
import { onPressType } from "../helpers/goToNextInput";
import { IEditTextInputProps } from "../interfaces/edit-interfaces";
import addToast from "../../../utils/addToast";

// table edit component, you send it in the column as editBody prop
// editBody: (inputData: IPrimeEditData<ProperInterface>) => (<EditTextInput inputData={inputData} />),
export const EditTextInput = (props: IEditTextInputProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        value = undefined,
        type,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-text-${fieldName}-${row.id}`}>
            {process.env.REACT_APP_DEFAULT_SEPARATOR == "comma" && type == "number" && typeof row[fieldName] == "string"
                ? (value ? value : getNestedObjValue(row, fieldName.split("."))).replace(".", ",")
                : value != undefined
                ? value
                : getNestedObjValue(row, fieldName.split("."))}
        </div>
    );
};

const EnabledInput = (props: IEditTextInputProps) => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState<string>("");
    const [hasKeyBeenPressed, setHasKeyBeenPressed] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const formRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const keyPressedRef = useRef<string>("");

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        editUniqueUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        mapPatchResponse = undefined,
        //common
        placeholder = "Enter value...",
        nextRecordOnEnter = true,
        //unique
        type = "text",
    } = props;

    const { row, fieldName, handleEditSubmit, isBusy, permCode = "", handleFocusSelectRecord, rowIndex } = props.inputData;

    const _name = name || fieldName;

    useEffect(() => {
        if (value != undefined) setInputValue(value);
        else {
            let _value = "";

            if (!!row[fieldName]) {
                if (process.env.REACT_APP_DEFAULT_SEPARATOR == "comma" && type == "number" && typeof row[fieldName] == "string")
                    _value = row[fieldName].replace(".", ",");
                else _value = row[fieldName];
            } else if (row[fieldName] === 0) _value = "0";
            setInputValue(_value);
        }

        error && setError(false);
    }, [row]);

    const blockKeySpam = () => {
        setHasKeyBeenPressed(true);
        setTimeout(() => {
            setHasKeyBeenPressed(false);
        }, 500);
    };

    const handleKeyPress = (e) => {
        if (hasKeyBeenPressed) return;

        switch (e.nativeEvent.keyCode) {
            case 13: // on press enter
                keyPressedRef.current = e.key;
                e.preventDefault();
                blockKeySpam();
                handleOnBlur({ target: { name: _name, value: inputValue } }, "enter");
                break;

            case 9: // on press tab
                keyPressedRef.current = e.key;
                blockKeySpam();
                handleOnBlur({ target: { name: _name, value: inputValue } }, "tab");
                break;
            default:
                keyPressedRef.current = "";
                break;
        }
    };

    const handleOnBlur = (e, onPress: onPressType | undefined = undefined) => {
        let _e = {
            target: {
                name: e.target.name,
                value:
                    process.env.REACT_APP_DEFAULT_SEPARATOR == "comma" && type == "number"
                        ? e.target.value.replace(",", ".")
                        : e.target.value,
            },
        };
        const oldValue = value || row[fieldName];

        // Old value comparison has to be done here because it has to be done before req params check
        if (oldValue == _e.target.value || ((oldValue == null || oldValue == undefined) && !_e.target.value))
            return onPress == "enter" && moveKeyPress(`prime-input-text-${fieldName}-`, rowIndex);

        if (type != "text") {
            if (!row[fieldName] && _e.target.value.length && _e.target.value.trim().length == 0) {
                _e.target.value = _e.target.value.trim();
                setInputValue("");
            }

            if (isNaN(_e.target.value)) {
                setError(true);
                addToast({ title: "Value must be number", color: "danger" });
                return;
            }
        }

        if (!permCode) handleSubmit(e, undefined);

        formRef.current && formRef.current.click();
    };

    const handleSubmit = async (e: SyntheticEvent, reqParams?: IReqParams) => {
        const _e = { target: { name: _name, value: inputRef.current?.value } };
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;

        await handleEditSubmit({
            row: row,
            e: _e,
            secondPartUrl: editUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            mapPatchResponse: mapPatchResponse,
            editUniqueUrl: editUniqueUrl,
            reqParams: reqParams,
        });

        keyPressedRef.current == "Enter" && moveKeyPress(`prime-input-text-${fieldName}-`, rowIndex);
    };

    const onChange = (e) => {
        let _value = e.target.value;

        if (process.env.REACT_APP_DEFAULT_SEPARATOR == "comma" && type == "number") _value = _value.replace(".", ",");
        else if (type == "integer") _value = _value.replace(".", "").replace(",", "");

        setInputValue(_value);

        error && setError(false);
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        let _value = event.clipboardData.getData("text/plain");

        if (type == "text") {
            setInputValue(_value);
            error && setError(false);

            return;
        }

        let _error = false;
        _value = process.env.REACT_APP_DEFAULT_SEPARATOR == "comma" ? _value.replace(".", ",") : _value.replace(",", ".");

        if (isNaN(_value as any)) {
            _error = true;
            addToast({ title: "Value must be number", color: "danger" });
        } else if (type == "number") _value = parseFloat(_value).toString();
        else if (type == "integer") _value = parseInt(_value).toString();

        setInputValue(_value);
        error != _error && setError(_error);
    };

    const handleClose = () => setInputValue(value || row[fieldName]);

    return (
        <ReqParamsHOC onSubmit={handleSubmit} permCode={permCode} formRef={formRef} onClose={handleClose}>
            <Input
                onPaste={handlePaste}
                disabled={isBusy}
                id={`prime-input-text-${fieldName}-${rowIndex}`}
                key={`prime-input-text-${fieldName}-${row.id}`}
                step="any"
                type="text"
                style={style}
                innerRef={inputRef}
                value={
                    process.env.REACT_APP_DEFAULT_SEPARATOR == "comma" && type == "number" && inputValue
                        ? String(inputValue)?.replace(".", ",")
                        : inputValue || ""
                }
                name={_name}
                onChange={onChange}
                onBlur={handleOnBlur}
                onFocus={() => handleFocusSelectRecord(row)}
                onKeyDown={(e) => nextRecordOnEnter && handleKeyPress(e)}
                className={`prime-table-cell-edit ${className} ${error ? "error" : ""}`}
                placeholder={t(placeholder)}
                autoComplete="off"
            />
        </ReqParamsHOC>
    );
};
