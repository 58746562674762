import React, { CSSProperties, useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
 
import { IPrimeRowData } from "../interfaces/placeholders-interfaces";
import { DefaultCell } from "../components/default-cell";
import { getCssVariable } from "../helpers/cssHelpers";

interface ITableRecord1 {
    [key: number]: INameId<number>[];
}

interface ITableRecord2 {
    [key: string]: INameId<number>[];
}

export interface IDropdownPlaceholderProps {
    rowData: IPrimeRowData<ITableRecord1 | ITableRecord2>;
}

interface IPopUpConfig {
    style?: CSSProperties;
    openStatus: "closed" | "mounted" | "opening" | "opened" | "closing";
}

//! this components works only when column that you want to display has {name: string, id: any} format
export const DropdownPlaceholder = ({ rowData }: IDropdownPlaceholderProps) => {
    const { tableRef, fieldName } = rowData;

    const containerRef = useRef<HTMLDivElement>(null);
    const popUpRef = useRef<HTMLDivElement>(null);

    const [popUpConfig, setPopUpConfig] = useState<IPopUpConfig>({ openStatus: "closed" });

    const values: INameId<number>[] = rowData.row?.[rowData.fieldName];
    const popUpValues: INameId<number>[] = values?.slice(1) || [];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target) && popUpConfig.openStatus == "opened") {
                setPopUpConfig((pervState) => ({ openStatus: "closing", style: { ...pervState.style, height: "0px" } }));

                setTimeout(() => {
                    setPopUpConfig({ openStatus: "closed" });
                }, 200);
            }
        };

        if (popUpConfig) document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [popUpConfig]);

    const openPopUp = () => {
        const tdElement = containerRef.current?.closest("td");

        if (tdElement && popUpConfig.openStatus == "closed") {
            const popUpPositionY = tdElement.offsetTop + tdElement.offsetHeight + 1;
            const popUpPositionX = tdElement.offsetLeft;

            setPopUpConfig({
                style: { top: popUpPositionY, left: popUpPositionX + 1, width: tdElement.offsetWidth - 2 },
                openStatus: "mounted",
            });

            setTimeout(() => {
                setPopUpConfig((pervState) => ({
                    openStatus: "opening",
                    style: {
                        ...pervState.style,
                        height: `${popUpValues.length * parseInt(getCssVariable(tableRef, "--record-height"))}px`,
                    },
                }));
            }, 0);

            setTimeout(() => {
                setPopUpConfig((pervState) => ({ ...pervState, openStatus: "opened" }));
            }, 200);
        }
    };

    return values?.length > 0 ? (
        <>
            <div className="multi-item-container" ref={containerRef}>
                <DefaultCell>{values[0]?.name}</DefaultCell>
                {popUpValues?.length > 0 && <span className="pi pi-angle-down multi-item-icon" onClick={openPopUp} />}
            </div>
            {popUpConfig.openStatus != "closed" && containerRef?.current && (
                <>
                    {createPortal(
                        <div className={`multi-items-popup ${popUpConfig.openStatus}`} style={popUpConfig.style} ref={popUpRef}>
                            {popUpValues.map((value) => (
                                <div className="multi-item-container" key={`${fieldName}-popup`}>
                                    <DefaultCell>{value.name}</DefaultCell>
                                </div>
                            ))}
                        </div>,
                        containerRef.current.closest("tr") as HTMLTableRowElement
                    )}
                </>
            )}
        </>
    ) : (
        <></>
    );
};
