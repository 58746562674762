import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import axios from "axios";

import { IBtnDropdownProps, IToggleDropdownProps, IDropdownItemProps } from "./interfaces/interfaces";
import CustomHelpContextMenu from "../help-context-menu/help-context-menu";
import { IPermCodeResponse } from "../help-context-menu/interface";
import Icon from "../icon";

import "./style.scss";

export const BtnDropdown = ({
    children,
    className = "",
    itemsWrapperClassName = "",
    label = "More...",
    myKey = "more-dropdown",
    permCode,
}: IBtnDropdownProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        const checkClick = (e) => {
            if (
                ref.current &&
                !ref.current.contains(e.target) &&
                !document.getElementById("custom-modal-requirement") &&
                (e.target?.getAttribute("a-key") != myKey ||
                    (typeof e.target.className == "string" && !e.target.className?.includes("toggle-dropdown")))
            ) {
                setDropdownOpen(false);
            }
        };

        if (dropdownOpen) document.addEventListener("mouseup", checkClick);
        else document.removeEventListener("mouseup", checkClick);

        return () => document.removeEventListener("mouseup", checkClick);
    }, [dropdownOpen]);

    return (
        <div className={`btn-dropdown ${dropdownOpen ? "opened" : "closed"} ${className}`}>
            <ToggleDropdown
                text={label}
                onClick={() => setDropdownOpen((prevState) => !prevState)}
                myKey={myKey}
                permCode={permCode}
                dropdownOpen={dropdownOpen}
            />
            {dropdownOpen && (
                <div ref={ref}>
                    <ul className={itemsWrapperClassName}>{children}</ul>
                </div>
            )}
        </div>
    );
};

const ToggleDropdown = (props: IToggleDropdownProps) => {
    const { t } = useTranslation();
    const {
        text,
        color = "gt-success",
        className = "",
        outline = true,
        onClick,
        display = true,
        disabled = false,
        myKey = "",
        permCode,
        dropdownOpen,
    } = props;

    // Code to display help menu START
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [helpPath, setHelpPath] = useState<string | undefined>(undefined);
    const buttonRef = useRef<any>(null);

    useEffect(() => {
        setShowContextMenu(false);

        const handleOutsideClick = (e) => {
            if (buttonRef.current && !buttonRef.current.contains(e.target)) {
                setShowContextMenu(false);
            }
        };

        const handleScroll = () => {
            setShowContextMenu(false);
        };

        const eventTypes = ["contextmenu", "click"];
        eventTypes.forEach((eventType) => {
            document.addEventListener(eventType, handleOutsideClick);
        });

        window.addEventListener("scroll", handleScroll);

        return () => {
            eventTypes.forEach((eventType) => {
                document.removeEventListener(eventType, handleOutsideClick);
            });
            window.removeEventListener("scroll", handleScroll);
        };
    }, [dropdownOpen]);

    const handleContextMenu = async (e) => {
        e.preventDefault();

        if (!permCode || dropdownOpen) return;

        try {
            const response: IPermCodeResponse = await axios.get("accessSettings/permissions", {
                params: { code: permCode },
            });

            const helpData = response?.data?.url;
            if (helpData) {
                setShowContextMenu(true);
                setHelpPath(helpData);

                const buttonRect = buttonRef.current.getBoundingClientRect();
                const menuPosition = {
                    x: buttonRect.left + 15,
                    y: buttonRect.top + buttonRect.height + 5,
                };
                setMenuPosition(menuPosition);
            }
        } catch (err) {}
    };

    const handleMenuClick = () => {
        setShowContextMenu(false);
    };
    // Code to display help menu END

    return (
        <>
            {display && (
                <>
                    <Button
                        color={color}
                        type="button"
                        className={`${"toggle-dropdown"} ${
                            (className ?? "mr-10") + (className && className.includes("mt-") ? "" : " mt-10")
                        }`}
                        onClick={(e) => onClick(e)}
                        outline={outline}
                        disabled={disabled}
                        a-key={myKey}
                        onContextMenu={handleContextMenu}
                        innerRef={buttonRef}
                    >
                        <Icon name={["fas", "angle-down"]} className="toggle-button-icon" a-key={myKey} />
                        {t(text) || ""}
                    </Button>
                    {permCode && showContextMenu && (
                        <CustomHelpContextMenu
                            positionX={menuPosition.x}
                            positionY={menuPosition.y}
                            handleMenuClick={handleMenuClick}
                            helpPath={helpPath}
                        />
                    )}
                </>
            )}
        </>
    );
};

const DropdownItem = ({ children, className = "", onClick, disabled = false }: IDropdownItemProps) => {
    return (
        <li className={`${className} pointer`} onClick={(e) => !disabled && onClick(e)}>
            {children}
        </li>
    );
};

BtnDropdown.DropdownItem = DropdownItem;
