export const setSelected = (dataSet: any[], notSelectFirst: boolean, onSelect: (arg: any[]) => void, selectedRecords: any[]) => {
    let recordToSelect: any[] = [];

    if (dataSet?.length == 0 && selectedRecords?.length == 0) return; // this line might create bugs
    if (dataSet?.length > 0) {
        const recordToBeSelected = dataSet.find((data) => data.isSelected == true);

        if (recordToBeSelected) recordToSelect = [recordToBeSelected];
        else if (selectedRecords?.length > 0) {
            const foundRecord = dataSet.find((r) => r.id == selectedRecords[0]?.id);
            if (foundRecord) recordToSelect = [foundRecord];
            else recordToSelect = notSelectFirst ? [] : dataSet[0] ? [dataSet[0]] : [];
        } else recordToSelect = notSelectFirst ? [] : dataSet[0] ? [dataSet[0]] : [];
    }

    onSelect(recordToSelect);
};
