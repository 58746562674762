import React from "react";

import { TooltipContentType } from "components/gt-tooltip/gt-tooltip";
import { TooltipHOC } from "components/index";

interface IDefaultCellProps {
    children: TooltipContentType;
    tooltipContent?: TooltipContentType;
}

export const DefaultCell = (props: IDefaultCellProps) => (
    <TooltipHOC tooltipContent={props.tooltipContent || props.children}>{props.children}</TooltipHOC>
);
