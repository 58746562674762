import React from "react";

import { IAsyncTask } from "../async-tasks-dropdown";
import Dropdown from "../../bs-dropdown";
import Icon from "../../icon";

export const AsyncTasksDropdownToggle = (props: { tasks: IAsyncTask[] }) => {
    return (
        <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
            <span className="btn btn-custom-round">
                <Icon name="cpu" />
                {props.tasks.length ? <span className="badge badge-circle badge-brand">{props.tasks.length}</span> : ""}
            </span>
        </Dropdown.Toggle>
    );
};
