// internal
import { withTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import React from "react";
import axios from "axios";

// internal
import CustomModalHeader from "../../../custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../../custom-modal-elements/custom-modal-footer";
import CustomDataTable from "../../../custom-data-table/custom-data-table";
import SuperscriptDisplay from "../../../superscript/superscript-display";
import { IMeta } from "../../../custom-data-table/interface";
import PrettyCell from "../../../prime-data-table/body-components/pretty-cell/pretty-cell";
import addToast from "../../../../utils/addToast";

// style
import "./style.scss";
import CustomSpinner from "../../../custom-spinner/custom-spinner";

class AuditWindow extends React.Component<any, any> {
    TYPE_COLORS = {
        "+": "#2FC787",
        "-": "red",
        "~": "orange",
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSet: [],
            columns: [],
            meta: {} as IMeta,
            appLabel: props.auditData?.appLabel,
            model: props.auditData?.model,
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData = async () => {
        try {
            const path = this.getAuditUrl("?pageSize=15");
            const response = await axios.get(path, { params: { hideToast: true } });
            const { data, meta } = response.data;

            if (!data[0]) {
                this.props.closeModal()
                addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
                this.setState({ isLoading: false });
                return;
            }

            if (window.location.pathname.includes("users-management")) {
                data.forEach((o) => (o.historyType = `user${o.historyType}`));
            }

            const newColumns = [] as any[];
            const auditSelectors = Object.keys(data[0]).filter((sel) => !sel.startsWith("history"));

            for (let selector of auditSelectors) {
                const foundColumn = this.props.tableColumns.find((c) => c.field == selector);

                if (foundColumn) {
                    foundColumn.name = foundColumn?.header;
                    newColumns.push({
                        ...foundColumn,
                        cell: (row) => {
                            const color = this.TYPE_COLORS[row.historyType];
                            const isChanged = row.__auditChangedValue?.includes(selector);
                            const isAdded = row.historyType.endsWith("+");
                            const isDeleted = row.historyType.endsWith("-");

                            const style = {
                                color: isAdded ? color : isDeleted ? color : isChanged ? color : "",
                            };

                            return <SuperscriptDisplay style={style} value={`${row[selector] || ""}`} />;
                        },
                    });
                }
            }

            const dataSet = this.highlightChanges(data, auditSelectors);

            this.setState({
                columns: [...auditColumns, ...newColumns],
                isLoading: false,
                dataSet,
                meta,
            });
        } catch (err) {
            addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
            this.setState({ isLoading: false });
            console.log(err);
        }
    };

    getAuditUrl = (params: string | null = null) => {
        const { appLabel, model } = this.state;
        let path: string;

        if (params) path = `audit${params}&appLabel=${appLabel}&model=${model}`;
        else path = `audit?appLabel=${appLabel}&model=${model}`;
        const ids = this.props.instance?.id?.toString();
        if (ids) path += `&instanceId=${ids}`;

        return path;
    };

    highlightChanges = (data, auditSelectors) => {
        let lastRowData = { __auditChangedValue: [] } as any;

        for (let row of data) {
            if (!row.__auditChangedValue) row.__auditChangedValue = [];

            for (let selector of auditSelectors) {
                if (row.id != lastRowData.id) continue;
                if (row[selector] != lastRowData[selector]) lastRowData.__auditChangedValue.push(selector);
            }
            lastRowData = row;
        }

        return data;
    };

    getAuditEntries = async (params = window.location.search) => {
        try {
            const path = this.getAuditUrl(params);
            const response = await axios.get(path);
 
            if (!response.data.data[0]) { 
                addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
                return;
            }

            const auditSelectors = Object.keys(response.data.data[0]).filter((sel) => !sel.startsWith("history"));
            const dataSet = this.highlightChanges(response.data.data, auditSelectors);

            this.setState({ dataSet, meta: response.data.meta });
        } catch (err) {
            console.log(err);
        }
    };
    render() {
        if (!this.state.appLabel || !this.state.model) return <></>;
        return (
            <Modal className="audit-window" isOpen={true} fade>
                <CustomModalHeader handlecloseModal={this.props.closeModal} name="Audit" />
                <ModalBody style={{ overflowY: "auto" }}>
                    <CustomDataTable
                        notSelectFirst
                        handleReload={this.getAuditEntries}
                        dataSet={this.state.dataSet}
                        meta={this.state.meta}
                        columns={this.state.columns}
                        keyField="historyId"
                        notAuditable
                    />
                </ModalBody>
                <CustomModalFooter closeModal={this.props.closeModal} cancelName="Close" />
            </Modal>
        );
    }
}

export default withTranslation()(AuditWindow);

const auditColumns = [
    {
        grow: 0.5,
        name: "historyId",
        selector: "historyId",
        sortable: true,
    },
    {
        grow: 1.1,
        name: "historyUser",
        selector: "historyUser.fullName",
        sortable: true,
    },
    {
        grow: 1.5,
        name: "historyDate",
        selector: "historyDate",
        sortable: true,
    },
    {
        name: "historyType",
        selector: "historyType",
        sortable: true,
        cell: (row) => <PrettyCell text={row.historyType} />,
    },
    {
        name: "historyChangeReason",
        selector: "historyChangeReason",
        sortable: true,
    },
    {
        name: "historyTransactionName",
        selector: "historyTransactionName",
        sortable: true, 
    },
];
