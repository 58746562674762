// external
import React, { Fragment, useEffect, useState } from "react";
import { Label, FormGroup, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import "./custom-modal-select.styles.scss";
import Icon from "../icon";
import hasPermission from "../../utils/hasPermissions";
import { components, InputProps, ValueContainerProps } from "react-select";
import { InputTextarea } from "primereact/inputtextarea";
import { InformationBadge } from "../information-badge/information-badge";
import { HideButton } from "./hide-button";
import { useCustomForm } from "../custom-form/context/context";

export const customStyles = {
    indicatorsContainer: (css, state) => {
        return {
            ...css,
            div: {
                marginTop: "-2px",
                padding: state.selectProps.notLabeled ? "0px" : "8px",
            },
        };
    },
    valueContainer: (css, state) => {
        return state.selectProps.notLabeled
            ? {
                  ...css,
                  maxHeight: "27px",
                  paddingLeft: "17px",
              }
            : {
                  ...css,
                  paddingLeft: "17px",
              };
    },
    singleValue: (css) => {
        return { ...css, color: "unset" };
    },
    control: (css, state) => {
        let newCSS = {
            ...css,
            borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            "&:hover": {
                borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            },
            boxShadow: state.isFocused ? "0 0 0 0.2rem #2fc78740" : "",
        };

        if (state.selectProps.notLabeled) {
            newCSS = {
                ...newCSS,
                minHeight: "27px",
                height: "27px",
            };
        }

        return newCSS;
    },
    option: (css, state) => {
        let bgc = "";

        if (state.isSelected) {
            bgc = "#725ec3";
        } else if (state.isFocused) {
            bgc = "#2fc78740";
        }

        return {
            ...css,
            backgroundColor: bgc,
            paddingTop: "3px",
            paddingBottom: "3px",
            wordBreak: "break-word",
        };
    },

    menu: (css) => {
        return { ...css, zIndex: 2 };
    },
};

export const textareaStyles = {
    indicatorsContainer: (css, state) => {
        return {
            ...css,
            div: {
                height: "100px",
                marginTop: "-2px",
                padding: state.selectProps.notLabeled ? "0px" : "8px",
            },
        };
    },
    valueContainer: (css, state) => {
        return state.selectProps.notLabeled
            ? {
                  ...css,
                  maxHeight: "27px",
                  paddingLeft: "17px",
              }
            : {
                  ...css,
                  overflow: "unset",
                  paddingLeft: "17px",
                  height: "auto",
              };
    },
    singleValue: (css) => {
        return {
            ...css,
            color: "unset",
            wordBreak: "break-all",
            whiteSpace: "normal",
            textOverflow: "unset",
            overflow: "auto",
            alignItems: "flex-start",
            transform: "none",
            top: 0,
            paddingTop: "1rem",
            paddingBottom: "1rem",
        };
    },
    control: (css, state) => {
        let newCSS = {
            ...css,
            borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            "&:hover": {
                borderColor: state.isFocused ? "#2fc78790" : "#eaecf0",
            },
            boxShadow: state.isFocused ? "0 0 0 0.2rem #2fc78740" : "",
        };

        if (state.selectProps.notLabeled) {
            newCSS = {
                ...newCSS,
                minHeight: "27px",
                height: "27px",
            };
        }

        return newCSS;
    },
    option: (css, state) => {
        let bgc = "";

        if (state.isSelected) {
            bgc = "#725ec3";
        } else if (state.isFocused) {
            bgc = "#2fc78740";
        }

        return {
            ...css,
            backgroundColor: bgc,
            paddingTop: "3px",
            paddingBottom: "3px",
            wordBreak: "break-all",
        };
    },

    menu: (css) => {
        return { ...css, zIndex: 2 };
    },

    placeholder: (css) => {
        return { ...css, alignItems: "flex-start", transform: "none", top: 0, paddingTop: "1rem" };
    },
};

const CustomModalCreatableSelect = (props) => {
    const {
        t,
        handleChange,
        handleInputChange,
        options,
        colSm,
        labelSm,
        labelName,
        className,
        name,
        value,
        placeholder,
        disabled,
        handleCreateOption,
        handleDeleteOption,
        labelClassName = "",
        handleOnFocus,
        hideLabel,
        menuPortalTarget,
        children,
        deletePerm = false,
        textarea = false,
        informationBadge,
        informationBadgeProps,
        required = false,
        autofocus = false,
        isClearable = true,
        innerRef,
        onMenuOpen,
    } = props;

    let { errors } = props;

    const { isHideMode } = useCustomForm();

    const [isHidden, setIsHidden] = useState<boolean>(false);

    const CustomOptions = ({ innerRef, innerProps, isDisabled, children, data, isSelected }) =>
        !isDisabled ? (
            <div ref={innerRef} {...innerProps} className={`deleteable-option ${isSelected ? "selected-opt" : ""}`}>
                {children}
                {deletePerm ? (
                    hasPermission(deletePerm) && (
                        <Icon
                            name="x"
                            className="btn btn-custom-round mr-20 mt-5"
                            style={{ float: "right" }}
                            onClick={() => handleDeleteOption(data)}
                        />
                    )
                ) : (
                    <Icon
                        name="x"
                        className="btn btn-custom-round mr-20 mt-5"
                        style={{ float: "right" }}
                        onClick={() => handleDeleteOption(data)}
                    />
                )}
            </div>
        ) : null;

    if (value && errors && errors[0] == "This field is required." && errors.length == 1) {
        errors = undefined;
    }

    useEffect(() => {
        innerRef && (innerRef.current = document.querySelector("#select-input-" + name));
    }, []);

    const Input = (props: InputProps<any>) => {
        if (props.isHidden) {
            return <InputTextarea {...props} className=" textarea w-100 p-0 border-0 " />;
        }
        return <InputTextarea {...props} className="textarea w-100 p-0 border-0 " />;
    };

    if (isHidden && !isHideMode) return <></>;

    return (
        <Fragment>
            <FormGroup row>
                {!hideLabel && (
                    <Label className={labelClassName} sm={labelSm || 3}>
                        {required && "* "}
                        {t(labelName)}:
                    </Label>
                )}
                <Col sm={colSm}>
                    {informationBadge && <InformationBadge {...informationBadgeProps} selector={name} />}
                    {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                    {!textarea ? (
                        <CreatableSelect
                            menuPortalTarget={menuPortalTarget}
                            onFocus={handleOnFocus}
                            className={`${className || ""} ${errors ? "custom-gt-select is-invalid" : ""}`}
                            isClearable={isClearable}
                            onChange={handleChange}
                            onInputChange={handleInputChange}
                            options={options}
                            styles={customStyles}
                            name={name}
                            value={options.find((o) => o.value == value) || null} //id (value) of obj
                            placeholder={placeholder ? `${t(placeholder)}...` : `${t(labelName)}...`}
                            isDisabled={disabled || false}
                            onCreateOption={(e) => handleCreateOption && handleCreateOption(e, name)}
                            components={!!handleDeleteOption && { Option: CustomOptions }}
                            formatCreateLabel={(v) => <span style={{ color: "#2fc787" }}>{`${t("Add")} "${v}"`}</span>}
                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                            autoFocus={autofocus}
                            onMenuOpen={onMenuOpen}
                            inputId={"select-input-" + name}
                        />
                    ) : (
                        <CreatableSelect
                            menuPortalTarget={menuPortalTarget}
                            onFocus={handleOnFocus}
                            className={`${className || ""} ${errors ? "custom-gt-select is-invalid" : ""}`}
                            isClearable={isClearable}
                            onChange={handleChange}
                            onInputChange={handleInputChange}
                            options={options}
                            styles={textareaStyles}
                            name={name}
                            value={options.find((o) => o.value == value) || null} //id (value) of obj
                            placeholder={placeholder ? `${t(placeholder)}...` : `${t(labelName)}...`}
                            isDisabled={disabled || false}
                            onCreateOption={(e) => handleCreateOption && handleCreateOption(e, name)}
                            components={!!handleDeleteOption ? { Input, Option: CustomOptions } : { Input }}
                            formatCreateLabel={(v) => <span style={{ color: "#2fc787" }}>{`${t("Add")} "${v}"`}</span>}
                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                            autoFocus={autofocus}
                            onMenuOpen={onMenuOpen}
                            inputId={"select-input-" + name}
                        />
                    )}
                    {errors && <Label className="invalid-feedback">{t(errors)}</Label>}
                </Col>
                {children}
            </FormGroup>
        </Fragment>
    );
};

export default withTranslation()(CustomModalCreatableSelect);
