import React from "react";

class SuperscriptDisplay extends React.Component<any, any> {
    // needed to control the superscript div
    myRef = React.createRef() as any;

    componentDidMount() {
        // pretty display if values given
        this.myRef.current.innerHTML = typeof this.props.value == "string" ? parseSuperscript(this.props.value) : this.props.value ?? "";
    }

    componentDidUpdate() {
        this.myRef.current.innerHTML = typeof this.props.value == "string" ? parseSuperscript(this.props.value) : this.props.value ?? "";
    }

    render() {
        return (
            <div
                data-tag="___react-data-table-allow-propagation___"
                style={this.props.style || {}}
                className={`${this.props.className || ""}`}
                ref={this.myRef}
            ></div>
        );
    }
}

// parse to pretty html
export const parseSuperscript = (text) => {
    let r = ["↑", "↓", "→", "←"];
    let rm = [
        '<sub data-tag="___react-data-table-allow-propagation___">',
        "</sub>",
        '<sup data-tag="___react-data-table-allow-propagation___">',
        "</sup>",
    ];

    r.forEach((e, i) => (text = text.replaceAll(e, rm[i])));
    return text;
};

export const parseSuperscriptToString = (text: string) => {
    return text.replaceAll(/[↑↓→←]/g, "");
};

export default SuperscriptDisplay;
