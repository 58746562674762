// internal
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React from "react";

import { PRETTY_CELL_CONFIG, prettyCellColorsMap } from "./pretty-cell-helpers";
import { TooltipHOC, Icon } from "components";

import "./styles.scss";

export interface IPrettyCellProps {
    text: string | undefined;
    t?: (str: string) => string;
}

interface IPrettyCell {
    icon: [string, string] | string;
    alias: string;
    color: string;
}

const PrettyCell = ({ text, t = undefined }: IPrettyCellProps) => {
    const status = text?.replaceAll(" ", "-").toLowerCase() || "";

    const { statuses } = useSelector((state) => ({ statuses: state.styles.statuses }));

    const prettyCell: IPrettyCell | undefined = statuses[status] || PRETTY_CELL_CONFIG[status];

    if (!prettyCell) return <></>;

    let statusText = prettyCell.alias || status;

    statusText = t ? t(statusText) : statusText;

    return (
        <TooltipHOC tooltipContent={statusText}>
            <div className={`prime-table-pretty-cell`} style={{ backgroundColor: prettyCell?.color || prettyCellColorsMap.defaultColor }}>
                {prettyCell?.icon && <Icon name={prettyCell.icon} />}
                <>{statusText}</>
            </div>
        </TooltipHOC>
    );
};

export default withTranslation()(PrettyCell);
