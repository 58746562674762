export type PreviewTabKeysType = "samples" | "samplingSites" | "additionalInformation" | "patient" | "formulations" | "attachmentsForOrder";
export type PreviewTabValuesType =
    | "Samples"
    | "Sampling sites"
    | "Additional informations"
    | "Patient"
    | "Formulations"
    | "Attachments for order";

export type PreviewTabs = {
    [key in PreviewTabKeysType]: PreviewTabValuesType;
};

export const previewTabs: PreviewTabs = {
    samples: "Samples",
    samplingSites: "Sampling sites",
    additionalInformation: "Additional informations",
    patient: "Patient",
    formulations: "Formulations",
    attachmentsForOrder: "Attachments for order",
};
