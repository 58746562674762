// AUTH
export const UPDATE_AUTH = "UPDATE_AUTH";
export const updateAuth = (auth) => ({
    type: UPDATE_AUTH,
    payload: auth,
});

export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";
export const updatePermissions = (permissions) => ({
    type: UPDATE_PERMISSIONS,
    payload: permissions,
});

export const UPDATE_REQUIREMENTS = "UPDATE_REQUIREMENTS";
export const updateRequirements = (requirements) => ({
    type: UPDATE_REQUIREMENTS,
    payload: requirements,
});

export const UPDATE_OPTIONS = "UPDATE_OPTIONS";
export const updateOptions = (options) => ({
    type: UPDATE_OPTIONS,
    payload: options,
});

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const updateLanguage = (language) => ({
    type: UPDATE_LANGUAGE,
    payload: language,
});

// SETTINGS
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const updateSettings = (settings) => ({
    type: UPDATE_SETTINGS,
    settings,
});

export const UPDATE_SYSTEM_SETTINGS = "UPDATE_SYSTEM_SETTINGS";
export const updateSystemSettings = (systemSettings) => ({
    type: UPDATE_SYSTEM_SETTINGS,
    systemSettings,
});

// TOAST
export const ADD_TOAST = "ADD_TOAST";
export const addToast = (data) => ({
    type: ADD_TOAST,
    data,
});

export const REMOVE_TOAST = "REMOVE_TOAST";
export const removeToast = (id) => ({
    type: REMOVE_TOAST,
    id,
});

export const UPDATE_WINDOW_SIZE = "UPDATE_WINDOW_SIZE";
export const updateWindowSize = (id) => ({
    type: UPDATE_WINDOW_SIZE,
    id,
});

// STYLES
export const UPDATE_STYLES = "UPDATE_STYLES";
export const updateStyles = (styles) => ({
    type: UPDATE_STYLES,
    styles,
});

//META APPS TYPES
export const UPDATE_META_APP_TYPES = "UPDATE_META_APP_TYPES";
export const updateMetaAppTypes = (metaAppTypes) => ({
    type: UPDATE_META_APP_TYPES,
    payload: metaAppTypes,
});
