import React, { useEffect, useState } from "react";

import { unstable_batchedUpdates } from "react-dom";
import Axios from "axios";

interface IUseFetchDataArguments {
    url: string | undefined;
    params?: any;
    triggerValues?: any;
    withIsBusy?: boolean;
    initState?: any;
    type?: "GET" | "POST";
    postData?: any;
    onSuccess?: (data) => void;
    onError?: (err) => void;
}

interface IUseFetchData<T> {
    data: T | undefined | null;
    setData: React.Dispatch<React.SetStateAction<T | null | undefined>>;
    isBusy: boolean;
}

export const useFetchData = <T,>({
    url,
    onSuccess,
    onError,
    params,
    triggerValues,
    withIsBusy = false,
    initState = undefined,
    type = "GET",
    postData,
}: IUseFetchDataArguments): IUseFetchData<T> => {
    const [data, setData] = useState<T | undefined | null>(initState);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    useEffect(() => {
        if (!url) return;
        const source = Axios.CancelToken.source();
        const fetchData = async () => {
            try {
                withIsBusy && setIsBusy(true);
                const response =
                    type == "GET"
                        ? await Axios.get(url, { params, cancelToken: source.token })
                        : await Axios.post(url, postData, { params, cancelToken: source.token });
                unstable_batchedUpdates(() => {
                    setData(response.data);
                    onSuccess && onSuccess(response.data);
                });
            } catch (err: any) {
                onError && onError(err);
                console.error(err);
            } finally {
                withIsBusy && setIsBusy(false);
            }
        };
        fetchData();
    }, [...(triggerValues || [])]);
    return { data, setData, isBusy };
};
