import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Input } from "reactstrap";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import axios from "axios";

import { generateAdvancedFiltersOptions, IAdvancedFiltersOptions, IAdvancedFiltersOptionsType } from "../../helpers/generateAdvancedFilter";
import { customStyles } from "../../../../components/custom-modal-elements/custom-modal-select";
import { IAdvancedFilterConfig, IPrimeTableFilters } from "../../interfaces";
import { FilteringType } from "components/prime-data-table/interfaces";
import CustomSpinner from "../../../custom-spinner/custom-spinner";
import CustomButton from "../../../custom-button/custom-button";
import { formatDate } from "../../../../utils/formatDate";
import DatePicker from "../../../date-time-picker";
import Icon from "../../../icon";

interface IAdvanceFilters {
    onReload: (arg: IPrimeTableFilters | undefined) => void;
    tableFilters?: IPrimeTableFilters;
    advancedFiltersConfig: IAdvancedFilterConfig[];
}

interface IExtendedAdvanceFilters extends IAdvanceFilters {
    togglePopup: (arg: boolean) => void;
    advancedFiltersOptions: IAdvancedFiltersOptions[];
    t: (arg: string) => string;
    filterTypeOptions: IFilterTypeOptions;
}

interface IFilter {
    id: string;
    filterField: string | undefined | null;
    filterType: FilteringType | undefined | "";
    value: string | number | undefined;
}

interface IValidatedFilter {
    id: string;
    filterField: string;
    filterType: FilteringType;
    value: string;
}

type IFilterTypeOptions = {
    [key in IAdvancedFiltersOptionsType]: ILabelValue<FilteringType>[];
};

interface IMappedOptions {
    [key: string]: string[];
}

type IInputType = "column" | "condition" | "value";

const filterTypeOptions: IFilterTypeOptions = {
    number: [
        { label: "equals", value: "__exact" },
        { label: "like", value: "__icontains" },
        { label: "lower than", value: "__lt" },
        { label: "lower or equal", value: "__lte" },
        { label: "greater than", value: "__gt" },
        { label: "greater or equal", value: "__gte" },
    ],
    date: [
        { label: "equals", value: "__icontains" },
        { label: "lower than", value: "__lt" },
        { label: "lower or equal", value: "__lte" },
        { label: "greater than", value: "__gt" },
        { label: "greater or equal", value: "__gte" },
    ],
    text: [
        { label: "equals", value: "__exact" },
        { label: "like", value: "__icontains" },
    ],
    dropdown: [{ label: "equals", value: "__exact" }],
    checkbox: [{ label: "equals", value: "__exact" }],
};

const checkboxOptions: ILabelValue[] = [
    { label: "Selected", value: "True" },
    { label: "Not selected", value: "False" },
];

export const AdvanceFiltersWrapper = (props: IAdvanceFilters) => {
    const { advancedFiltersConfig } = props;
    if (!advancedFiltersConfig) return <></>;

    const { t } = useTranslation();

    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [advancedFiltersOptions, setAdvancedFiltersOptions] = useState<IAdvancedFiltersOptions[] | undefined>(undefined);

    const _filterTypeOptions: IFilterTypeOptions = useMemo(
        () => ({
            number: filterTypeOptions.number.map((o) => ({ ...o, label: t(o.label) })),
            date: filterTypeOptions.date.map((o) => ({ ...o, label: t(o.label) })),
            text: filterTypeOptions.text.map((o) => ({ ...o, label: t(o.label) })),
            dropdown: filterTypeOptions.dropdown.map((o) => ({ ...o, label: t(o.label) })),
            checkbox: filterTypeOptions.checkbox.map((o) => ({ ...o, label: t(o.label) })),
        }),
        []
    );

    useEffect(() => {
        setAdvancedFiltersOptions(generateAdvancedFiltersOptions(advancedFiltersConfig, t));
    }, []);

    return (
        <>
            <CustomButton text="Advanced filtering" icon={{ name: ["fas", `search`] }} withoutPerm onClick={() => setFilterVisible(true)} />
            {filterVisible && advancedFiltersOptions && (
                <AdvanceFilters
                    {...props}
                    togglePopup={(popUpOpen) => setFilterVisible(popUpOpen)}
                    advancedFiltersOptions={advancedFiltersOptions}
                    filterTypeOptions={_filterTypeOptions}
                    t={t}
                />
            )}
        </>
    );
};

interface IFilterValueOptions {
    [key: string]: { [key: string]: ILabelValue[] };
}

const AdvanceFilters = (props: IExtendedAdvanceFilters) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [filters, setFilters] = useState<IFilter[]>([]);
    const [filterValueOptions, setFilterValueOptions] = useState<IFilterValueOptions | undefined>(undefined);

    const { onReload, tableFilters, togglePopup, advancedFiltersOptions, t, filterTypeOptions } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                togglePopup(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const getOptions = async () => {
            const filtersWithOptions = advancedFiltersOptions.filter((filter) => filter.filterOptionConfig);
            let mappedOptions: IMappedOptions = {};

            filtersWithOptions.forEach((o) => {
                const optionsKey: string = o.filterOptionConfig?.appModelPath as string;
                const filterField: string = o.filterOptionConfig?.field as string;

                mappedOptions[optionsKey] = mappedOptions[optionsKey] ? [...mappedOptions[optionsKey], filterField] : [filterField];
            });

            if (Object.keys(mappedOptions).length != 0) {
                try {
                    const response = await axios.put("filters/fields-choices", mappedOptions);
                    setFilterValueOptions({
                        ...response.data,
                        additionalOptions: { checkboxOptions: checkboxOptions.map((o) => ({ ...o, label: t(o.label) })) },
                    });
                } catch (e) {}
            }

            generateFilters();
        };

        const generateFilters = () => {
            if (tableFilters) {
                const _tableFilters = { ...tableFilters };
                let _filters: IFilter[] = [];

                Object.keys(_tableFilters).map((columnFilterKey) => {
                    _tableFilters[columnFilterKey].forEach((columnsFilter) => {
                        if (typeof columnsFilter.value != "string" && typeof columnsFilter.value != "number") {
                            _filters.push(
                                {
                                    id: uuid(),
                                    filterField: columnFilterKey,
                                    filterType: "__gte",
                                    value: columnsFilter.value.startDt,
                                },
                                {
                                    id: uuid(),
                                    filterField: columnFilterKey,
                                    filterType: "__lte",
                                    value: columnsFilter.value.endDt,
                                }
                            );
                        } else {
                            _filters.push({
                                id: uuid(),
                                filterField: columnFilterKey,
                                filterType: columnsFilter.filteringType,
                                value: columnsFilter.value,
                            });
                        }
                    });
                });

                setFilters(_filters);
            } else {
                setFilters([{ id: uuid(), filterField: "", filterType: "", value: "" }]);
            }
        };

        getOptions();
    }, [advancedFiltersOptions]);

    const handleChange = (inputValue: string | undefined | FilteringType, index: number, inputType: IInputType) => {
        const _filters = [...filters];
        const advancedFilter = advancedFiltersOptions.find((f) => f.filterField == inputValue);

        switch (inputType) {
            case "column":
                if (advancedFilter?.filterOptionConfig || advancedFilter?.type == "checkbox") {
                    _filters[index].filterType = "__exact";
                    _filters[index].value = "";
                } else {
                    _filters[index].filterType = "";
                    _filters[index].value = "";
                }

                _filters[index].filterField = inputValue;
                break;
            case "condition":
                _filters[index].filterType = inputValue as FilteringType;
                break;
            case "value":
                _filters[index].value = inputValue;
                break;
        }

        setFilters(_filters);
    };

    const handleAddFilter = () => {
        const _filters = [...filters];

        _filters.push({ id: uuid(), filterField: "", filterType: "", value: "" });
        setFilters([..._filters]);
    };

    const handleDeleteFilter = (index) => {
        if (filters.length == 1) {
            setFilters([{ id: filters[0].id, filterField: null, filterType: "", value: "" }]);
        } else {
            const _filters = [...filters];

            _filters.splice(index, 1);
            setFilters([..._filters]);
        }
    };

    const handleRefresh = () => {
        const validatedFilters: IValidatedFilter[] = filters.filter((filter) =>
            filter.filterField &&
            filter.filterField.trim().length > 0 &&
            filter.filterType &&
            filter.filterType.trim().length > 0 &&
            filter.value &&
            typeof filter.value == "string"
                ? filter.value.trim().length > 0
                : filter.value
        ) as IValidatedFilter[];

        let primeTableFilters: IPrimeTableFilters = {};

        validatedFilters.forEach((filter) => {
            if (primeTableFilters[filter.filterField])
                primeTableFilters[filter.filterField].push({ filteringType: filter.filterType, value: filter.value });
            else primeTableFilters[filter.filterField] = [{ filteringType: filter.filterType, value: filter.value }];
        });

        const keys = Object.keys(primeTableFilters);

        if (keys.length > 0) {
            keys.map((filterKey) => {
                if (filterKey.endsWith("Dt")) {
                    if (
                        primeTableFilters[filterKey].length == 2 &&
                        primeTableFilters[filterKey][0].filteringType == "__gte" &&
                        primeTableFilters[filterKey][1].filteringType == "__lte"
                    ) {
                        primeTableFilters[filterKey] = [
                            {
                                filteringType: "__range",
                                value: {
                                    startDt: primeTableFilters[filterKey][0].value,
                                    endDt: primeTableFilters[filterKey][1].value,
                                } as any,
                            },
                        ];
                    }
                }
            });

            onReload(primeTableFilters);
        } else onReload(undefined);

        togglePopup(false);
    };

    return (
        <>
            <div className="popup" ref={wrapperRef}>
                {filterValueOptions ? (
                    <>
                        {filters.map((filter, index) => {
                            const advancedFilter = advancedFiltersOptions.find((f) => f.filterField == filter.filterField);

                            return (
                                <Row key={`custom-filter-table-${filter.id}`} className="advanced-filters-row">
                                    <Col sm={4}>
                                        {/* Filter field input */}
                                        <Select
                                            className="custom-gt-select"
                                            styles={customStyles}
                                            options={advancedFiltersOptions}
                                            onChange={(e) => handleChange(e.filterField, index, "column")}
                                            placeholder={`${t("Select")}...`}
                                            value={advancedFilter ? advancedFilter : null}
                                            getOptionLabel={(o) => o.filterLabel}
                                            getOptionValue={(o) => o.filterField}
                                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        {/* Filter type input */}
                                        <Select
                                            className="custom-gt-select"
                                            styles={customStyles}
                                            options={advancedFilter ? filterTypeOptions[advancedFilter.type] : []}
                                            onChange={(o) => handleChange(o.value, index, "condition")}
                                            placeholder={`${t("Select")}...`}
                                            value={
                                                advancedFilter
                                                    ? filterTypeOptions[advancedFilter.type].find((o) => o.value == filter.filterType)
                                                    : null
                                            }
                                            isDisabled={advancedFilter?.filterOptionConfig || advancedFilter?.type == "checkbox"}
                                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        {/* Filter value input */}
                                        {advancedFilter?.filterOptionConfig ? (
                                            /* If filter has options*/
                                            <Select
                                                className="custom-gt-select"
                                                styles={customStyles}
                                                options={
                                                    filterValueOptions[advancedFilter.filterOptionConfig.appModelPath][
                                                        advancedFilter.filterOptionConfig.field
                                                    ]
                                                }
                                                getOptionLabel={(o) => t(o.label)}
                                                getOptionValue={(o) => o.value}
                                                onChange={(o) => handleChange(o.value, index, "value")}
                                                placeholder={`${t("Select")}...`}
                                                value={filterValueOptions[advancedFilter.filterOptionConfig.appModelPath][
                                                    advancedFilter.filterOptionConfig.field
                                                ].find((o) => o.value == filter.value)}
                                                noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                                            />
                                        ) : advancedFilter?.type == "checkbox" ? (
                                            /* If filter is a checkbox*/
                                            <Select
                                                className="custom-gt-select"
                                                styles={customStyles}
                                                options={filterValueOptions.additionalOptions.checkboxOptions}
                                                getOptionLabel={(o) => t(o.label)}
                                                getOptionValue={(o) => o.value}
                                                onChange={(o) => handleChange(o.value, index, "value")}
                                                placeholder={`${t("Select")}...`}
                                                value={
                                                    filterValueOptions.additionalOptions.checkboxOptions?.find(
                                                        (o) => o.value == filter.value
                                                    ) || null
                                                }
                                                noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                                            />
                                        ) : filter.filterField?.endsWith("Dt") ? (
                                            /* If filter is a date*/
                                            <DatePicker
                                                autoComplete="off"
                                                selected={filter.value && new Date(filter.value)}
                                                onChange={(date) => handleChange(formatDate(date), index, "value")}
                                                dateFormat="yyyy-MM-dd"
                                                className="rui-datetimepicker form-control w-100"
                                            />
                                        ) : (
                                            /* If filter is a number or o text*/
                                            <Input
                                                type="text"
                                                onChange={(e) => handleChange(e.target.value, index, "value")}
                                                value={filter.value}
                                            />
                                        )}
                                    </Col>
                                    <Col sm={1} className="ml-4">
                                        <span
                                            className="btn btn-custom-round mt-10"
                                            onClick={() => handleDeleteFilter(index)}
                                            title={t("Remove filter")}
                                        >
                                            <Icon name={["fas", "minus"]} color="#ef5164ee" />
                                        </span>
                                    </Col>
                                </Row>
                            );
                        })}

                        <Row>
                            <Col sm="12">
                                <div className="filter-placeholder" onClick={handleAddFilter}>
                                    {t("Click to add filter")}
                                </div>
                            </Col>
                        </Row>

                        <span className="btn btn-outline-brand ml-5 mt-5 mr-10 mb-5" onClick={handleRefresh} title={t("Filter")}>
                            {t("Filter")}
                        </span>
                    </>
                ) : (
                    <CustomSpinner />
                )}
            </div>
        </>
    );
};
