import { useEffect, useState } from "react";
import Axios, { CancelToken } from "axios";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { IData } from "hooks/useGetData";;
import { useDebounce } from "./useDebounce";

interface IUseFetchTableData<T> {
    url: string | undefined;
    triggerValues?: any[];
    mapData?: (data: T[]) => any;
    afterFetch?: (data: T[]) => any;
    initParams?: string;
    shouldFetch?: any;
    additionalParams?: string;
    configParams?: any;
    handleUnmount?: Function;
    handleError?: Function;
    getLocation?: boolean;
    groupedOnInit?: boolean;
}

export function useFetchTableData<T = any>({
    url,
    triggerValues = [],
    mapData,
    afterFetch,
    initParams = "?pageSize=10&page=1",
    shouldFetch = undefined,
    additionalParams = "",
    handleUnmount = undefined,
    handleError = undefined,
    configParams = undefined,
    getLocation = false,
    groupedOnInit = false,
}: IUseFetchTableData<T>) {
    const [data, setData] = useState<IData<T>>(undefined);
    const [urlParams, setParams] = useState<string>(initParams);
    const [random, setRandom] = useState<number>(0);

    const refreshData: Function = () => setRandom(uuid());

    const { state } = useLocation<{ instanceId: number }>();

    triggerValues = triggerValues.map((value) => useDebounce(value, 250, 0));

    useEffect(() => {
        if (!(shouldFetch != false)) return;
        const source = Axios.CancelToken.source();
        const getData = async (token: CancelToken | undefined = undefined) => {
            let request = { ...configParams };

            if (!url) {
                source.cancel();
                setData({ data: [], meta: undefined });
                afterFetch && afterFetch([]);
                return;
            }

            const locationParams = getLocation && state?.instanceId ? `&search=${state?.instanceId}&search_field=id` : "";

            try {
                const response = await Axios.get(url + urlParams + additionalParams + locationParams, { ...request, cancelToken: token });

                const data = response.data.data ? response.data.data : response.data;
                const dataToSet = mapData ? mapData(data) : data;

                setData({ data: dataToSet, meta: response.data.meta });
                afterFetch && afterFetch(dataToSet);
            } catch (err) {
                handleError && handleError(err);
            }
        };
        if (groupedOnInit && !urlParams.includes("grouping=")) return;
        getData(source.token);
        return () => source.cancel();
    }, [urlParams, random, ...triggerValues, shouldFetch]);

    useEffect(() => {
        return () => {
            handleUnmount && handleUnmount();
        };
    }, []);

    const handleReload = (params?: string, refresh?: boolean) => {
        if (refresh) refreshData();
        else if (params) setParams(params);
    };

    return { data, setData, refreshData, handleReload };
}
