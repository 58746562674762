import { Checkbox } from "primereact/checkbox";
import React from "react";

import { ICheckboxInputPlaceholderProps } from "../interfaces/placeholders-interfaces";
import { getNestedObjValue } from "../helpers/primeHelpers";

// table checkbox placeholder, you send it in the column as body prop
// body: (rowData: IPrimeRowData<ProperInterface>) => (<CheckboxInputPlaceholder inputData={inputData} />),
// you also have to pass in column a className prop, className="checkbox-container", for checkbox to be centered and to have a proper color
export const CheckboxInputPlaceholder = ({ rowData, className = "", style = {}, value = undefined }: ICheckboxInputPlaceholderProps) => (
    <Checkbox
        name={rowData.fieldName}
        onChange={() => {}}
        checked={value !== undefined ? value : getNestedObjValue(rowData.row, rowData.fieldName.split("."))}
        disabled
        id="input-placeholder"
        className={`prime-table-checkbox ${className}`}
        key={`prime-input-checkbox-${rowData.fieldName}-${rowData.row.id}`}
        style={style}
    />
);
