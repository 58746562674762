import Axios from "axios";

import { IBaseRecord, OnEditChangeType } from "../interfaces/table-interfaces";
import addToast from "../../../utils/addToast";
import { EditInputEvent } from "../interfaces";

export const onSubmitEdit = async <T extends IBaseRecord>(
    rowData: T,
    e: EditInputEvent,
    url: string,
    dataSet: any[],
    params: any,
    refresh: boolean,
    mapPatchResponse: Function,
    hideSuccessToast: boolean,
    onEditChange?: OnEditChangeType<T>,
    reqParams: IReqParams = {}
) => {
    const { name, value } = e.target;

    try {
        const editResponse = await Axios.patch(`${url}/${rowData.id}`, {
            ...params,
            [name]: !!value || value === false ? value : null,
            reqParams,
        });
        let editData = editResponse.data.data;

        if (!refresh) {
            let dataToSet;

            if (mapPatchResponse) dataToSet = mapPatchResponse(dataSet, editData, rowData.id);
            else dataToSet = dataSet.map((r) => (r.id == rowData.id ? { ...editData, isSelected: true } : { ...r, isSelected: false }));
            onEditChange && onEditChange(dataToSet, { target: { name, value } }, rowData);
        }

        !hideSuccessToast && addToast({ title: "Data has been edited." });

        return editResponse;
    } catch (error: any) {
        const { message } = error?.response;
        const defaultMessage = "Something went wrong while editing data.";
        addToast({ color: "danger", title: message ? message : defaultMessage });

        if (error.response?.status == "403") return error.response.data;
        return error.response?.status;
    }
};

export const onClientSideEdit = async <T extends IBaseRecord>(
    rowData: any,
    e: EditInputEvent,
    dataSet: any[],
    mapPatchResponse: Function,
    onEditChange?: OnEditChangeType<T>
) => {
    const { name, value } = e.target;

    if (value == rowData[name] || ((rowData[name] == null || rowData[name] == undefined) && !value)) return undefined;

    let dataToSet;
    let editData = dataSet.find((r) => r.id == rowData.id);
    editData[name] = value;

    if (mapPatchResponse) dataToSet = mapPatchResponse(dataSet, editData, rowData.id);
    else dataToSet = dataSet.map((r) => (r.id == rowData.id ? { ...editData, isSelected: true } : { ...r, isSelected: false }));

    onEditChange && onEditChange(dataToSet, { target: { name, value } }, rowData);

    return { status: 200 };
};
