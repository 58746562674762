import React, { useEffect, useState } from "react";
import { Label, FormGroup, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import Select from "react-select";
import moment from "moment";

import { customStyles } from "../../components/custom-modal-elements/custom-modal-select";
import DatePicker from "../../components/date-time-picker";
import LabelError from "../label-error/label-error";
import en from "date-fns/locale/en-GB";
import pl from "date-fns/locale/pl";
import Icon from "../icon";

import "../date-time-picker/style.scss";
import { HideButton } from "./hide-button";
import { useCustomForm } from "../custom-form/context/context";
1;
const languages = {
    pl: pl,
    en: en,
};

const CustomModalDatePicker = (props) => {
    const {
        i18n,
        t,
        handleChange,
        forLabel,
        labelText,
        name,
        format = "yyyy-MM-dd",
        errors,
        selectedDate,
        showTimeSelect = undefined,
        timeIntervals = undefined,
        withPortal = undefined,
        colSm,
        labelSm,
        disabled = false,
        noLabel = false,
        onBlur = undefined,
        handleKeyDown = undefined,
        minDate = undefined,
        maxDate = undefined,
        startDate,
        endDate,
        selectsRange,
        inline,
        openCalendar,
        placeholder,
        className = "",
        customInput,
        mask,
        customInputPlaceholder,
        autoFocus = false,
        onCalendarClose = undefined,
        currentMonthOnly = false,
        onChangeCalendar,
        todayButton = false,
        clearable,
        selectsStart = false,
        selectsEnd = false,
        required = false,
    } = props;

    const years = Array.from({ length: 130 }, (_, i) => i + 1900).reverse();
    const yearsOpts = years.map((y) => ({ label: y, value: y }));

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const { isHideMode } = useCustomForm();

    const monthsOpts = months.map((m) => ({ label: t(m), value: m }));
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [customInputDate, setCustomInputDate] = useState("");
    const [isValidCustomInput, setIsValidCustomInput] = useState(true);
    const [headerDate, setHeaderDate] = useState<Array<number | string>>([currentDate.getFullYear(), months[currentDate.getMonth()]]);

    useEffect(() => {
        selectedDate && setCurrentDate(new Date(selectedDate));
    }, [selectedDate]);

    useEffect(() => {
        if (!selectsRange) {
            setHeaderDate([currentDate.getFullYear(), months[currentDate.getMonth()]]);
        }
        onChangeCalendar && onChangeCalendar();
    }, [currentDate]);

    const handleChangeMonth = (direction: number) => {
        setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + direction)));
        setHeaderDate((prevDate) => [prevDate[0], months[currentDate.getMonth()]]);
    };

    const handleChangeSelectHeader = (value: number, type: string) => {
        if (type == "year") {
            setHeaderDate((prevDate) => [value, prevDate[1]]);
        } else {
            setHeaderDate((prevDate) => [prevDate[0], value]);
        }
    };

    const handleClose = () => {
        selectedDate && setCurrentDate(new Date(selectedDate));
    };

    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        const changeMonthSelect = (e) => {
            handleChangeSelectHeader(months.indexOf(e.value), "month");
            changeMonth(months.indexOf(e.value));
            setCurrentDate(new Date(currentDate.setMonth(months.indexOf(e.value))));
        };

        const changeYearSelect = (e) => {
            handleChangeSelectHeader(e.value, "year");
            changeYear(e.value);
            setCurrentDate(new Date(currentDate.setFullYear(e.value)));
        };

        const changeToPrevMonth = () => {
            decreaseMonth();
            handleChangeMonth(-1);
        };

        const changeToNextMonth = () => {
            increaseMonth();
            handleChangeMonth(1);
        };

        return (
            <div
                style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    placeItems: "center",
                }}
            >
                {(!prevMonthButtonDisabled || currentMonthOnly) && (
                    <span className="btn btn-custom-round inline-block mr-20" onClick={changeToPrevMonth}>
                        <Icon name="chevron-left" />
                    </span>
                )}
                <Select
                    className="custom-gt-select w-100px"
                    styles={customStyles}
                    options={yearsOpts}
                    onChange={(e) => changeYearSelect(e)}
                    placeholder={`${props.t("Year")}...`}
                    value={yearsOpts.find((x) => x.value == headerDate[0])}
                />

                <Select
                    className="custom-gt-select w-130px"
                    styles={customStyles}
                    options={monthsOpts}
                    onChange={(e) => changeMonthSelect(e)}
                    placeholder={`${props.t("Month")}...`}
                    value={monthsOpts.find((x) => x.value == headerDate[1])}
                />

                {(!nextMonthButtonDisabled || currentMonthOnly) && (
                    <span className="btn btn-custom-round inline-block ml-20" onClick={changeToNextMonth}>
                        <Icon name="chevron-right" />
                    </span>
                )}
            </div>
        );
    };

    //const addHoursToDate = (d : Date, h : number) => d.setHours(d.getHours()+h)

    const dateChangeWrapper = (date, e) => {
        if (e?.target?.classList.contains("react-datepicker__today-button")) {
            return handleChange(date, name, true);
        }
        //  CLPBDEV-118
        //  THIS IS CURRENTLY DONE ON BACKEND AS DATABASE SEEMS TO HAVE PROBLEMS WITH TIMEZONES
        //  SEE: SERIALIZERS

        // date = addHoursToDate(date, 2)

        return handleChange(date, name);
    };

    const customInputChange = ({ target: { value } }) => {
        setCustomInputDate(value);
    };

    const CustomInput = ({ onChange, value }) => {
        let inputValue = value.replaceAll("/", "").replaceAll("_", "").replaceAll(":", "").replaceAll(" ", "");

        if (inputValue.length == 12) {
            const year = inputValue.substring(0, 4);
            const month = inputValue.substring(4, 6);
            const day = inputValue.substring(6, 8);
            const hour = inputValue.substring(8, 10);
            const minute = inputValue.substring(10, 12);
            const newValue = `${year}-${month}-${day} ${hour}:${minute}`;

            setIsValidCustomInput(moment(newValue).isValid());
        }

        return (
            <InputMask
                mask={mask}
                onChange={onChange}
                className={`rui-datetimepicker form-control w-100 ${errors || !isValidCustomInput ? " is-invalid" : ""}`}
                placeholder={customInputPlaceholder}
                value={value}
                key={name}
                disabled={disabled}
            />
        );
    };

    const clearValue = () => handleChange(undefined, name);
    if (isHidden && !isHideMode) return <></>;

    return (
        <FormGroup row className={`${className} relative-container`}>
            {noLabel ? null : (
                <Label for={name} sm={labelSm || 3}>
                    {required && "* "}
                    {t(labelText)}:
                </Label>
            )}
            <Col sm={colSm}>
                {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                <DatePicker
                    name={name}
                    onChange={dateChangeWrapper}
                    className={`rui-datetimepicker form-control w-100 ${errors ? " is-invalid" : ""}`}
                    id={forLabel}
                    selected={selectedDate ? new Date(selectedDate) : false}
                    dateFormat={format}
                    placeholderText={placeholder ? `${t(placeholder)}` : `${t(labelText)}...`}
                    locale={languages[i18n.language]}
                    autoComplete="off"
                    // showTimeSelect={showTimeSelect}
                    timeIntervals={timeIntervals}
                    withPortal={withPortal}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={disabled}
                    onBlur={onBlur}
                    onKeyDown={handleKeyDown}
                    renderCustomHeader={customHeader}
                    onCalendarClose={onCalendarClose ? onCalendarClose : handleClose}
                    open={openCalendar}
                    timeInputLabel={t("Time") + ":"}
                    showTimeInput={showTimeSelect}
                    minDate={minDate || (currentMonthOnly && new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))}
                    maxDate={maxDate || (currentMonthOnly && new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))}
                    timeFormat="p"
                    autoFocus={autoFocus}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange={selectsRange}
                    selectsStart={selectsStart}
                    selectsEnd={selectsEnd}
                    inline={inline}
                    disabledKeyboardNavigation={true}
                    todayButton={todayButton && t("Today")}
                    customInput={customInput && <CustomInput onChange={customInputChange} value={customInputDate} />}
                />
                {clearable && (selectedDate || startDate || endDate) ? (
                    <span onClick={clearValue} className="pi pi-times date-clear-button" />
                ) : undefined}
                {errors && <LabelError id={forLabel} error={errors} />}
                {!isValidCustomInput && <LabelError id={forLabel} error="The date format is incorrect" />}
            </Col>
        </FormGroup>
    );
};

export default withTranslation()(CustomModalDatePicker);
