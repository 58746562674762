import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Row, Col, Input } from "reactstrap";
import Icon from "../icon";
import { customStyles } from "../../components/custom-modal-elements/custom-modal-select";
import DatePicker from "../date-time-picker";
import { formatDate } from "../../utils/formatDate";
import { withTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import axios from "axios";

const CustomFilterMenu = (props) => {
    const [_filters, setFilters] = useState([] as any);
    const [_options, setOptions] = useState({} as any);
    const wrapperRef = useRef(null);

    const useOutsideHider = (ref) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.handleShowFilter();
                }
            };

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);

            // Unbind the event listener on clean up
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }, [ref]);
    };

    useOutsideHider(wrapperRef);

    useEffect(() => {
        const columns = props.columns
            .filter((o) => "filterChoice" in o)
            .map((o) => [o.filterSelector ? o.filterSelector : o.selector, o.filterChoice]);

        const choices = Object.fromEntries(columns);

        if (Object.keys(choices).length > 0) {
            axios
                .post("filter_options/", choices)
                .then((response) => setOptions(response.data))
                .then(() => {
                    const filters = props.filters.length ? props.filters : [{ id: 0, column: "", condition: "", value: "" }];
                    setFilters(filters);
                });
        } else {
            const filters = props.filters.length ? props.filters : [{ id: 0, column: "", condition: "", value: "" }];
            setFilters(filters);
        }
    }, [props.columns, props.filters]);

    const handleChange = (index, selector, value) => {
        const filters = _filters;

        if (selector == "column") {
            // set condition and clear value for a column with specified options
            const column = props.columns.find((o) => o.selector == value);
            if (column && "filterChoice" in column) {
                filters[index]["condition"] = "__exact";
                filters[index]["value"] = "";
            } else {
                // clear value when changing back from a column with specified options
                const prevColumn = props.columns.find((o) => o.selector == filters[index]["column"]);
                if (prevColumn && "filterChoice" in prevColumn) filters[index]["value"] = "";
            }
        }

        if (value || selector != "value") {
            filters[index][selector] = value;
        }

        // if(value || selector!='value'){
        //     filters[index][selector] = value
        //     filters[index+1] = {id: uuid(), column:'', condition:'', value:''}
        // }
        // else{
        //     if(filters.length > 1){
        //         filters.splice(index,1)
        //     }
        // }

        setFilters([...filters]);
    };

    const handleAddFilter = () => {
        const filters = _filters;
        filters.push({ id: uuid(), column: "", condition: "", value: "" });
        setFilters([...filters]);
    };

    const handleDeleteFilter = (index) => {
        const filter = _filters;
        filter.splice(index, 1);
        setFilters([...filter]);
    };

    const handleRefresh = () => {
        props.handleReload(_filters);
    };

    const conditionOptions = [
        { label: "​", value: "__exact" },
        { label: props.t("like"), value: "__icontains" },
        { label: props.t("lower than"), value: "__lt" },
        { label: props.t("lower or equal"), value: "__lte" },
        { label: props.t("greater than"), value: "__gt" },
        { label: props.t("greater or equal"), value: "__gte" },
    ];

    return (
        <div className="popup" ref={wrapperRef}>
            {_filters.map((filter, i) => {
                const currentColumn = props.columns.find((o) => o.selector == filter.column);
                return (
                    <Row key={`custom-filter-table-${filter.id}`} className="mb-10">
                        <Col>
                            <Select
                                className="custom-gt-select"
                                styles={customStyles}
                                options={props.columns}
                                getOptionLabel={(o) => o.name}
                                getOptionValue={(o) => o.selector}
                                onChange={(o) => handleChange(i, "column", o.selector)}
                                placeholder={`${props.t("Select")}...`}
                                defaultValue={currentColumn}
                            />
                        </Col>
                        <Col>
                            <Select
                                className="custom-gt-select"
                                styles={customStyles}
                                options={conditionOptions}
                                onChange={(o) => handleChange(i, "condition", o.value)}
                                placeholder={`${props.t("Select")}...`}
                                defaultValue={conditionOptions.find((o) => o.value == filter.condition)}
                                value={conditionOptions.find((o) => o.value == filter.condition)}
                                isDisabled={currentColumn && "filterChoice" in currentColumn}
                            />
                        </Col>
                        <Col>
                            {currentColumn && "filterChoice" in currentColumn ? (
                                <Select
                                    className="custom-gt-select"
                                    styles={customStyles}
                                    options={_options[currentColumn.selector]}
                                    getOptionLabel={(o) => props.t(o.name)}
                                    getOptionValue={(o) => o.selector}
                                    onChange={(o) => handleChange(i, "value", o.selector)}
                                    placeholder={`${props.t("Select")}...`}
                                    value={_options[currentColumn.selector]?.find((o) => o.selector == filter.value) || null}
                                    defaultValue={_options[currentColumn.selector]?.find((o) => o.selector == filter.value)}
                                />
                            ) : filter.column.endsWith("Dt") ? (
                                <DatePicker
                                    autoComplete="off"
                                    selected={filter.value && new Date(filter.value)}
                                    onChange={(date) => handleChange(i, "value", formatDate(date))}
                                    dateFormat="yyyy-MM-dd"
                                    className="rui-datetimepicker form-control w-100"
                                />
                            ) : (
                                <Input type="text" onChange={(e) => handleChange(i, "value", e.target.value)} defaultValue={filter.value} />
                            )}
                        </Col>
                        <Col sm="1" className="ml-4">
                            <span
                                className="btn btn-custom-round mt-10"
                                onClick={() => handleDeleteFilter(i)}
                                title={props.t("Remove filter")}
                            >
                                <Icon name={["fas", "minus"]} color="#ef5164ee" />
                            </span>
                        </Col>
                    </Row>
                );
            })}

            <Row>
                <Col sm="12">
                    <div className="filter-placeholder" onClick={handleAddFilter}>
                        {props.t("Click to add filter")}
                    </div>
                </Col>
            </Row>

            <span className="btn btn-outline-brand ml-5 mt-5 mr-10 mb-5" onClick={handleRefresh} title={props.t("Filter")}>
                {props.t("Filter")}
            </span>
        </div>
    );
};

export default withTranslation()(CustomFilterMenu);
