// each table can reference one column resizer
const instances = new Map()
const initializing = [] as any

// merge objects and set to a map
const updateMap = (r,o) => instances.set(r.self, { ...r, ...o} )

// init function
export const initColumnResizer = (ref) => {

    // failsafe against custom-data-table's bad code ( ͡° ͜ʖ ͡°)
    if (initializing.includes(ref)) return
    initializing.push(ref)
    
    const instance = instances.get(ref)

    let prevLastUpdated : number
    let prevColWidths   : Map<string, number>

    if (instance) {
        prevLastUpdated = instance.lastUpdated
        prevColWidths   = instance.colWidths
        destroyColumnResizer(ref)
    }

    // timeout(..., 0) waits for DOM refresh
    setTimeout( () => {
        const tableHeadRow = ref.querySelector('.rdt_TableHeadRow')
        const columns      = ref.querySelectorAll('.rdt_TableCol:not(:last-child)')

        // empty table doesnt have a head row
        if (!tableHeadRow) {
            initializing.splice(initializing.indexOf(ref), 1)
        }

        const mouseDownWrapper = e => handleMouseDown(e, ref)
        const mouseMoveWrapper = e => handleMouseMove(e, ref)
        const mouseUpWrapper   = e => handleMouseUp(ref)

        // add listeners for each column
        columns.forEach( (c,i) => {
            c.setAttribute('colID', i+1 )
            c.addEventListener("mousedown", mouseDownWrapper)
        })

        // add global listeners for mouse movement
        document.addEventListener("mousemove", mouseMoveWrapper)
        document.addEventListener("mouseup",   mouseUpWrapper)

        // keep previous widths if reinitializing
        const _lastUpdated = prevLastUpdated || 0
        const _colWidths   = prevColWidths   || new Map<string, number>()

        // map all relevant vars to the ref
        instances.set(ref, {
            self: ref, 
            columns, 
            lastUpdated: _lastUpdated,
            tableHeadRow,
            currentColumn: null as any,
            colWidths: _colWidths,
            minWidth: 50,
            mouseMoveWrapper,
            mouseUpWrapper
        })

        // failsafe against custom-data-table's bad code ( ͡° ͜ʖ ͡°)
        initializing.splice(initializing.indexOf(ref), 1)

        // don't call if its not needed
        if (prevLastUpdated != 0) updateColWidths(columns, _colWidths, _lastUpdated, ref)
        
    }, 0 ) 
}

// destroy function
export const destroyColumnResizer = (ref) => {
    const instance = instances.get(ref)
    if (!instance) return

    const { mouseMoveWrapper, mouseUpWrapper } = instance

    // remove global listeners
    document.removeEventListener("mousemove", mouseMoveWrapper)
    document.removeEventListener("mouseup",   mouseUpWrapper)

    // remove from the map
    instances.delete(ref)
}

// export function for manual force update
export const updateColumnResizer = (ref) => {
    const instance = instances.get(ref)
    if (!instance) return

    const { columns, colWidths, lastUpdated } = instance
    
    // timeout(..., 0) waits for DOM refresh
    //setTimeout( () => updateColWidths(columns, colWidths, lastUpdated, ref), 0 )
    updateColWidths(columns, colWidths, lastUpdated, ref) 
}

// column resize action starts here
const handleMouseDown = (event, ref) => {
    const instance = instances.get(ref)
    if (!instance) return

    let { tableHeadRow, columns, colWidths } = instance
    if (!tableHeadRow || !tableHeadRow.contains(event.target)) return
    
    updateMap(instance, { currentColumn: event.currentTarget })
}

// column resize action in progress
const handleMouseMove = (event, ref, colIdOverride = null) => {
    const instance = instances.get(ref)
    if (!instance) return

    let { columns, colWidths, currentColumn, minWidth, lastUpdated } = instance
    if (!colIdOverride && !currentColumn) return
    
    const column  = currentColumn
    const colID   = colIdOverride || column.getAttribute('colID')
    const myWidth = colWidths.get(colID)

    // add current width to the value of mouse movement along X axis
    let newWidth = (myWidth || column.offsetWidth) + event.movementX

    // minimum column size so it doesn't look too weird
    newWidth = newWidth < minWidth ? minWidth : newWidth
    colWidths.set(colID, newWidth)

    updateColWidths(columns, colWidths, colID, ref)
    updateMap(instance, { colWidths, lastUpdated : Number(colID) > lastUpdated ? Math.floor(Number(colID)) : lastUpdated })
}

// column resize action ends here
const handleMouseUp = (ref) => {
    const instance = instances.get(ref)
    if (!instance) return

    let { currentColumn } = instance
    if (!currentColumn) return

    currentColumn = null
    updateMap(instance, { currentColumn })
}

// update column widths
const updateColWidths = (columns, colWidths, colID, ref) => {

    // keep the size of all columns on the left static during the drag
    // in order to keep the responsiveness to a certain degree
    for (let i = 0; i < columns.length; i++){
        const j = (1+i).toString()
        
        if ( i <= Number(colID) ){
            let width = colWidths.get(j) 

            if (!width) {
                width = columns[i].offsetWidth
                colWidths.set(j, width)
            }

            setMinMaxWidth(columns[i].style, width)
            
        } else {
            const st = columns[i].style
            st.removeProperty("minWidth")
            st.removeProperty("maxWidth")
        }
        
        const rows  = ref.querySelectorAll(`.rdt_TableRow > .rdt_TableCell:nth-child(${j})`)
        const width = colWidths.get(j)
        
        // rows have to be resized one by one because react-data-table-component uses basic divs instead of actual tables
        rows.forEach( (e:any) => {
            if (!width) return
            setMinMaxWidth(e.style, width)
        })
    }
}

// set both min and max width
const setMinMaxWidth = (style, width) => {
    //if (Math.abs(style.offsetWidth - width) < 5) return;
    width = `${width}px`
    style.maxWidth = width
    style.minWidth = width
}
