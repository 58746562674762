// external
import { withTranslation } from "react-i18next";
import React from "react";
import Axios from "axios";
import fs from "fs";

// internal
import { IQbeResultsColumns } from "../../pages/Reports/AdvancedQueries/sections/dataTableContent/content";
import { getStatusDisplay } from "components/prime-data-table/body-components/pretty-cell/pretty-cell-helpers";
import { handleTimeFormat } from "../../pages/Reports/AdvancedQueries/helpers";
import { formatDate, formatDateWithFullTime } from "../../utils/formatDate";
import { mapToIds } from "../prime-data-table/helpers/primeHelpers";
import addToast from "../../utils/addToast";
import Dropdown from "../bs-dropdown";
import Icon from "../icon";

interface IQbeCSVResults {
    columns: IQbeResultsColumns[];
    qbe_results: any[];
}

const ExportCSV = (props) => {
    const superscriptsUnicodes = {
        "1": "\u00B9",
        "2": "\u00B2",
        "3": "\u00B3",
        "4": "\u2074",
        "5": "\u2075",
        "6": "\u2076",
        "7": "\u2077",
        "8": "\u2078",
        "9": "\u2079",
        "0": "\u2070",
    };

    const subscriptsUnicodes = {
        "1": "\u2081",
        "2": "\u2082",
        "3": "\u2083",
        "4": "\u2084",
        "5": "\u2085",
        "6": "\u2086",
        "7": "\u2087",
        "8": "\u2088",
        "9": "\u2089",
        "0": "\u2080",
    };

    const getData = async () => {
        try {
            const response = await props.fetchFunction();

            const formattedResp = handleTimeFormat(response);

            return formattedResp.data;
        } catch {}
    };

    const handleExportRaw = async () => {
        const dataSet: IQbeCSVResults = await getData();

        downloadCSV(dataSet.qbe_results);
    };

    const handleExportJSON = async () => {
        try {
            const dataSet: IQbeCSVResults = await getData();
            const columns: IQbeResultsColumns[] = dataSet.columns;

            const relationId = columns.find((column) => column.field == "id")?.relation.id;

            const response = await Axios.post("export-instances", { relation: relationId, ids: mapToIds(dataSet.qbe_results) });

            downloadJsonFile(
                response.data,
                `${response.data[0]._import_order}_${(props.qbe.label as string)
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")}__${formatDateWithFullTime(new Date())}`
            );

            addToast({ title: `Data has been exported.` });
        } catch {}
    };

    function downloadJsonFile(jsonData: object, fileName: string) {
        const jsonString = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();

        URL.revokeObjectURL(url);
    }

    const handleExport = async () => {
        const dataSet: IQbeCSVResults = await getData();

        //get map selector => name
        const translateMap = new Map();
        props.dataColumns.map((o) => {
            if (o.field) translateMap.set(o.field, o.header);
            else translateMap.set(o.selector, o.name);
        });

        //formating logic, pretty columns (with map), pretty status (with t)
        const formattedDataSet = dataSet.qbe_results.map((o) => {
            var key,
                keys = Object.keys(o);
            var n = keys.length;
            var newobj = {};
            for (let n = 0; n < keys.length; n++) {
                if (keys[n] == "isSelected") continue;
                key = keys[n];
                if (key.toLowerCase().includes("status")) {
                    if (o[key]) newobj[translateMap.get(key)] = props.t(getStatusDisplay(o[key]));
                } else if (key.toLowerCase().includes("unit")) {
                    var displayUnit = "";
                    if (o[key]) {
                        for (var i = 0; i < o[key].length; i++) {
                            if (o[key][i] == "↓" || o[key][i] == "←" || o[key][i] == "" || o[key][i] == " ") continue;
                            if (o[key][i] == "↑") {
                                displayUnit = displayUnit.concat(subscriptsUnicodes[o[key][i + 1]]);
                                i++;
                            } else if (o[key][i] == "→") {
                                displayUnit = displayUnit.concat(superscriptsUnicodes[o[key][i + 1]]);
                                i++;
                            } else {
                                displayUnit = displayUnit.concat(o[key][i]);
                            }
                        }
                    }
                    newobj[translateMap.get(key)] = displayUnit;
                } else {
                    newobj[translateMap.get(key)] = o[key];
                }
            }
            return newobj;
        });
        downloadCSV(formattedDataSet);
    };

    const downloadCSV = (array) => {
        if (!array || array.length == 0) {
            addToast({
                title: "Export to CSV failed",
                content: "This table is empty",
                color: "danger",
            });
            return;
        }

        const link = document.createElement("a");

        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const pathFix = window.location.pathname.replaceAll("/", "-").toLowerCase();
        const filename = `weblab${pathFix}-export-${formatDate(Date.now())}.csv`;
        const encoding = "utf-8,\uFEFF";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv; charset=${encoding}${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
        link.remove();
    };

    const convertArrayOfObjectsToCSV = (array) => {
        array = array.map((r) => {
            const { excludedRow, isSelected, ...rest } = r;
            return rest;
        });

        const columnDelimiter = ";";
        const lineDelimiter = "\n";
        const keys = Object.keys(array[0]);

        let result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        // jacek question -- kolumny widoczne z columns czy z dataset (backend?) wszystkie czy nie wszystkie
        // czy tlumaczymy fieldy (np status) bo export / import itp tid

        array.forEach((item) => {
            keys.forEach((key, ctr) => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key] ?? "";
            });
            result += lineDelimiter;
        });

        return result;
    };

    return (
        <>
            <Dropdown tag="div" className="ml-auto mr-10" openOnHover showTriangle direction="left">
                <Dropdown.Toggle tag="div" href="#" className="floating-icon dropdown-item mr-10">
                    <span title={props.t("Export to CSV")} className="btn btn-custom-round mt-5">
                        <Icon name={["fas", "download"]} />
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu tag="ul" className="nav" modifiers={{ offset: { offset: "5" } }}>
                    <li>
                        <a className="nav-link pointer" onClick={handleExport}>
                            <Icon name={["fas", "file-csv"]} />
                            <span>{props.t("Visible data to CSV")}</span>
                            <span className="rui-nav-circle"></span>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link pointer" onClick={handleExportRaw}>
                            <Icon name={["fas", "file-csv"]} />
                            <span>{props.t("Raw data to CSV")}</span>
                            <span className="rui-nav-circle"></span>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link pointer" onClick={handleExportJSON}>
                            <Icon name={["fas", "file-csv"]} />
                            <span>{props.t("Save JSON")}</span>
                            <span className="rui-nav-circle"></span>
                        </a>
                    </li>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default withTranslation()(ExportCSV);
