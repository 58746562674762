import { createPortal } from "react-dom";
import React, { useState } from "react";

import { IEditAdvancedRemarksProps } from "components/prime-data-table/interfaces/edit-interfaces";
import { useMountTransition } from "hooks/useMountTransition";
import ModalAdvancedRemarks from "./modal-advanced-remarks";
import { hasRemarks } from "../../../../utils/hasRemarks";

import "./advanced-remark.scss";

export const EditAdvancedRemarks = (props: IEditAdvancedRemarksProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { row, modelInfo, refresh } = props.inputData;

    const hasTransitionedIn = useMountTransition(modalOpen);

    return (
        <div className="advanced-remark" style={{ position: "relative" }}>
            <span onClick={() => setModalOpen(true)} className={hasRemarks(row?.hasRemarks)} />
            {(modalOpen || hasTransitionedIn) &&
                createPortal(
                    <ModalAdvancedRemarks
                        modelName={modelInfo?.model}
                        handleCloseModal={() => setModalOpen(false)}
                        row={row}
                        refreshParentTable={refresh}
                        addFromLookupProps={props.addFromLookupProps}
                        modalOpen={modalOpen}
                    />,
                    document.body
                )}
        </div>
    );
};
