import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";

import { IFilterMultiSelectInputProps } from "../interfaces/filter-interfaces";

//! Backend is not supporting this type of filtering.
//! When this will change this component should be merged with filter-select-input similarly to filter-date-rage-input
export const MemoMultiFilterSelectInput = (props: IFilterMultiSelectInputProps) => {
    return useMemo(() => <FilterMultiSelectInput {...props} />, [props.inputData.filter, props.inputData.onFilterChange]);
};

const FilterMultiSelectInput = ({ inputData, optionFilter = false, placeholder }: IFilterMultiSelectInputProps) => {
    const { t } = useTranslation();
    const { column, onFilterChange, filter, filterChoices } = inputData;

    return (
        <MultiSelect
            key={`prime-filter-input-${column.field}`}
            className="custom-search-table-input prime-table-filter-input-font"
            name={column.field}
            value={filter?.value}
            options={filterChoices}
            onChange={({ target: t }) => onFilterChange({ name: t.name, value: t.value?.length > 0 ? t.value : undefined }, "__exact")}
            optionLabel="label"
            optionValue="value"
            placeholder={placeholder ? t(placeholder) : `${t("Filter")} ${t(column.header)}`}
            filter={optionFilter}
            filterBy="label"
        />
    );
};
