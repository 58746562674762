import React, { useState } from "react";

import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames/dedupe";

import Icon from "components/icon";

export const OverflowMenu = ({ children, visibilityMap }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleToggleMenu = () => setIsMenuOpen((prev) => !prev);

    return (
        <div style={{ order: Object.entries(visibilityMap).length + 1 }}>
            <NavItem className="overflow-menu-nav-item">
                <NavLink className={classnames("gt-nav-pills", "rui-tabs-link", "overflow-menu-link")} onClick={handleToggleMenu}>
                    <Icon name={["fas", "ellipsis-vertical"]}></Icon>
                </NavLink>
                {isMenuOpen && (
                    <div className="overflow-menu-container" onClick={handleToggleMenu}>
                        <div className="overflow-menu">{children}</div>
                    </div>
                )}
            </NavItem>
        </div>
    );
};
