/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal Dependencies
 */
import Icon from '../icon';

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const PagePopupSearch = ( props ) => {
    const {
        settings,
    } = props;

    return (
        <div className="rui-search">
            <div className="rui-search-head">
                <form action="#">
                    <div className="input-group">
                        <input type="search" className="form-control form-control-clean order-12" placeholder="Type to search..." />
                        <div className="input-group-prepend mnl-3 order-1">
                            <button type="button" className="btn btn-clean btn-uniform btn-grey-5 mb-0 mnl-10">
                                <Icon name="search" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="rui-search-body">
                <div className="row vertical-gap">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h4>Projects</h4>
                        <Link to="#" className="media">
                            <span className="media-img">M</span>
                            <span className="media-body">
                                <span className="media-title">Aaa</span>
                                <small className="media-subtitle">Be life tree every behold fish</small>
                            </span>
                        </Link>
                        <Link to="#" className="media">
                            <span className="media-img">
                                <Icon name="heart" />
                            </span>
                            <span className="media-body">
                                <span className="media-title">Aaa</span>
                                <small className="media-subtitle">Fruit their us also rule one multiply</small>
                            </span>
                        </Link>
                        <Link to="#" className="media">
                            <span className="media-img">R</span>
                            <span className="media-body">
                                <span className="media-title">Aaa</span>
                                <small className="media-subtitle">Behold, to all own, one all fruitful let</small>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h4>Experiments</h4>
                        <Link to="#" className="media">
                            <span className="media-img">M</span>
                            <span className="media-body">
                                <span className="media-title">Aaa</span>
                                <small className="media-subtitle">Be life tree every behold fish</small>
                            </span>
                        </Link>
                        <Link to="#" className="media">
                            <span className="media-img">
                                <Icon name="heart" />
                            </span>
                            <span className="media-body">
                                <span className="media-title">Aaa</span>
                                <small className="media-subtitle">Fruit their us also rule one multiply</small>
                            </span>
                        </Link>
                        <Link to="#" className="media">
                            <span className="media-img">R</span>
                            <span className="media-body">
                                <span className="media-title">Aaa</span>
                                <small className="media-subtitle">Behold, to all own, one all fruitful let</small>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h4>Files</h4>
                        <Link to="#" className="media">
                            <span className="media-img bg-transparent"><img src={ settings.img_file.pdf } className="icon-file" alt="" /></span>
                            <span className="media-body">
                                <span className="media-title">COA-P00065.pdf</span>
                                <small className="media-subtitle">DJ adm</small>
                            </span>
                        </Link>
                        <Link to="#" className="media">
                            <span className="media-img bg-transparent"><img src={ settings.img_file.pdf } className="icon-file" alt="" /></span>
                            <span className="media-body">
                                <span className="media-title">COA-P00066.pdf</span>
                                <small className="media-subtitle">Pan Tester</small>
                            </span>
                        </Link>
                        <Link to="#" className="media">
                            <span className="media-img bg-transparent"><img src={ settings.img_file.png } className="icon-file" alt="" /></span>
                            <span className="media-body">
                                <span className="media-title">chem-reaction.png</span>
                                <small className="media-subtitle">Pan Tester</small>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PagePopupSearch;
