import { Modal, ModalBody } from "reactstrap";
import React from "react";

import * as GT from "components";
import { Accordion, AccordionTab } from "primereact/accordion";
import { T } from "@formulajs/formulajs";
import { useTranslation } from "react-i18next";

interface IVerifyPreviewModalProps {
    closeModal: () => void;
    verifications: IVerification[];
}

interface IVerification {
    Name: string;
    Intact: boolean;
    Valid: boolean;
    Revoked: boolean;
    Trusted: boolean;
    "Signer reported date": string;
    "Certificate not valid before": string;
    "Certificate not valid after": string;
    issuer: {
        "Country name": string;
        "State or province name": string;
        "Locality name": string;
        "Organization name": string;
        "Organizational unit name": string;
        "Common name": string;
        "Email address": string;
    };
    subject: {
        "Country name": string;
        "State or province name": string;
        "Locality name": string;
        "Organization name": string;
        "Organizational unit name": string;
        "Common name": string;
        "Email address": string;
    };
}

export const VerifyPreviewModal = ({ closeModal, verifications }: IVerifyPreviewModalProps) => {
    const { t } = useTranslation();

    const handleCloseModal = () => closeModal();

    return (
        <Modal isOpen={true} toggle={() => closeModal()} fade>
            <GT.ModalHeader handlecloseModal={handleCloseModal} name="Preview vile verifications" />
            <ModalBody className="file-validation-modal">
                <Accordion activeIndex={0}>
                    {verifications.map((verification) => {
                        const mainSectionData = {};

                        Object.keys(verification)
                            .filter((key) => !["issuer", "subject"].includes(key))
                            .forEach((key) => (mainSectionData[key] = verification[key]));

                        const issuerSectionData = verification.issuer;
                        const subjectSectionData = verification.subject;

                        return (
                            <AccordionTab header={verification.Name}>
                                <Accordion activeIndex={0}>
                                    <AccordionTab header={t("Main")}>
                                        <SectionData sectionData={mainSectionData} t={t} />
                                    </AccordionTab>
                                    <AccordionTab header={t("Issuer")}>
                                        <SectionData sectionData={issuerSectionData} t={t} />
                                    </AccordionTab>
                                    <AccordionTab header={t("Subject")}>
                                        <SectionData sectionData={subjectSectionData} t={t} />
                                    </AccordionTab>
                                </Accordion>
                            </AccordionTab>
                        );
                    })}
                </Accordion>
            </ModalBody>
            <GT.ModalFooter closeModal={handleCloseModal} cancelName="Close" />
        </Modal>
    );
};

const SectionData = ({ sectionData, t }) => {
    return (
        <>
            {Object.keys(sectionData).map((key) => (
                <>
                    {typeof sectionData[key] === "boolean" ? (
                        <GT.CustomInput
                            labelSm={2}
                            className="validation-modal-checkbox"
                            labelText={key}
                            readOnly
                            type="checkbox"
                            forLabel="key"
                            name="key"
                            checked={sectionData[key]}
                        />
                    ) : (
                        <p style={{ marginBottom: "5px", marginLeft: "20px" }}>
                            {t(key)}: {sectionData[key]}
                        </p>
                    )}
                </>
            ))}
        </>
    );
};
