import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, ModalBody } from "reactstrap";

import CustomModalHeader from "../../components/custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../components/custom-modal-elements/custom-modal-footer";
import CustomModalSelect from "../../components/custom-modal-elements/custom-modal-select";
import { IErrors } from "../../pages/interfaces/IErrors";
import addToast from "../../utils/addToast";
import { connect } from "react-redux";
import { updateAuth as actionUpdateAuth } from "../../actions";
import { updatePermissions as actionUpdatePermissions } from "../../actions";
import CustomForm from "../custom-form/custom-form";

const CustomNavbarRoleModal = (props) => {
    const [roleData, setRoleData] = useState([] as any);
    const [errors, setErrors] = useState({} as IErrors);
    const [selectedRole, setSelectedRole] = useState(null as any);

    useEffect(() => {
        if (props.modalOpen) {
            axios.get("roles/form").then((response) => {
                setRoleData(response.data.roles);
                const selected = response.data.roles.find((obj) => obj.label == props.roleName);
                setSelectedRole(selected);
                setErrors({});
            });
        }
    }, [props.modalOpen]);

    const handleSubmitData = async (event) => {
        event.preventDefault();

        if (selectedRole == undefined) {
            setErrors({ selectedRole: "This field may not be null" });
            return;
        }

        const sendState = { selectedRole: selectedRole.value };

        try {
            const res = await axios.post("change-role", sendState);

            props.updateAuth({ role: selectedRole.label });
            props.updatePermissions(res.data.permissions);
            props.closeModal();

            addToast({
                title: "Role has been changed",
            });
            location.reload();
        } catch (err) {
            if (err.response?.status == "400") setErrors(err.response.data);
        }
    };

    const handleChangeRole = (event) => {
        const selected = roleData.find((obj) => obj.value == event.target.value);
        setSelectedRole(selected);
    };

    return (
        <>
            <Modal isOpen={props.modalOpen} toggle={props.closeModal} fade>
                <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData}>
                    <CustomModalHeader handlecloseModal={props.closeModal} name="Change role" />
                    <ModalBody>
                        <CustomModalSelect
                            errors={errors.selectedRole}
                            labelName={"Role"}
                            options={roleData}
                            handleChange={handleChangeRole}
                            name="selectedRole"
                            value={selectedRole}
                        />
                    </ModalBody>
                    <CustomModalFooter closeModal={props.closeModal} cancelName="Cancel" okName="change" />
                </CustomForm>
            </Modal>
        </>
    );
};

export default connect(({ auth }) => ({ auth }), { updateAuth: actionUpdateAuth, updatePermissions: actionUpdatePermissions })(
    CustomNavbarRoleModal
);
