import React, { useEffect, useState, useMemo, useRef, SyntheticEvent } from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

import SuperscriptDisplay from "../../superscript/superscript-display";
import { sweetConfirm } from "../../sweet-alert/sweetConfirm";
import { getNestedObjValue } from "../helpers/primeHelpers";
import { ReqParamsHOC } from "../helpers/req-params-hoc";
// import { moveKeyPress } from "../helpers/moveKeyPress";
import { IEditSelectInputProps } from "../interfaces/edit-interfaces";

// table edit component, you send it in the column as editBody prop
// editBody: (inputData: IPrimeEditData<ProperInterface>) => (<EditSelectInput inputData={inputData} />),
export const EditSelectInput = (props: IEditSelectInputProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        CustomPlaceholder = undefined,
        superscript,
        value,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    const content = value ? value : getNestedObjValue(row, fieldName.split("."));

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-select-${fieldName}-${row.id}`}>
            {CustomPlaceholder ? <CustomPlaceholder /> : superscript ? <SuperscriptDisplay value={content} /> : content}
        </div>
    );
};

const EnabledInput = (props: IEditSelectInputProps) => {
    const { t } = useTranslation();

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        editUniqueUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        mapPatchResponse = undefined,
        //common
        placeholder = "Choose value...",
        nextRecordOnEnter = false,
        //unique
        sweetConfirmMessage = undefined,
        customMoreModal = undefined,
        clearable = true,
        optionFilter = false,
        superscript = false,
    } = props;

    const [error, setError] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const formRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<any>(null);
    // const keyPressedRef = useRef<string>("");1

    const { row, fieldName, handleEditSubmit, selectChoices, isBusy, permCode = "", rowIndex, handleFocusSelectRecord } = props.inputData;

    const _selectChoices = superscript
        ? useMemo(() => {
              return selectChoices?.map((o, i) => ({
                  label: (
                      <div key={i}>
                          <SuperscriptDisplay key={`opt-${i}`} value={o.label} />
                      </div>
                  ),
                  stringValue: o.label.replace(/[↑↓→←]/gi, ""),
                  value: o.value,
              }));
          }, [selectChoices])
        : selectChoices;

    const _value = value || row[fieldName] || "";
    const _name = name || fieldName;

    useEffect(() => {
        error && setError(false);
    }, [row]);

    const handleOnChange = async (e) => {
        if (sweetConfirmMessage && !(await sweetConfirm("Select", sweetConfirmMessage))) return;

        // rowValue is needed for checking if value before change is different then value on blur'e
        let _e = { ...e, target: { ...e.target, name: e.target.name, value: !e.target.value ? null : e.target.value } };

        if (!permCode) handleSubmit(e, undefined);

        inputRef.current = _e;

        formRef.current && formRef.current.click();
    };

    const handleMoreModalSubmit = async (value) => {
        const _e = { target: { name: _name, value: value } };
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;

        const response = await handleEditSubmit({
            row: row,
            e: _e,
            secondPartUrl: editUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            mapPatchResponse: mapPatchResponse,
            editUniqueUrl: editUniqueUrl,
        });

        if (response?.status?.toString()[0] == "2" || response?.response?.status == undefined) return;
        else setError(true);
    };

    const handleSubmit = async (e: SyntheticEvent, reqParams?: IReqParams) => {
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;
        const _e = { target: { name: _name, value: inputRef?.current?.value } };

        await handleEditSubmit({
            row: row,
            e: _e,
            secondPartUrl: editUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            mapPatchResponse: mapPatchResponse,
            editUniqueUrl: editUniqueUrl,
            reqParams: reqParams,
        });
    };

    return (
        <ReqParamsHOC onSubmit={handleSubmit} permCode={permCode} formRef={formRef} onClose={undefined}>
            <Dropdown
                disabled={isBusy}
                key={`prime-input-select-${fieldName}-${row.id}`}
                id={`prime-input-select-${fieldName}-${rowIndex}`}
                name={_name}
                options={_selectChoices}
                value={_value}
                optionLabel="label"
                optionValue="value"
                style={{ width: "100%", ...style }}
                onFocus={() => handleFocusSelectRecord(row)}
                showOnFocus
                onChange={(e) => handleOnChange(e)}
                placeholder={t(placeholder)}
                showClear={clearable && !!_value}
                filter={optionFilter}
                className={`prime-table-cell-edit ${className} ${error ? "error" : ""} ${
                    (!clearable || !_value) && !customMoreModal ? "widerSpan" : ""
                } ${customMoreModal ? "no-dropdown-icon" : ""}`}
                emptyMessage={t("No records found")}
            />
            <div style={{ position: "relative", width: "0px", overflow: "visible" }}>
                {customMoreModal && (
                    <>
                        <span onClick={() => setModalOpen(true)} className="pi pi-ellipsis-h prime-edit-more-icon" />
                        {modalOpen &&
                            customMoreModal(
                                () => setModalOpen(false),
                                (value) => handleMoreModalSubmit(value),
                                _value
                            )}
                    </>
                )}
            </div>
        </ReqParamsHOC>
    );
};
