import React from 'react';
import { Spinner } from "reactstrap";

const CustomSpinner = (props) => {
    const { color, type } = props;
    
    return (
        <>
            <Spinner color={ color || "secondary" } type={ type || "grow" }>
                Loading...
            </Spinner>
        </>
    );
};

export default CustomSpinner;

