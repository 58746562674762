export const getStatusDisplay = (s: string) => {
    s = s.replaceAll(" ", "-").toLowerCase();
    return PRETTY_CELL_CONFIG[s]?.alias || s.charAt(0).toUpperCase() + s.slice(1);
};

interface IPrettyCellConfig {
    [key: string]: {
        icon: [string, string] | string;
        alias: string;
        color: string;
    };
}

// Keep `prettyCellColorsMap` and `PRETTY_CELL_CONFIG` in sync with weblab_backend/settings/helpers/icons.py

export const prettyCellColorsMap: { [key: string]: string } = {
    colorPositive: "#b1f7bf", // green
    colorNegative: "#f78483", // red
    colorWarning: "#ffd875", // yellow
    colorInfo: "#e6e6e6", //  ??
    colorSpecial: "#d5c6e4", // pink
    colorPending: "#a3f0ff", // blue
    defaultColor: "grey", // grey
};

export const PRETTY_CELL_CONFIG: IPrettyCellConfig = {
    // audit-specific cells, aliasing to readable tag
    "user+": { icon: ["fas", "sign-in-alt"], alias: "auditApp>>Log In", color: prettyCellColorsMap.colorPositive },
    "user-": { icon: ["fas", "sign-out-alt"], alias: "auditApp>>Log Out", color: prettyCellColorsMap.colorNegative },

    "+": { icon: ["fas", "plus"], alias: "auditApp>>Add", color: prettyCellColorsMap.colorPositive },
    "-": { icon: ["fas", "minus"], alias: "auditApp>>Delete", color: prettyCellColorsMap.colorNegative },
    "~": { icon: ["fas", "exchange-alt"], alias: "auditApp>>Change", color: prettyCellColorsMap.colorWarning },

    // statuses
    draft: { icon: ["fas", "folder-open"], alias: "Draft", color: prettyCellColorsMap.colorInfo },
    new: { icon: ["fas", "folder-open"], alias: "New", color: prettyCellColorsMap.colorInfo },
    planned: { icon: ["fas", "calendar-alt"], alias: "Planned", color: prettyCellColorsMap.colorPending },
    active: { icon: ["fas", "angle-double-up"], alias: "Active", color: prettyCellColorsMap.colorPositive },
    "in-usage": { icon: ["fas", "angle-double-up"], alias: "In usage", color: prettyCellColorsMap.colorPositive }, //czy nie lepiej niebieski?
    done: { icon: ["fas", "check"], alias: "Done", color: prettyCellColorsMap.colorPositive },
    warn_done: { icon: ["fas", "check"], alias: "Warn Done", color: prettyCellColorsMap.colorWarning },
    warn_oos: { icon: ["fas", "ban"], alias: "Warn OOS", color: prettyCellColorsMap.colorWarning },
    oos: { icon: ["fas", "ban"], alias: "OOS", color: prettyCellColorsMap.colorNegative },
    cancelled: { icon: "x", alias: "Cancelled", color: prettyCellColorsMap.colorNegative },
    quarantine: { icon: ["fas", "virus"], alias: "Quarantine", color: prettyCellColorsMap.colorWarning },
    utilized: { icon: ["fas", "recycle"], alias: "Utilized", color: prettyCellColorsMap.colorPositive },
    released: { icon: ["fas", "check-double"], alias: "Released", color: prettyCellColorsMap.colorPositive },
    received: { icon: ["fas", "hand-holding"], alias: "Received", color: prettyCellColorsMap.colorPositive },
    retired: { icon: ["fas", "ban"], alias: "Retired", color: prettyCellColorsMap.colorInfo },
    "not-active": { icon: "x", alias: "Not active", color: prettyCellColorsMap.colorNegative }, //czy nie lepiej szary?
    archived: { icon: ["fas", "file-archive"], alias: "Archived", color: prettyCellColorsMap.colorInfo },
    "in-lab": { icon: ["fas", "hand-holding"], alias: "In Lab", color: prettyCellColorsMap.colorPending },
    sampled: { icon: ["fas", "vials"], alias: "Sampled", color: prettyCellColorsMap.colorPending },
    prepared: { icon: ["fas", "clipboard-check"], alias: "Prepared", color: prettyCellColorsMap.colorPending },
    conducted: { icon: ["fas", "check"], alias: "Conducted", color: prettyCellColorsMap.colorPositive },
    canceled: { icon: ["fas", "ban"], alias: "Canceled", color: prettyCellColorsMap.colorWarning }, //czerwony?
    started: { icon: ["fas", "folder-open"], alias: "Started", color: prettyCellColorsMap.colorInfo }, // niebieski?
    added: { icon: ["fas", "folder-open"], alias: "Added", color: prettyCellColorsMap.colorInfo },
    deleted: { icon: ["fas", "trash"], alias: "Deleted", color: prettyCellColorsMap.colorNegative },
    "in-use": { icon: ["fas", "folder-open"], alias: "In use", color: prettyCellColorsMap.colorPending },
    logged: { icon: ["fas", "check"], alias: "Logged", color: prettyCellColorsMap.colorPositive },
    "in-progress": { icon: ["fas", "folder"], alias: "In progress", color: prettyCellColorsMap.colorPending },
    internal: { icon: ["fas", "folder"], alias: "Internal", color: prettyCellColorsMap.colorPending }, //nie wiem co znaczy
    external: { icon: ["fas", "folder"], alias: "External", color: prettyCellColorsMap.colorPending }, //nie wiem co znaczy
    "in-service": { icon: ["fas", "tools"], alias: "In service", color: prettyCellColorsMap.colorPending },
    timetable: { icon: ["fas", "calendar-alt"], alias: "", color: prettyCellColorsMap.defaultColor },
    autostart: { icon: ["fas", "play-circle"], alias: "", color: prettyCellColorsMap.defaultColor },
    created: { icon: ["fas", "folder-open"], alias: "Created", color: prettyCellColorsMap.colorInfo },
    "pre-draft": { icon: ["fas", "user-clock"], alias: "Pre draft", color: prettyCellColorsMap.colorInfo },
    awaits_approval: { icon: ["fas", "pause"], alias: "Awaits approval", color: prettyCellColorsMap.colorPending },
    in_creation: { icon: ["fas", "wrench"], alias: "In creation", color: prettyCellColorsMap.defaultColor },
    approved: { icon: ["fas", "check-double"], alias: "_statuses>>Approved", color: prettyCellColorsMap.colorPositive },

    //specifications
    production: { icon: ["fas", "industry"], alias: "Production", color: prettyCellColorsMap.colorWarning }, // tu też nie wiem jaki kontekst
    diagnostic: { icon: ["fas", "clinic-medical"], alias: "Diagnostic", color: prettyCellColorsMap.colorPending }, // tu bym dała zielony mozę ale nie wiem
    nondiagnostic: { icon: ["fas", "clinic-medical"], alias: "Nondiagnostic", color: prettyCellColorsMap.colorNegative }, //czy nie lepiej żółty?
    inconclusive: { icon: ["fas", "question"], alias: "Inconclusive", color: prettyCellColorsMap.colorPending }, //żółty raczej

    //sample workflow statuses
    receive: { icon: ["fas", "chalkboard-teacher"], alias: "Receive", color: prettyCellColorsMap.defaultColor },
    register: { icon: ["fas", "clipboard"], alias: "Register", color: prettyCellColorsMap.defaultColor },
    prepare: { icon: ["fas", "vial"], alias: "Prepare", color: prettyCellColorsMap.defaultColor },
    "receive-in-lab": { icon: ["fas", "flask"], alias: "Receive in lab", color: prettyCellColorsMap.defaultColor },
    results: { icon: ["fas", "poll"], alias: "Results", color: prettyCellColorsMap.defaultColor },
    sampling: { icon: ["fas", "vials"], alias: "Sampling", color: prettyCellColorsMap.defaultColor },

    // demands statuses
    sent_for_estimation: { icon: ["fas", "share"], alias: "Sent for estimation", color: prettyCellColorsMap.colorPending },
    estimated: { icon: ["fas", "money-bill-wave"], alias: "Estimated", color: prettyCellColorsMap.colorPositive },
    accepted_by_manager: { icon: ["fas", "check"], alias: "Accepted by manager", color: prettyCellColorsMap.colorPositive },
    sent_for_dt_acceptation: { icon: ["fas", "share-square"], alias: "Sent for DT acceptation", color: prettyCellColorsMap.colorPending },
    accepted: { icon: ["fas", "check-double"], alias: "Accepted", color: prettyCellColorsMap.colorPositive },
    rejected: { icon: ["fas", "times"], alias: "Rejected", color: prettyCellColorsMap.colorNegative },
    correction: { icon: ["fas", "redo-alt"], alias: "Correction", color: prettyCellColorsMap.colorWarning },
    realized: { icon: ["fas", "circle-check"], alias: "Realized", color: prettyCellColorsMap.colorPositive },
    partially_realized: { icon: ["fas", "circle-half-stroke"], alias: "Partially realized", color: prettyCellColorsMap.colorPositive },
    "took-part-in": { icon: ["fas", "user-check"], alias: "Took part in", color: prettyCellColorsMap.colorPending },
    certified: { icon: ["fas", "user-graduate"], alias: "Certified", color: prettyCellColorsMap.colorPositive },

    //result_sheet
    finished: { icon: ["fas", "flag-checkered"], alias: "Finished", color: prettyCellColorsMap.colorPositive },
    activated: { icon: ["fas", "check"], alias: "Activated", color: prettyCellColorsMap.colorWarning }, //tu w sumie nie wiem jaki jest kontekst, może fioletowy?
    opened: { icon: ["fas", "door-open"], alias: "Opened", color: prettyCellColorsMap.colorPending }, //szary?

    //test reports
    pending: { icon: ["fas", "clock"], alias: "Pending", color: prettyCellColorsMap.colorInfo }, // tu totalnie powinno być niebieskie
    signed: { icon: ["fas", "pen-to-square"], alias: "Signed", color: prettyCellColorsMap.colorPending }, //a tu szary
    sent: { icon: ["fas", "paper-plane"], alias: "Sent", color: prettyCellColorsMap.colorPositive },
};
