
export function updateBreadcrumb( bradcrumbs = {} ){
    let $breadcrumbData = window.jQuery('#breadcrumb-data')
    if ($breadcrumbData.length == 0) return

    const updateFunction = $breadcrumbData.data('update')
    updateFunction(bradcrumbs)
}

export function setCurrentBreadcrumb(name, breadCrumb = null) { 
    if(!breadCrumb){
        const b = document.querySelector('.breadcrumb-item:last-child')
        if(b) b.innerHTML = name
    }
}
