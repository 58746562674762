import React from "react";

import { getNestedObjValue } from "../helpers/primeHelpers";
import { Tooltip } from "primereact/tooltip";

interface IRowGroupHeaderProps {
    row: any;
    groupField?: any;
    t: Function;
    frozenColumn: boolean;
}

export const MemoRowGroupHeader = React.memo(({ row, groupField, t, frozenColumn }: IRowGroupHeaderProps) => {
    const value = getNestedObjValue(row, groupField.field.split("."));
    const content =
        typeof value != "boolean" ? `${groupField.header}: ${t(value || "No value")}` : value ? t("Selected") : t("Not selected");

    // if there is frozen column then span with class content has display: none,
    // on unfrozen part of the table (id not for this, tooltip would appear when not wanted)
    // but because of this RowGroupHeader doesn't have right height,
    // thats why i used span with class content which spreads RowGroupHeader
    return (
        <>
            {frozenColumn ? (
                <>
                    <span className="prime-group-header placeholder no-select">.</span>
                    <span className="prime-group-header content" id={`tooltip-uniqe-key-${row.id}`}>
                        {content}
                    </span>
                    <Tooltip position="bottom" target={`#tooltip-uniqe-key-${row.id}`}>
                        <div>{content} </div>
                    </Tooltip>
                </>
            ) : (
                <span className="prime-group-header">{content}</span>
            )}
        </>
    );
});
