import React, { ReactNode } from "react";

import { DefaultCell } from "../components/default-cell";
import { I, Filters, EditBody } from "../index";

export const mapToAdditionalColumns = (extraFields: I.ExtraFields | undefined, generateAdditionalColumns): I.IPrimeTableColumn[] => {
    if (!extraFields || !generateAdditionalColumns) return [];

    return Object.keys(extraFields).map((key): I.IPrimeTableColumn => {
        const _key: keyof I.ExtraField = key as keyof I.ExtraField;
        const column: I.ExtraField = extraFields[_key];

        return { header: column?.name, field: _key, sortable: true, noHeaderTranslation: true, additionalType: column?.type };
    });
};

export const additionalColumnEditBody = (inputData: I.IPrimeEditData<any>, type: I.ExtraFieldTypes): ReactNode => {
    switch (type) {
        case "str":
            return <div style={{ height: "100%" }}> {EditBody.text(inputData, { shouldDataSetRefresh: false })}</div>;
        case "date":
            return <div style={{ height: "100%" }}> {EditBody.date(inputData, { shouldDataSetRefresh: false })}</div>;
        case "int":
            return <div style={{ height: "100%" }}> {EditBody.text(inputData, { type: "integer", shouldDataSetRefresh: false })}</div>;
        case "float":
            return <div style={{ height: "100%" }}> {EditBody.text(inputData, { type: "number", shouldDataSetRefresh: false })}</div>;
        default:
            return <DefaultCell>{inputData.row?.[inputData.fieldName]}</DefaultCell>;
    }
};

export const additionalColumnFilterBody = (filterData: I.IPrimeFilterData, type: I.ExtraFieldTypes): ReactNode => {
    switch (type) {
        case "str":
            return Filters.text(filterData);
        case "date":
            return Filters.date(filterData);
        case "int":
            return Filters.number(filterData);
        case "float":
            return Filters.number(filterData);
        default:
            return undefined;
    }
};

export const additionalColumnFilterField = (field: string): string => {
    return field.replace("extraField", "extra_field_");
};
