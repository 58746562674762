import store from "../reducers/configureStore";
import { addToast as addToastAction } from "../actions";
import i18n from '../i18n';

interface IToast {
    title?: string | any,
    content?: string | any,
    time?: Date,
    duration?: number,
    color?: string,
}

const addToast = ( obj:IToast ) => {

    const title     = obj.title?.text   || obj.title   || ''
    let content   = obj.content?.text || obj.content || ''

    const maxDuration = 10000 // 10000ms = 10s
    const minDuration = 4000  // 4000ms  = 4s

    const words    = `${title} ${content}`.trim().split(' ')
    const duration = words.length * 500
    const clampDur = Math.min( maxDuration , Math.max( minDuration , duration ))

    let charLimitMatch = content.match(/^Ensure this field has no more than (\d+) characters.$/)
    
    if (charLimitMatch){
            content = i18n.t("Ensure this field has no more than {{count}} characters", {count: Number(charLimitMatch[1])});
    }
    charLimitMatch = content.match(/^Ensure that there are no more than (\d+) digits in total.$/);
    if (charLimitMatch){
            content = i18n.t("Ensure that there are no more than {{count}} digits in total", {count: Number(charLimitMatch[1])});
    }
    charLimitMatch = content.match(/^Ensure that there are no more than (\d+) digits before the decimal point.$/);
    if (charLimitMatch){
            content = i18n.t("Ensure that there are no more than {{count}} digits before the decimal point", {count: Number(charLimitMatch[1])});
    }
    charLimitMatch = content.match(/^Ensure that there are no more than (\d+) decimal places.$/);
    if (charLimitMatch){
            content = i18n.t("Ensure that there are no more than {{count}} decimal places", {count: Number(charLimitMatch[1])});
    }
    store.store.dispatch( 
        addToastAction({
            title:     i18n.t((title), { count: obj.title?.count }),
            content:   i18n.t((content), { count: obj.content?.count }),
            time:      obj.time     || (new Date().getTime() - 1000),
            duration:  obj.duration || clampDur,
            color:     obj.color    || 'brand'
        }) 
    )
}

export default addToast