import { XCircle } from "react-feather";
import React from "react";

import "./styles.scss";

export const EmptyMessage = ({ t }) => {
    return (
        <div className="empty-message">
            <XCircle />
            <p>{t("No records found")}</p>
        </div>
    );
};
