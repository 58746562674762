export const mapStatusesStyles = (statuses) => ({
    statuses: statuses.reduce((acc, current) => {
        acc[current.name] = {
            color: current.color,
            icon: [current.type, current.icon],
            alias: current.alias,
        };
        return acc;
    }, {}),
});
