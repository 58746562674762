import { Col, Form, Label, Modal, ModalBody, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Axios from "axios";

import { parseSuperscriptToString } from "../../../superscript/superscript-display";
import CustomModalHeader from "../../../custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../../custom-modal-elements/custom-modal-footer";
import CustomModalSelect from "../../../custom-modal-elements/custom-modal-select";
import PrimeInputGroup from "../../../prime-input-group/prime-input-group";
import { sweetConfirm } from "../../../sweet-alert/sweetConfirm";
import CustomButton from "../../../custom-button/custom-button";
import { IErrors } from "../../../../pages/interfaces/IErrors";
import { useFormData } from "../../../../hooks/useFormData";
import CustomForm from "../../../custom-form/custom-form";
import addToast from "../../../../utils/addToast";
import { IMeta } from "../../interfaces/table-interfaces";

interface IProps {
    selectedRecord?: any;
    closeModal: () => void;
    meta: IMeta;
}

interface ITranslateData {
    defaultLanguage?: {
        label: string;
        value: string;
    };
    model: string;
    languages: {
        label: string;
        value: string;
    }[];
    translatableFields: string[];
    translations?: any;
}

interface IFormData {
    ids?: any;
    values?: any;
}

const labelStyle = {
    fontWeight: 700,
    fontSize: "1.2rem",
};

export const PrimeTranslateModal = ({ selectedRecord, closeModal, meta }: IProps) => {
    const { t } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [formData, setFormData] = useState<IFormData>({ ids: {}, values: {} });
    const [errors, setErrors] = useState<IErrors>({});

    const { formData: languagesData } = useFormData<ITranslateData>({
        url: `bs/translations-form-data/${meta.ModelInfo?.app}/${meta.ModelInfo?.model}`,
    });

    const handleSubmitData = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const payload = {
            inputData: Object.keys(formData?.values).map((key) => ({
                language: selectedLanguage,
                text: formData.values[key],
                field: key,
            })),
        };

        try {
            await Axios.put(`/bs/translations/${meta.ModelInfo?.app}/${meta.ModelInfo?.model}/${selectedRecord?.id}`, payload);
            addToast({ title: "Translations saved successfully" });
            closeModal();
        } catch (err: any) {
            if (err.response?.status == "400") setErrors(err.response.data);
            addToast({ title: "Something went wrong while saving translations", color: "danger" });
        }
    };

    const handleSelectChange = async (e) => {
        const { value: language } = e.target;
        try {
            const response = await Axios.get(
                `/bs/translations-form-data/${meta.ModelInfo?.app}/${meta.ModelInfo?.model}/${selectedRecord?.id}/${language}`
            );

            const tempFormData = { ids: {}, values: {} };

            response.data.translations.map((translation) => {
                tempFormData.values[translation.field] = translation.text;
                tempFormData.ids[`${translation.field}Id`] = translation.id;
            });

            setFormData({ ...tempFormData });
        } catch (err: any) {
            if (err.response?.status == "400") setErrors(err.response.data);
            addToast({ title: "Something went wrong while changing language", color: "danger" });
        }
        setSelectedLanguage(language);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, values: { ...prevState.values, [name]: value } }));
    };

    const handleDeleteTranslation = async (field) => {
        if (!formData?.ids?.[`${field}Id`]) return;
        if (!(await sweetConfirm("Delete translation", "Do you want to delete?"))) return;

        try {
            await Axios.delete(`/bs/translations/${formData?.ids?.[`${field}Id`]}`);

            const tempFormData = JSON.parse(JSON.stringify(formData));
            delete tempFormData.values[field];
            delete tempFormData.ids[`${field}Id`];

            setFormData(tempFormData);
        } catch (err: any) {
            if (err.response?.status == "400") setErrors(err.response.data);
            addToast({ title: "Something went wrong while deleting translation", color: "danger" });
        }
    };

    return (
        <Modal isOpen={true} toggle={closeModal} fade>
            <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData}>
                <CustomModalHeader handlecloseModal={closeModal} name="Translations" />
                <ModalBody>
                    <Row className="d-flex justify-content-center">
                        <Col sm={6}>
                            <Label style={{ fontSize: "1.2rem" }}>{t(`${languagesData?.defaultLanguage?.label || ""}`)}</Label>
                        </Col>
                        <Col sm={6}>
                            <CustomModalSelect
                                hideLabel
                                labelName="Language"
                                options={languagesData?.languages}
                                handleChange={handleSelectChange}
                                isClearable={false}
                                errors={errors?.language}
                            />
                        </Col>
                    </Row>
                    {languagesData?.translatableFields.map(
                        (field) =>
                            selectedRecord?.[field] && (
                                <div key={`translation-${field}`}>
                                    <Label style={labelStyle}>{t(field[0].toUpperCase() + field.slice(1))}</Label>
                                    <PrimeInputGroup
                                        labelText={parseSuperscriptToString(selectedRecord?.[field])}
                                        name={field}
                                        type={field != "description" ? "text" : "textarea"}
                                        labelStyle={{
                                            wordBreak: "break-all",
                                            alignItems: "flex-start",
                                        }}
                                        value={formData?.values?.[field] ? formData?.values?.[field] : ""}
                                        handleChange={handleInputChange}
                                        placeholder="Translation..."
                                        labelSm={6}
                                        colSm={6}
                                        disableLabelTranslation
                                        rightButton={
                                            <CustomButton
                                                icon={{ name: "trash" }}
                                                onClick={() => handleDeleteTranslation(field)}
                                                className={`textarea-inputgroup ${field}`}
                                            />
                                        }
                                    />
                                </div>
                            )
                    )}
                </ModalBody>
                <CustomModalFooter closeModal={closeModal} cancelName="Cancel" okName="Save" />
            </CustomForm>
        </Modal>
    );
};
