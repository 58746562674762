import React, { CSSProperties } from "react";

import LabelError from "../label-error/label-error";
import hasPermission from "../../utils/hasPermissions";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import addToast from "../../utils/addToast";

import "./custom-modal-checkbox.scss";

interface ICustomModalInput {
    handleChange?: any;
    forLabel?: string | undefined;
    labelText?: string;
    name?: any;
    className?: string;
    noLabel?: boolean;
    disabled?: boolean;
    checked?: boolean;
    errors?: any;
    id?: string | number;
    style?: CSSProperties;
    labelFirst?: boolean;
    permCode?: string;
}

export const CustomModalCheckbox = (props: ICustomModalInput) => {
    const { t } = useTranslation();

    const {
        handleChange,
        forLabel = "",
        labelText,
        name,
        className = "",
        noLabel = false,
        disabled = false,
        checked = false,
        errors,
        style,
        labelFirst = false,
        permCode,
    } = props;

    const onChange = (e) => {
        if (permCode && !hasPermission(permCode)) return addToast({ title: "No permissions", color: "danger" });
        handleChange(e);
    };

    return (
        <div className="custom-modal-checkbox-container">
            {labelFirst && !noLabel && (
                <label className="checkbox-label" htmlFor="checkbox-input">
                    {t(labelText || "")}:
                </label>
            )}
            <Checkbox
                name={name}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
                id="checkbox-input"
                className={`custom-modal-checkbox ${className}`}
                style={style}
            />
            {errors && <LabelError id={forLabel} error={t(errors)} />}
            {!labelFirst && !noLabel && (
                <label className="checkbox-label" htmlFor="checkbox-input">
                    {t(labelText || "")}
                </label>
            )}
        </div>
    );
};
