import React from "react";

import Icon from "../icon";

import "./error-page-fallback.style.scss";
import { useTranslation } from "react-i18next";

export const ErrorPageFallback = () => {
    const { t } = useTranslation();

    return (
        <div className="error-page-fallback">
            <Icon name={["far", "face-frown"]}></Icon>
            <h1>{t("OOOPS! Something went wrong here...")}</h1>
            <p>{t("Sorry we're having some technical issue. Try to refresh the page, sometime it works.")}</p>
        </div>
    );
};
