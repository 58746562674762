import React from "react";
import { withTranslation } from "react-i18next";

const DurationFieldDisplay = (props) => {
    const getText = (props) => {
        if (props.text) {
            const split = props.text?.split(" ");
            if (split.length == 1) {
                const time = split[0]?.split(":");
                const splitTime = getTime(time);
                return (
                    (splitTime[0] ? props.t(`{{count}} hours`, { count: splitTime[0] }) : "") +
                    " " +
                    (splitTime[1] ? props.t(`{{count}} minutes`, { count: splitTime[1] }) : "") +
                    " " +
                    (splitTime[2] ? props.t(`{{count}} seconds`, { count: splitTime[2] }) : "")
                );
            } else {
                const time = split[3]?.split(":");
                const splitTime = getTime(time);
                return (
                    (parseInt(split[0]) ? props.t(`{{count}} years`, { count: parseInt(split[0]) }) : "") +
                    " " +
                    (parseInt(split[1]) ? props.t(`{{count}} months`, { count: parseInt(split[1]) }) : "") +
                    " " +
                    (parseInt(split[2])
                        ? props.t(`{{count}}${props.isWorkingDays ? " working" : ""} days`, { count: parseInt(split[2]) })
                        : "") +
                    " " +
                    (splitTime[0] ? props.t(`{{count}} hours`, { count: splitTime[0] }) : "") +
                    " " +
                    (splitTime[1] ? props.t(`{{count}} minutes`, { count: splitTime[1] }) : "") +
                    " " +
                    (splitTime[2] ? props.t(`{{count}} seconds`, { count: splitTime[2] }) : "")
                );
            }
        } else {
            return "";
        }
    };

    const getTime = (time) => {
        const hours = parseInt(time[0]);
        const minutes = parseInt(time[1]);
        const seconds = parseInt(time[2]);
        return [hours, minutes, seconds];
    };

    return <div>{getText(props)}</div>;
};
export default withTranslation()(DurationFieldDisplay);
