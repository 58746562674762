import axios from "axios";

export const handleTimeFormat = (resp) => {
    let isDate = false;
    let isTimeDate = false;
    let fieldName = "";

    let response = resp;

    resp.data.columns.map((x) => {
        if (x.output_field_type == "DateTimeField") {
            isTimeDate = true;
            fieldName = x.connection;
        }
        if (x.output_field_type == "DateField") {
            isDate = true;
            fieldName = x.connection;
        }
    });

    if (isDate) {
        response.data.qbe_results.data = resp.data.qbe_results.data.map((x) => {
            if (x[fieldName] == null) return x;

            let date = new Date(x[fieldName]);
            let dateMonth: any = date.getMonth() + 1;
            let dateDay: any = date.getDate();

            if (dateMonth < 10) {
                dateMonth = `0${dateMonth}`;
            }
            if (dateDay < 10) {
                dateDay = `0${dateDay}`;
            }

            x[fieldName] = `${date.getFullYear()}-${dateMonth}-${dateDay}`;
            return x;
        });
    } else if (isTimeDate) {
        response.data.qbe_results.data = resp.data.qbe_results.data.map((x) => {
            let date: Date = new Date(x[fieldName]);
            let dateMonth: string | number = date.getMonth() + 1;
            let dateDay: string | number = date.getDate();
            let dateHours: string | number = date.getHours();
            let dateMinutes: string | number = date.getMinutes();

            if (dateMonth < 10) {
                dateMonth = `0${dateMonth}`;
            }
            if (dateDay < 10) {
                dateDay = `0${dateDay}`;
            }
            if (dateHours < 10) {
                dateHours = `0${dateHours}`;
            }
            if (dateMinutes < 10) {
                dateMinutes = `0${dateMinutes}`;
            }

            x[fieldName] = `${date.getFullYear()}-${dateMonth}-${dateDay} ${dateHours}:${dateMinutes}`;
            return x;
        });
    }

    return response;
};

export const getQbeResultsResponse = (selectedOpts, qbe, params = "") => {
    const queries = groupBy(selectedOpts, "queryId");
    const sendData = Object.values(queries).map((e: any) => {
        return {
            id: e[0].id,
            condition: e[1].value.split(" |")[0],
            condition_value: e[2].value.split(" |")[0] == "dt--Today" ? "now()" : e[2].value.split(" |")[0],
            separator: e[3]?.value?.split(" |")?.[0],
        };
    });

    try {
        return axios.post(`query-by-example/run-overide-filters/${qbe.value}${params}`, sendData);
    } catch (err) {
        console.error(err);
    }
};

const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
};
