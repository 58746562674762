import Axios, { CancelToken, CancelTokenSource } from "axios";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { IBaseRecord, IMeta } from "components/prime-data-table/interfaces";

interface DataInterface<T> {
    data?: T[];
    meta?: IMeta | null;
}

export type IData<T> = DataInterface<T> | undefined;
interface IUseGetData<T, K> {
    url: string | undefined;
    triggerValues?: any[];
    mapData?: (data: K[], response: any) => any;
    afterFetch?: (data: T[]) => any;
    initParams?: string;
    shouldFetch?: any;
    additionalParams?: string;
    configParams?: any;
    handleUnmount?: Function;
    handleError?: Function;
    groupedOnInit?: boolean;
    getLocation?: boolean;
}

interface ICancelToken {
    [key: number]: CancelTokenSource;
}

let cancelToken: ICancelToken = {};
export function useGetData<T extends IBaseRecord = any, K = T>({
// export function useGetData<T = any, K = T>({
    url,
    triggerValues = [],
    mapData,
    afterFetch,
    initParams = "?pageSize=10&page=1",
    shouldFetch = undefined,
    additionalParams = "",
    handleUnmount = undefined,
    handleError = undefined,
    configParams = undefined,
    groupedOnInit = false,
    getLocation = false,
}: IUseGetData<T, K>) {
    const [data, setData] = useState<IData<T>>(undefined);
    const [urlParams, setParams] = useState<string>(initParams);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [random, setRandom] = useState<number>(0);

    const refreshData: Function = () => setRandom(uuid());

    const [cancelTokenName] = useState<number>(uuid());

    const { state } = useLocation<{ instanceId: number }>();

    useEffect(() => {
        if (!(shouldFetch != false)) return;
        const source = Axios.CancelToken.source();

        if (groupedOnInit) {
            if (data) getData(source.token);
            else {
                const pageSize = parseInt(initParams.getBetween("?pageSize=", "&page=") || "10");
                setData({ data: [], meta: { pageSize: pageSize, page: 1, count: 0, lastPage: 0, ExtraFields: {} } });
            }
        } else getData(source.token);
        return () => source.cancel();
    }, [urlParams, random]);

    useEffect(() => {
        const checkParams = async () => {
            let _params: string = data?.meta ? `?pageSize=${data?.meta.pageSize}&page=1` : initParams;
            const groupingStr: string = "&grouping=";

            if (urlParams.includes(groupingStr)) {
                const orderingStr: string = "&ordering=";

                const start = urlParams.indexOf(groupingStr);
                const orderingStrIndex: number = urlParams.indexOf(orderingStr);
                const end = orderingStrIndex + orderingStr.length + urlParams.slice(start + groupingStr.length, orderingStrIndex).length;

                _params = _params.concat(urlParams.slice(start, end));
            }

            setParams((prevParams) => {
                if (prevParams == _params) getData();
                return _params;
            });
        };

        (shouldFetch || (shouldFetch != false && data)) && checkParams();
    }, [...triggerValues, shouldFetch]);

    useEffect(() => {
        return () => {
            handleUnmount && handleUnmount();
        };
    }, []);

    initParams == "?pageSize=5&page=1" && console.log(triggerValues);

    const getData = async (token: CancelToken | undefined = undefined) => {
        let request = { ...configParams };
        setIsLoading(true);

        if (cancelTokenName && cancelToken[cancelTokenName] != undefined) {
            cancelToken[cancelTokenName].cancel("Operation canceled due to new request.");
        }

        if (!url) {
            setData({ data: [], meta: undefined });
            afterFetch && afterFetch([]);
            return;
        }

        if (cancelTokenName) {
            cancelToken[cancelTokenName] = Axios.CancelToken.source();
            request = { ...request, cancelToken: cancelToken[cancelTokenName]?.token };
        }

        let locationParams = "";
        if (getLocation) {
            window.history.replaceState({}, document.title);
            locationParams = state?.instanceId ? `&search=${state?.instanceId}&search_field=id` : "";
        }

        try {
            const response = await Axios.get(url + urlParams + additionalParams + locationParams, { ...request, cancelToken: token });

            const data = response.data.data ? response.data.data : response.data;
            const dataToSet = mapData ? mapData(data, response) : data;

            setData({ data: dataToSet, meta: response.data.meta });
            afterFetch && afterFetch(dataToSet);
        } catch (err) {
            handleError && handleError(err);
        } finally {
            setIsLoading(false);
            if (cancelTokenName) delete cancelToken[cancelTokenName];
        }
    };

    const handleReload = (params?: string, refresh?: boolean) => {
        if (refresh) getData();
        else if (params) setParams(params);
    };

    return { data, setData, refreshData, handleReload, isLoading };
} //# ///////////// TABLE DATA INTERFACE /////////////
