import React, { CSSProperties, FocusEventHandler, KeyboardEventHandler, MouseEventHandler, useState } from "react";

import { FormGroup, Col, Tooltip, Label } from "reactstrap";
import LabelError from "../label-error/label-error";
import Input, { InputType } from "reactstrap/lib/Input";
import { InputText } from "primereact/inputtext";
import { withTranslation } from "react-i18next";
import { ButtonProps } from "primereact/button";

import "./prime-input-group.scss";

interface IPrimeInputGroup {
    t: Function;
    handleChange?: any;
    value?: any;
    forLabel?: string | undefined;
    labelText?: string;
    type?: InputType;
    name?: any;
    labelSm?: any;
    colSm?: any;
    placeholder?: string;
    className?: string;
    autoComplete?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    noLabel?: boolean;
    tooltipText?: any;
    handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    readOnly?: any;
    onMouseDown?: Function;
    defaultValue?: any;
    disabled?: boolean;
    min?: number | string;
    max?: number | string;
    style?: CSSProperties;
    errors?: any;
    firstInput?: boolean;
    id?: string | number;
    propRef?: any;
    labelStyle?: CSSProperties;
    leftButton?: ButtonProps;
    rightButton?: ButtonProps;
    disableLabelTranslation?: boolean;
    required?: boolean;
}

const PrimeInputGroup = (props: IPrimeInputGroup) => {
    const {
        t,
        handleChange,
        forLabel = "",
        labelText,
        type = "text",
        name,
        labelSm = 3,
        colSm,
        placeholder,
        value,
        className,
        autoComplete = "off",
        onBlur = undefined,
        onFocus = undefined,
        noLabel = false,
        tooltipText,
        handleKeyDown = undefined,
        readOnly = undefined,
        onMouseDown = undefined,
        defaultValue = undefined,
        disabled = false,
        min = undefined,
        max = undefined,
        style,
        firstInput = false,
        labelStyle,
        leftButton,
        rightButton,
        disableLabelTranslation,
        required,
    } = props;

    let { errors, propRef } = props;

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const translateErrorString = (errorStrings: Array<string>) => {
        let result = [] as any;
        if (typeof errorStrings == "string") {
            return t(errorStrings);
        }

        const regexList = [
            /^Ensure this field has no more than (\d+) characters.$/,
            /^Ensure that there are no more than (\d+) digits in total.$/,
            /^Ensure that there are no more than (\d+) digits before the decimal point.$/,
            /^Ensure that there are no more than (\d+) decimal places.$/,
            /Range is overlaping with (.*) and (.*)/,
        ];
        const translationList = [
            "Ensure this field has no more than {{count}} characters",
            "Ensure that there are no more than {{count}} digits in total",
            "Ensure that there are no more than {{count}} digits before the decimal point",
            "Ensure that there are no more than {{count}} decimal places",
            "Range is overlaping with {{substring}} and {{substring_2}}",
        ];

        let errorMatch;
        for (let errorString of errorStrings) {
            for (let i = 0; i < regexList.length; i++) {
                errorMatch = errorString.match(regexList[i]);
                if (errorMatch) {
                    result.push(
                        t(translationList[i], { count: Number(errorMatch[1]), substring: errorMatch[1], substring_2: errorMatch?.[2] })
                    );
                    break;
                }
            }

            if (!errorMatch) result.push(t(errorString));
        }
        return result.join(". ");
    };

    if (value && errors && errors[0] == "This field is required." && errors.length == 1) {
        errors = undefined;
    }

    return (
        <FormGroup row className={className}>
            {firstInput || noLabel ? null : (
                <Label for={t(forLabel)} sm={labelSm || 3} style={labelStyle}>
                    {required && "* "}
                    {!disableLabelTranslation ? t(labelText) : labelText}:
                </Label>
            )}
            <Col sm={colSm}>
                {type == "text" ? (
                    <div className="p-inputgroup">
                        {leftButton}
                        <InputText
                            autoComplete={autoComplete}
                            name={name}
                            onChange={handleChange}
                            className={"form-control " + (errors ? "is-invalid" : "")}
                            id={forLabel}
                            placeholder={t(placeholder) || t(labelText) + "..."}
                            value={value}
                            step="any"
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={handleKeyDown}
                            readOnly={readOnly}
                            onMouseDown={onMouseDown || readOnly ? (e) => e.preventDefault() : undefined}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            min={min}
                            max={max}
                            style={style}
                            ref={propRef}
                        />
                        {rightButton}
                    </div>
                ) : (
                    <div className="p-inputgroup">
                        {leftButton}
                        <Input
                            autoComplete={autoComplete}
                            name={name}
                            onChange={handleChange}
                            className={errors ? "is-invalid" : ""}
                            type={type}
                            id={forLabel}
                            placeholder={t(placeholder) || t(labelText) + "..."}
                            value={value}
                            step="any"
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={handleKeyDown}
                            readOnly={readOnly}
                            onMouseDown={onMouseDown || readOnly ? (e) => e.preventDefault() : undefined}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            min={min}
                            max={max}
                            style={style || { height: "100px" }}
                            ref={propRef}
                        />
                        {rightButton}
                    </div>
                )}
                {errors && <LabelError id={forLabel} error={translateErrorString(errors)} />}
                {tooltipText && forLabel && !errors && (
                    <Tooltip target={forLabel} isOpen={tooltipOpen} toggle={() => setTooltipOpen((prev) => !prev)}>
                        {tooltipText}{" "}
                    </Tooltip>
                )}
            </Col>
            {!firstInput || noLabel ? null : (
                <Label for={t(forLabel)} sm={labelSm || 3} style={labelStyle}>
                    {t(labelText)}
                </Label>
            )}
        </FormGroup>
    );
};

export default withTranslation()(PrimeInputGroup);
