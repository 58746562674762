// app settings
const settings = {
    // signCert0: require("../common-assets/js/szafir/szafirsdk-module"),
    // signCert1: require("../common-assets/js/szafir/SZAFIR"),
    // signCert3: require("../common-assets/js/szafir/content"),
    // signCert4: require("../common-assets/js/szafir/popup"),
    // signCert2: require("../common-assets/js/szafir/background"),
    // signCert5: require("../common-assets/js/szafir/jquery-2.1.4.min.js"),
    languages: [
        {
            name: "Polski",
            value: "pl",
            icon: require("../common-assets/images/flags/polish.svg"),
        },
        {
            name: "English",
            value: "en",
            icon: require("../common-assets/images/flags/united-kingdom.svg"),
        },
        {
            name: "Deutsch",
            value: "de",
            icon: require("../common-assets/images/flags/deutschland.svg"),
        },
        {
            name: "České",
            value: "cz",
            icon: require("../common-assets/images/flags/czech-republic.svg"),
        },
        {
            name: "Français",
            value: "fr",
            icon: require("../common-assets/images/flags/french-flag.svg"),
        },
    ],
    night_mode: false,
    spotlight_mode: false,
    show_section_lines: true,
    sidebar_dark: false,
    sidebar_static: false,
    sidebar_small: false,
    sidebar_effect: "shrink",
    nav: true,
    nav_dark: false,
    nav_logo_path: require("../common-assets/images/weblab-light.png"),
    nav_logo_white_path: require("../common-assets/images/weblab-dark.png"),
    nav_logo_width: "130px",
    nav_logo_url: "/",
    nav_align: "left",
    nav_expand: "lg",
    nav_sticky: true,
    nav_autohide: true,
    nav_container_fluid: true,
    home_url: "/",
    navigation: {},
    navigation_profile_panel: {
        "/profile": {
            name: "Profile",
            permcode: "view.account-profile.main.view",
        },
    },
    navigation_sidebar: {
        "/": {
            label: "Base",
            name: "Dashboard",
            icon: ["fas", "home"],
        },

        "/accessSettings": {
            name: "Access settings",
            icon: ["fas", "layer-group"],
            permCode: "view.access-settings.main.view",
        },

        "/transaction_settings": {
            name: "Transaction settings",
            icon: ["fas", "layer-group"],
            permCode: "view.transaction-settings.main.view",
        },

        "/bs": {
            name: "Basic settings",
            icon: ["fas", "layer-group"],
            permCode: "view.basic-settings.main.view",
            sub: {
                "/bs/tests": {
                    icon: ["fas", "vial"],
                    name: "Tests",
                    permCode: "view.basic-settings::tests.main.view",
                },
                "/bs/locations": {
                    icon: ["fas", "folder-open"],
                    name: "Locations",
                    permCode: "view.basic-settings::locations.main.view",
                },
                "/bs/laboratories": {
                    icon: ["fas", "vials"],
                    name: "Laboratories",
                    permCode: "view.basic-settings::laboratories.main.view",
                },
                "/bs/units": {
                    icon: ["fas", "ruler"],
                    name: "UOM",
                    permCode: "view.basic-settings::units.main.view",
                },
                "/bs/material-types": {
                    icon: ["fas", "cubes"],
                    name: "Material types",
                    permCode: "view.basic-settings::material-types.main.view",
                },
                "/bs/materials": {
                    icon: ["fas", "briefcase"],
                    name: "Materials",
                    permCode: "view.basic-settings::materials.main.view",
                },
                "/bs/pictogram": {
                    icon: ["fas", "key"],
                    name: "Pictogram",
                    permCode: "view.basic-settings::pictogram.main.view",
                },
                "/bs/eqTypes": {
                    icon: ["fas", "microscope"],
                    name: "Equipment types",
                    permCode: "view.basic-settings::equipment-types.main.view",
                },
                "/bs/maintTypes": {
                    icon: ["fas", "tools"],
                    name: "Maintenance types",
                    permCode: "view.basic-settings::maintenance-types.main.view",
                },
                "/bs/specifications": {
                    icon: ["fas", "list-alt"],
                    name: "Specifications",
                    permCode: "view.basic-settings::specifications.main.view",
                },
                "/bs/testPlans": {
                    icon: ["fas", "calendar-alt"],
                    name: "Test plans",
                    permCode: "view.basic-settings::test-plans.main.view",
                },
                "/bs/methods": {
                    icon: ["fas", "sitemap"],
                    name: "Methods",
                    permCode: "view.basic-settings::methods.main.view",
                },
                // "/bs/suppliers": {
                //     icon: ["fas", "dolly-flatbed"],
                //     name: "Suppliers",
                //     permCode: "view.basic-settings::suppliers.main.view",
                // },
                "/bs/systemEmail": {
                    icon: ["fas", "mail-bulk"],
                    name: "System email",
                    permCode: "view.basic-settings::system-email.main.view",
                },
                "/bs/audit": {
                    icon: ["fas", "book"],
                    name: "Audit settings",
                    permCode: "view.basic-settings::audit.main.view",
                },
                "/bs/lookups": {
                    icon: ["fas", "clipboard-list"],
                    name: "Lookups",
                    permCode: "view.basic-settings::lookups.main.view",
                },
                "/bs/personel": {
                    icon: ["fas", "user-md"],
                    name: "Personel",
                    permCode: "view.basic-settings::personel.main.view",
                },
                "/bs/users-management": {
                    icon: ["fas", "user-cog"],
                    name: "Users management",
                    permCode: "view.basic-settings::users-management.main.view",
                },
                "/bs/integrations": {
                    icon: ["fas", "cogs"],
                    name: "Integrations",
                    permCode: "view.basic-settings::integrations.main.view",
                },
                "/bs/report_template": {
                    icon: ["far", "list-alt"],
                    name: "Report templates",
                },
                "/bs/metadata": {
                    icon: ["fas", "table"],
                    name: "Metadata",
                    permCode: "view.basic-settings::metadata.template.main.view",
                },
                "/bs/result-qualifier": {
                    icon: ["fas", "poll-h"],
                    name: "Result qualifier",
                    permCode: "view.basic-settings::result-qualifier.main.view",
                },
                "/bs/cost-group": {
                    icon: ["fas", "poll-h"],
                    name: "Cost groups",
                    permCode: "view.basic-settings::cost-group.main.view",
                },
                "/bs/workflow": {
                    icon: ["fas", "bezier-curve"],
                    name: "Workflow",
                    permCode: "view.basic-settings::workflow.main.view",
                },
                "/bs/cost-limits": {
                    icon: ["fas", "money-bill"],
                    name: "Cost limits",
                    permCode: "view.basic-settings::cost-limits.main.view",
                },
                "/bs/materials-tree": {
                    icon: ["fas", "sitemap"],
                    name: "Materials tree",
                    permCode: "view.materials-tree.main.view",
                },
                "/bs/calendar": {
                    icon: ["fas", "calendar-days"],
                    name: "Calendar",
                    permCode: "view.basic-settings::calendar.main.view",
                },
                "/bs/sample-workflow": {
                    icon: ["fas", "diagram-project"],
                    name: "Sample workflow",
                    permCode: "view.basic-settings::sample-workflow.main.view",
                },
                "/bs/eln-templates": {
                    icon: ["far", "pen-to-square"],
                    name: "Eln templates",
                    permCode: "view.basic-settings::eln-templates.main.view",
                },
                "/bs/process-parameters": {
                    icon: ["fas", "sliders"],
                    name: "Process parameters",
                    permCode: "view.basic-settings::process-parameters.main.view",
                },
                "/bs/stability-templates": {
                    icon: ["far", "list-alt"],
                    name: "Stability protocols",
                    permCode: "view.basic-settings::stability-templates.main.view",
                },
                "/bs/sampling-sites": {
                    icon: ["fas", "folder-open"],
                    name: "Sampling sites",
                    permCode: "view.basic-settings::sampling-sites.main.view",
                },
                "/bs/bulk-packaging": {
                    icon: ["fas", "box"],
                    name: "Packages",
                    permCode: "view.basic-settings::bulk-packaging.main.view",
                },
                "/bs/meta-app-types": {
                    icon: ["fas", "cubes"],
                    name: "Meta app types",
                    permCode: "view.basic-settings::meta-app-types.main.view",
                },
            },
        },
        "/administration": {
            name: "Administration",
            icon: ["fas", "user-tie"],
            permCode: "view.administration.main.view",
            sub: {
                "/administration/password-policy": {
                    icon: ["fas", "key"],
                    name: "Password policy",
                    permCode: "view.administration::password-policy.main.view",
                },
                "/administration/statuses-config": {
                    icon: ["fas", "gears"],
                    name: "Statuses config",
                    permCode: "view.administration::statuses-config.main.view",
                },
                "/administration/code-mask": {
                    icon: ["fas", "tag"],
                    name: "Code mask",
                    permCode: "view.administration::code-mask.main.view",
                },
                "/administration/company-settings": {
                    icon: ["fas", "tag"],
                    name: "Company settings",
                    permCode: "view.administration::company-settings.main.view",
                },
                "/administration/printers": {
                    icon: ["fas", "print"],
                    name: "Printers",
                    permCode: "view.administration::printers.main.view",
                },
            },
        },

        // Components Base
        "/my": {
            name: "My",
            icon: ["fas", "layer-group"],
            permcode: "view.filtered-for-me.main.view",
            sub: {
                "/my/projects": {
                    icon: ["fas", "book"],
                    name: "Projects",
                },
                // "/my/experiments": {
                //     name: "My experiments",
                // },
                // "/my/samples": {
                //     name: "My samples",
                // },
                // "/my/tasks": {
                //     name: "My tasks",
                // },
                // "/my/equipment": {
                //     name: "My equipment",
                // },
            },
        },

        // CSV
        "/csv": {
            name: "CSV",
            icon: ["fas", "file-csv"],
            permcode: "view.csv.main.view",
            sub: {
                "/csv/templates": {
                    icon: ["fas", "tools"],
                    name: "Template",
                    permCode: "view.csv::template.main.view",
                },
                "/csv/import": {
                    icon: ["fas", "upload"],
                    name: "Import",
                    permCode: "view.csv::import.main.view",
                },
                "/csv/json-import": {
                    icon: ["fas", "upload"],
                    name: "JSON Import",
                    permCode: "view.csv::json-import.main.view",
                },
            },
        },

        // Apps
        label: {
            label: "Workplace",
        },
        "/projects": {
            name: "Projects",
            icon: ["fas", "book"],
            permCode: "view.projects.main.view",
        },
        // "/experiments": {
        //     name: "Experiments",
        //     icon: ["fas", "flask"],
        //     permCode: "view.experiments.main.view",
        // },
        "/samples": {
            name: "Samples",
            icon: ["fas", "dna"],
            permCode: "view.samples.main.view",
        },
        "/orders": {
            name: "Orders",
            icon: ["fas", "box-open"],
            permCode: "view.orders.main.view",
            sub: {
                "/orders/register": {
                    name: "Register",
                    icon: ["fas", "clipboard"],
                    permCode: "view.orders::register.main.view",
                },
                "/orders/samples-logistics": {
                    name: "Samples Logistics",
                    icon: ["fas", "box"],
                    permCode: "view.orders::samples-logistics.main.view",
                },
                "/orders/sampling": {
                    name: "Sampling",
                    icon: ["fas", "vials"],
                    permCode: "view.orders::sampling.main.view",
                },
                "/orders/receive": {
                    name: "Receive",
                    icon: ["fas", "chalkboard-teacher"],
                    permCode: "view.orders::receive.main.view",
                },
                "/orders/prepare": {
                    name: "Preparing",
                    icon: ["fas", "vial"],
                    permCode: "view.orders::prepare.main.view",
                },
                "/orders/receive-in-lab": {
                    name: "Receive in lab",
                    icon: ["fas", "flask"],
                    permCode: "view.orders::receive-in-lab.main.view",
                },
                "/orders/results": {
                    name: "Results",
                    icon: ["fas", "poll"],
                    permCode: "view.orders::results.main.view",
                },
                // "/orders/cross-results": {
                //     name: "Cross results",
                //     icon: ["fas", "poll-h"],
                //     permCode: "view.orders::cross-results.main.view",
                // },
                "/orders/release-results": {
                    name: "Release results by section",
                    icon: ["fas", "tasks"],
                    permCode: "view.orders::release-results.main.view",
                },
                "/orders/release-samples": {
                    name: "Release sample",
                    icon: ["fas", "check-circle"],
                    permCode: "view.orders::release-samples.main.view",
                },
                "/orders/release-orders": {
                    name: "Release order",
                    icon: ["fas", "clipboard-check"],
                    permCode: "view.orders::release-orders.main.view",
                },
                "/orders/test-reports": {
                    name: "Test reports",
                    icon: ["fas", "file-lines"],
                    permCode: "view.orders::test-reports.main.view",
                },
                "/orders/edit": {
                    name: "Edit order",
                    icon: ["fas", "edit"],
                    permCode: "view.orders::edit.main.view",
                },
                "/orders/preview": {
                    name: "Preview order",
                    icon: ["fas", "paste"],
                    permCode: "view.orders::preview.main.view",
                },
                "/orders/manage-eln": {
                    name: "Manage result sheets",
                    icon: ["fas", "book"],
                    permCode: "view.orders::result-sheet.manage.main.view",
                },
                "/orders/my-eln": {
                    name: "My result sheets",
                    icon: ["fas", "book-open"],
                    permCode: "view.orders::result-sheet.my.main.view",
                },
                "/orders/authorize-eln": {
                    name: "Authorize result sheets",
                    icon: ["fas", "clipboard-check"],
                    permCode: "view.orders::result-sheet.authorize.main.view",
                },
                "/orders/archive-eln": {
                    name: "Archive result sheets",
                    icon: ["fas", "clipboard-list"],
                    permCode: "view.orders::result-sheet.archive.main.view",
                },
                "/orders/sampling-list": {
                    name: "Sampling list",
                    icon: ["fas", "rectangle-list"],
                    permCode: "view.orders::sampling-list.main.view",
                },
                "/orders/shipment-of-samples": {
                    name: "Shipment of samples",
                    icon: ["fas", "truck"],
                    permCode: "view.orders::shipment-of-samples.main.view",
                },
                "/orders/formulations": {
                    name: "Formulations",
                    icon: ["fas", "prescription-bottle"],
                    permCode: "view.orders::formulations.main.view",
                },
            },
        },
        "/stability-tests": {
            name: "Stability tests",
            icon: ["fas", "box-open"],
            permCode: "view.stability-tests.main.view",
            sub: {
                "/stability-tests/stability-protocol": {
                    name: "Orders",
                    icon: ["fas", "clipboard"],
                    permCode: "view.stability-tests::stability-orders.main.view",
                },
                "/stability-tests/awaiting-samples": {
                    name: "Awaiting samples",
                    icon: ["fas", "clipboard"],
                    permCode: "view.stability-tests::awaiting-samples.main.view",
                },
            },
        },
        // "/patients": {
        //     name: "Patients",
        //     icon: ["fas", "user"],
        //     permCode: "view.patients.main.view",
        // },
        "/crm": {
            name: "CRM",
            icon: ["fas", "users"],
            permCode: "view.crm.main.view",
            sub: {
                "/crm/clients": {
                    name: "Client list",
                    icon: ["fas", "user-tie"],
                    permCode: "view.crm::client-list.main.view",
                },
                "/crm/price-list": {
                    name: "Price list",
                    icon: ["fas", "money-check-alt"],
                    permCode: "view.crm::price-list.main.view",
                },
                "/crm/offers-contracts": {
                    name: "Offers Contracts",
                    icon: ["fas", "file-alt"],
                    permCode: "view.crm::offers-contracts.main.view",
                },
                "/crm/sampling-plans": {
                    name: "Sampling plans",
                    icon: ["fas", "file-alt"],
                    permCode: "view.crm::sampling-plans.main.view",
                },
            },
        },
        "/tasks": {
            name: "Tasks",
            icon: ["fas", "flag"],
            permCode: "view.tasks.main.view",
        },
        "/equipments": {
            name: "Equipments",
            icon: ["fas", "microscope"],
            permCode: "view.equipments.main.view",
        },
        "/cdr": {
            name: "CDR",
            icon: ["fas", "folder"],
            permCode: "view.cdr.main.view",
            sub: {
                "/cdr/document-types": {
                    name: "Document types",
                    icon: ["fas", "paste"],
                    permCode: "view.cdr::document-types.main.view",
                },
                "/cdr/document-workflows": {
                    icon: ["fas", "bezier-curve"],
                    name: "Document workflows",
                    permCode: "view.cdr::document-workflows.main.view",
                },
                "/cdr/document-repository": {
                    name: "Document repository",
                    icon: ["fas", "copy"],
                    permCode: "view.cdr::document-repository.main.view",
                },
                "/cdr/released-documents": {
                    name: "Released documents",
                    icon: ["fas", "copy"],
                    permCode: "view.cdr::released-documents.main.view",
                },
            },
        },
        "/inventory": {
            name: "Inventory",
            icon: ["fas", "warehouse"],
            permCode: "view.inventory.main.view",
        },
        // "/inventory-sample": {
        //     name: "Inventory samples",
        //     icon: ["fas", "warehouse"],
        //     permCode: "view.inventory-samples.main.view",
        // },
        "/reports": {
            name: "Reports",
            icon: ["far", "list-alt"],
            permCode: "view.reports.main.view",
            sub: {
                "/reports/advanced-queries": {
                    name: "Advanced queries",
                    icon: ["fas", "magnifying-glass"],
                    permCode: "view.reports::advanced-queries.main.view",
                },
                "/reports/query-template": {
                    name: "Query template",
                    icon: ["fas", "magnifying-glass"],
                    permCode: "view.reports::query-template.main.view",
                },
            },
        },
        "/control-cards": {
            name: "Control cards",
            icon: ["fas", "chart-line"],
            permCode: "view.control-cards.main.view",
        },
        "/product-trends": {
            name: "Product trends",
            icon: ["fas", "chart-line"],
            permCode: "view.product-trends.main.view",
        },
        "/planned-trainings": {
            name: "Trainings",
            icon: ["fas", "graduation-cap"],
            permCode: "view.trainings.main.view",
        },
        "/subcontractors": {
            name: "Subcontractors",
            icon: ["fas", "users"],
            permCode: "view.subcontractors.main.view",
        },
        "/demands": {
            name: "Demands",
            icon: ["fas", "shopping-cart"],
            permCode: "view.demands.main.view",
            sub: {
                "/demands/suppliers": {
                    name: "Suppliers",
                    icon: ["fas", "truck"],
                    permCode: "view.demands::suppliers.main.view",
                },
                "/demands/demands-list": {
                    name: "Demands list",
                    icon: ["fas", "list-ul"],
                    permCode: "view.demands::demands-list.main.view",
                },
                "/demands/orders": {
                    name: "Demand orders",
                    icon: ["fas", "cart-plus"],
                    permCode: "view.demands::orders.main.view",
                },
            },
        },
        // "/help": {
        //     name: "Help",
        //     icon: ["fas", "question-circle"],
        //     permCode: "view.help.main.view",
        // },
        "/integrated-system": {
            name: "Integrated system",
            icon: ["fas", "graduation-cap"],
            permCode: "view.integrated-system.main.view", //TODO change
            sub: {
                "/integrated-system/types": {
                    name: "Types",
                    icon: ["fas", "graduation-cap"],
                    permCode: "view.integrated-system::types.main.view", // change
                },
                "/integrated-system/instances": {
                    name: "Instances",
                    icon: ["fas", "graduation-cap"],
                    permCode: "view.integrated-system::instances.main.view", // change
                },
                "/integrated-system/mapping": {
                    name: "Mapping",
                    icon: ["fas", "sitemap"],
                    permCode: "view.integrated-system::mapping.main.view", // change
                },
            },
        },
        // "/nonconformance-card": {
        //     name: "Nonconformance card",
        //     icon: ["fas", "clipboard-check"],
        //     permCode: "view.nonconformance-card.main.view",
        // },
        // "/method-validations": {
        //     name: "Method validations",
        //     icon: ["fas", "square-check"],
        //     permCode: "view.method-validations.main.view",
        // },
        // "/customer-satisfaction-survey": {
        //     name: "Customer satisfaction survey",
        //     icon: ["fas", "square-poll-horizontal"],
        //     permCode: "view.customer-satisfaction-survey.main.view",
        // },
        // "/questionnaire": {
        //     name: "Questionnaire",
        //     icon: ["fas", "square-poll-horizontal"],
        //     permCode: "view.questionnaire.group.main.view",
        // },
        // "/error-logger": {
        //     name: "Error logger",
        //     icon: ["fas", "bug"],
        //     permCode: "view.error-logger.main.view",
        // },
    },
    breadcrumbs_presets: {
        apps: {},
    },
    img_country: {
        usa: require("../common-assets/images/flags/united-states-of-america.svg"),
        polish: require("../common-assets/images/flags/polish.svg"),
        uk: require("../common-assets/images/flags/united-kingdom.svg"),
    },
    img_file: {
        empty: require("../common-assets/images/file-icons/icon-empty.svg"),
        zip: require("../common-assets/images/file-icons/icon-zip.svg"),
        rar: require("../common-assets/images/file-icons/icon-rar.svg"),
        html: require("../common-assets/images/file-icons/icon-html.svg"),
        php: require("../common-assets/images/file-icons/icon-php.svg"),
        css: require("../common-assets/images/file-icons/icon-css.svg"),
        js: require("../common-assets/images/file-icons/icon-js.svg"),
        doc: require("../common-assets/images/file-icons/icon-doc.svg"),
        txt: require("../common-assets/images/file-icons/icon-txt.svg"),
        pdf: require("../common-assets/images/file-icons/icon-pdf.svg"),
        xls: require("../common-assets/images/file-icons/icon-xls.svg"),
        png: require("../common-assets/images/file-icons/icon-png.svg"),
        jpg: require("../common-assets/images/file-icons/icon-jpg.svg"),
    },
    globalPermCodes: ["other"],
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value): any => {
    $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
    const isTrue = $_GET[name] === "1";

    switch (name) {
        case "setting-night-mode":
            settings.night_mode = isTrue;
            break;
        case "setting-show-section-lines":
            settings.show_section_lines = isTrue;
            break;
        case "setting-sidebar-small":
            settings.sidebar_small = isTrue;
            break;
        case "setting-sidebar-dark":
            settings.sidebar_dark = isTrue;
            break;
        case "setting-nav-dark":
            settings.nav_dark = isTrue;
            break;
        // no default
    }
});

export default settings;
