// internal
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import axios from 'axios'

// internal
import { IMeta } from "../custom-data-table/interface";
import CustomDataTable from "../custom-data-table/custom-data-table";
import CustomModalHeader from "../custom-modal-elements/custom-modal-header"
import CustomModalFooter from "../custom-modal-elements/custom-modal-footer"
import SuperscriptDisplay from '../superscript/superscript-display'; 
import addToast from '../../utils/addToast';
import Icon from '../icon'

// style
import './style.scss'
import PrettyCell from 'components/prime-data-table/body-components/pretty-cell/pretty-cell';

class AuditWindow extends React.Component<any, any> {
    
    TYPE_COLORS = {
        '+': '#2FC787',
        '-': 'red',
        '~': 'orange'
    }
    
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isOpen: false,
            dataSet: [],
            columns: [],
            meta: {} as IMeta,
            appLabel: props.auditData?.appLabel,
            model: props.auditData?.model,
        }
    }

    getAuditUrl = (params : string | null = null) => {
        const { appLabel, model } = this.state
        let path : string

        if (params){
            path = `audit${params}&appLabel=${appLabel}&model=${model}`
        } else {
            path = `audit?appLabel=${appLabel}&model=${model}`
        }

        const ids = this.props.instanceIds.toString()
        if (ids) path += `&instanceId=${ids}`

        return path
    }


    openModal = async () =>  {

        this.setState({ isLoading: true })
        try {
            const path = this.getAuditUrl('?pageSize=15')
            const response = await axios.get(path, { params: { hideToast: true } })
            const { data, meta } = response.data
            
            if (!data[0]) {
                addToast({ title: 'No audit events found', content: 'or audit for this table is disabled', color: 'danger'})
                this.setState({ isLoading: false })
                return
            }

            if(window.location.pathname.includes('users-management')){
                data.forEach( o => o.historyType = `user${o.historyType}`)
            }

            const newColumns = [] as any []
            const auditSelectors = Object.keys(data[0]).filter( sel => !sel.startsWith('history') )

            for (let selector of auditSelectors){

                const foundColumn = this.props.tableColumns.find( c => c.selector == selector )

                if (foundColumn){
                    newColumns.push({
                        ...foundColumn,
                        cell: (row) => {
                            const color     = this.TYPE_COLORS[row.historyType]
                            const isChanged = row.__auditChangedValue?.includes(selector)
                            const isAdded   = row.historyType.endsWith('+')
                            const isDeleted = row.historyType.endsWith('-')

                            const style = { 
                                color: isAdded ? color : (isDeleted ? color : (isChanged ? color : ''))
                            }

                            return <SuperscriptDisplay style={style} value={ `${row[selector] || ''}` }/>
                        }
                    })
                }
            }

            const dataSet = this.highlightChanges(data, auditSelectors)
            
            this.setState({
                columns: [ ...auditColumns, ...newColumns ], 
                isLoading: false,
                isOpen: true,
                dataSet,
                meta
            })

        } catch (err){
            addToast({ title: 'No audit events found', content: 'or audit for this table is disabled', color: 'danger'})
            this.setState({ isLoading: false })
            console.log(err)
        }
    }

    highlightChanges = (data, auditSelectors) => {
        let lastRowData = { __auditChangedValue: [] } as any

        for (let row of data) {
            if (!row.__auditChangedValue) row.__auditChangedValue = []
            
            for (let selector of auditSelectors){
                if (row.id != lastRowData.id) continue
                if (row[selector] != lastRowData[selector]) lastRowData.__auditChangedValue.push(selector)
            }
            lastRowData = row
        }

        return data
    }

    getAuditEntries = async (params = window.location.search) => {
        
        try {
            const path = this.getAuditUrl(params)
            const response = await axios.get(path)

            if (!response.data.data[0]) {
                addToast({ title: 'No audit events found', content: 'or audit for this table is disabled', color: 'danger'})
                return
            }

            const auditSelectors = Object.keys(response.data.data[0]).filter( sel => !sel.startsWith('history') )
            const dataSet = this.highlightChanges(response.data.data, auditSelectors)
            
            this.setState({ dataSet, meta: response.data.meta })
        } catch (err){
            console.log(err)
        }
    }

    closeModal = () =>  this.setState({isOpen: false})

    render(){
        if (!this.state.appLabel || !this.state.model) return <></>;
        
        return (
            <>
                { this.state.isLoading ? (
                    <span className="pointer ml-10 mr-10">
                        <Spinner style={{ width: '16px', height: '16px' }} color="#bcbec0"/>
                    </span>
                ) : (
                    <span title={this.props.t("Audit")} className="btn btn-custom-round mr-10 mt-5" onMouseDown={ () => this.openModal() } >
                        <Icon name={['fas',"book"]} />
                    </span>
                ) }
                
                <Modal
                    className="audit-window"
                    isOpen={ this.state.isOpen }
                    fade
                >
                        <CustomModalHeader handlecloseModal={ this.closeModal }  name="Audit" />

                        <ModalBody style={{overflowY: 'auto'}}>
                        
                            <CustomDataTable
                                notSelectFirst
                                handleReload={this.getAuditEntries}
                                dataSet={this.state.dataSet}
                                meta={this.state.meta}
                                columns={this.state.columns}
                                keyField='historyId'
                                notAuditable
                            />

                        </ModalBody>

                        <CustomModalFooter closeModal={ this.closeModal } cancelName="Close" />
                    
                </Modal>
            </>
        )
    }
}

export default withTranslation()(AuditWindow)

const auditColumns = [
    { 
        grow: 0.5,
        name: "historyId",
        selector: "historyId",
        sortable: true,
    },
    { 
        grow: 1.1,
        name: "historyUser", 
        selector: "historyUser.fullName", 
        sortable: true,
    },
    { 
        grow: 1.5,
        name: "historyDate", 
        selector: "historyDate", 
        sortable: true,
    },
    { 
        name: "historyType", 
        selector: "historyType", 
        sortable: true, 
        cell: row => <PrettyCell text={row.historyType} />
    }
]