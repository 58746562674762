import React, { createContext, useContext, useRef, useState } from "react";

import { orderModes, OrderModesTypes } from "../../utils/modes";
import { ISample } from "../../../Samples/interface";

export const OrderResultsContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.results;
export const OrderResultsContextProvider = ({ children }) => {
    const refreshSampleResult = useRef<Function | null>(null);
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
    const [refreshSampleData, setRefreshSampleData] = useState<Function | undefined>(undefined);

    return (
        <OrderResultsContext.Provider
            value={{
                selectedSamples,
                setSelectedSamples,
                refreshSampleData,
                setRefreshSampleData,
                mode,
                refreshSampleResult,
            }}
        >
            {children}
        </OrderResultsContext.Provider>
    );
};

export const useOrderResults = (): IUseOrderResults => {
    const { selectedSamples, setSelectedSamples, refreshSampleData, setRefreshSampleData, mode, refreshSampleResult } =
        useContext(OrderResultsContext);

    return { selectedSamples, setSelectedSamples, refreshSampleData, setRefreshSampleData, mode, refreshSampleResult };
};

interface IUseOrderResults {
    selectedSamples: ISample[];
    setSelectedSamples: Function;
    mode: OrderModesTypes;
    refreshSampleResult: React.MutableRefObject<Function | null>;
    refreshSampleData: Function;
    setRefreshSampleData: Function;
}
