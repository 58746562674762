/**
 * External Dependencies
 */
import React, { Component } from "react";
import classnames from "classnames/dedupe";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPageFallback } from "../error-page-fallback/error-page-fallback";

/**
 * Component
 */
class PageWrap extends Component {
    render() {
        const { className, children } = this.props;

        return (
            <div className={classnames("rui-page rui-page-react content-wrap route-transition", className)}>
                <ErrorBoundary FallbackComponent={ErrorPageFallback}>
                    <div>{children}</div>
                </ErrorBoundary>
            </div>
        );
    }
}

export default PageWrap;
