import React, { useEffect, useState } from "react";

import { Modal, ModalBody } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

import CustomModalHeader from "../../../custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../../custom-modal-elements/custom-modal-footer";
import { FrozenColumnType, IGeneratedPrimeTableColumn } from "../../interfaces/table-interfaces";
import CustomForm from "../../../custom-form/custom-form";

interface IProps {
    frozenColumn: FrozenColumnType;
    visibleColumns: IGeneratedPrimeTableColumn[];
    closeModal: () => void;
    handleFreezeColumn: (arg1?: IGeneratedPrimeTableColumn, arg2?: number) => void;
}

export const PrimeFreezeColumnModal = ({ frozenColumn, visibleColumns, closeModal, handleFreezeColumn }: IProps) => {
    const { t } = useTranslation();
    const [selectFrozenColumn, setSelectFrozenColumn] = useState<string | undefined>(undefined);

    useEffect(() => {
        frozenColumn?.field != selectFrozenColumn && setSelectFrozenColumn(frozenColumn?.field);
    }, []);

    const handleSubmitData = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        visibleColumns.some((col, index) => col.field == selectFrozenColumn && handleFreezeColumn(col, index));
        closeModal();
    };

    return (
        <Modal isOpen={true} toggle={closeModal} fade>
            <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData}>
                <CustomModalHeader handlecloseModal={closeModal} name="Pin the column" />
                <ModalBody>
                    <Dropdown
                        value={selectFrozenColumn}
                        options={visibleColumns}
                        onChange={(e) => setSelectFrozenColumn(e.target.value)}
                        optionValue="field"
                        optionLabel="header"
                        className="custom-search-table-input"
                        placeholder={`${t("Select column")}`}
                        filter
                        showClear
                    />
                </ModalBody>
                <CustomModalFooter closeModal={closeModal} cancelName="Cancel" okName="Ok" />
            </CustomForm>
        </Modal>
    );
};
