// external
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import classnames from "classnames/dedupe";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Badge } from "reactstrap";
import Cookies from "js-cookie";
import i18n from "../../i18n";
import axios from "axios";

// internal
import { updateAuth, updatePermissions, updateStyles, updateSystemSettings, updateMetaAppTypes } from "../../actions";
import { initPluginYaybar } from "../../../common-assets/js/rootui-parts/initPluginYaybar";
import { metaAppsUrls } from "components/MetaApps/utils/meta-apps-urls";
import { mapStatusesStyles } from "../../utils/mapStatusesStyles";
import { TooltipHOC } from "../gt-tooltip/gt-tooltip-hoc";
import hasPermission from "../../utils/hasPermissions";
import Icon from "../icon";

import "../../../common-assets/js/yaybar/yaybar";
import "./style.scss";

window.RootUI.initPluginYaybar = initPluginYaybar;

/**
 * Component
 */
class PageYaybar extends Component {
    constructor(props) {
        super(props);

        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.logOut = this.logOut.bind(this);
        this.filteredCounter = {};
    }

    componentDidMount() {
        window.RootUI.initPluginYaybar();
        window.jQuery("#yaybar-refresh").data("func", this.refreshYaybar);
        this.getCounterFiltered();
        this.getStatusesStyles();
        this.getSystemSettings();
        this.getMetaAppTypes();
    }

    refreshYaybar = () => {
        this.forceUpdate();
    };

    getCounterFiltered = async () => {
        const response = await axios.get("my");
        this.setState({ filteredCounter: response.data });
    };

    getStatusesStyles = async () => {
        const { updateStyles } = this.props;
        const stylesResponse = await axios.get("bs/icons", {
            params: {
                noPagination: "True",
            },
        });
        stylesResponse.data?.data?.length && updateStyles(mapStatusesStyles(stylesResponse.data.data));
    };

    getSystemSettings = async () => {
        const { updateSystemSettings } = this.props;
        const systemSettings = await axios.get("system-settings");
        updateSystemSettings(systemSettings.data);
    };

    getMetaAppTypes = async () => {
        const { updateMetaAppTypes } = this.props;

        try {
            const res = await axios.get(metaAppsUrls.metaAppType(), { params: { noPagination: "True" } });

            updateMetaAppTypes({ metaAppTypes: res.data.data });
        } catch (e) {}
    };

    componentWillUnmount() {
        window.YAYBAR.prototype.destroy();
    }

    checkName = (name) => {
        return this.state?.filteredCounter?.hasOwnProperty(name) && this.state?.filteredCounter[name] ? true : false;
    };

    getCount = (name) => {
        let number = 0;
        Object.entries(this.state.filteredCounter).forEach((item) => {
            return item[0] == name ? (number = item[1]) : null;
        });
        return number;
    };

    logOut() {
        const { updateAuth, updatePermissions } = this.props;

        const clearAuthPromys = new Promise((res, rej) => {
            updatePermissions({});
            updateAuth({ token: "", name: "", laboratory: "" });
        });

        const csrftoken = Cookies.get("csrftoken");
        const logoutPromys = new Promise((res, rej) => {
            axios.post("logout", undefined, csrftoken ? { headers: { "X-CSRFToken": csrftoken } } : undefined);
        });

        clearAuthPromys.then((_) => logoutPromys);
    }

    renderSubmenus(nav, returnObject = false) {
        let thereIsActive = false;

        const result = Object.keys(nav).map((url) => {
            const data = nav[url];

            let isActive = null;

            if (window.location.pathname == "/" && url == "/") {
                isActive = true;
            } else {
                isActive = url[1] ? window.location.pathname == url : false;
            }

            let isOpened = false;

            if (isActive) {
                thereIsActive = true;
            }

            let sub = "";
            if (data.sub) {
                let subData;
                if (data.name === "Basic settings") {
                    const sortedValues = Object.values(data.sub).sort((a, b) => (i18n.t(a.name) > i18n.t(b.name) ? 1 : -1));
                    const sortedKeys = Object.entries(data.sub).sort((a, b) => (i18n.t(a[1].name) > i18n.t(b[1].name) ? 1 : -1));
                    let sortedDataSub = {};
                    for (let i = 0; i < sortedKeys.length; i++) {
                        sortedDataSub[sortedKeys[i][0]] = sortedValues[i];
                    }
                    subData = this.renderSubmenus(sortedDataSub, true);
                } else {
                    subData = this.renderSubmenus(data.sub, true);
                }

                sub = <ul className="yay-submenu dropdown-triangle">{subData.content}</ul>;

                if (subData.thereIsActive) {
                    isOpened = true;
                    thereIsActive = true;
                }
            }

            if (data.permCode != undefined)
                if (!hasPermission(data.permCode)) {
                    return null;
                }

            return (
                <React.Fragment key={`${url}-${data.name}`}>
                    {data.label ? (
                        <li className="yay-label">
                            <Trans>{data.label}</Trans>
                        </li>
                    ) : (
                        ""
                    )}
                    <li
                        className={classnames({
                            "yay-item-active": isActive,
                            "yay-submenu-open": isOpened || (isActive && url == "/bs"),
                        })}
                    >
                        {data.name ? (
                            //Tile KM
                            <NavLink
                                to={
                                    [
                                        "/my",
                                        "/bs",
                                        "/csv",
                                        "/orders",
                                        "/stability-tests",
                                        "/cdr",
                                        "/crm",
                                        "/reports",
                                        "/demands",
                                        "/integrated-system",
                                    ].includes(url)
                                        ? url
                                        : data.sub
                                        ? "#"
                                        : url
                                }
                                className={
                                    (data.name == "Basic Settings" ? "basic-settings-yay " : "") +
                                    (url == "/bs" ? "yay-sub-toggle" : data.sub ? "yay-sub-toggle" : "")
                                }
                            >
                                {data.icon ? (
                                    <>
                                        <span className="yay-icon">
                                            <Icon name={data.icon} />
                                        </span>
                                        <span>
                                            <Trans>{data.name}</Trans>
                                        </span>
                                        {data.name == "Filtered for me" && this.state?.filteredCounter && (
                                            <Badge className="badge-circle" color="danger">
                                                {this.state.filteredCounter?.sum}
                                            </Badge>
                                        )}
                                        <span className="rui-yaybar-circle" />
                                    </>
                                ) : (
                                    <>
                                        <span>
                                            <Trans>{data.name}</Trans>
                                        </span>
                                        {this.checkName(data.name) && (
                                            <Badge className="badge-circle" color="danger">
                                                {this.getCount(data.name)}
                                            </Badge>
                                        )}
                                    </>
                                )}
                                {data.sub ? (
                                    <span className="yay-icon-collapse">
                                        <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                                    </span>
                                ) : (
                                    ""
                                )}
                            </NavLink>
                        ) : (
                            ""
                        )}
                        {sub}
                    </li>
                </React.Fragment>
            );
        });

        if (returnObject) {
            return {
                content: result,
                thereIsActive,
            };
        }

        return result;
    }

    renderDynamicSubmenus() {
        const {
            location: { state },
            metaAppTypes,
        } = this.props;

        return (
            <>
                <li className="yay-label">
                    <Trans>Meta Apps</Trans>
                </li>
                {metaAppTypes.map((type, appIndex) => {
                    let isActive = false;

                    if (type.name == state?.metaAppTypeName && window.location.pathname == "/meta-app") {
                        isActive = true;
                    }

                    return (
                        <li className={classnames({ "yay-item-active": isActive })} key={appIndex}>
                            {type.status == "draft" ? (
                                <a>
                                    <TooltipHOC
                                        style={{ width: "100%" }}
                                        tooltipContent={"You have to activate workflow that is assigned to this meta app"}
                                    >
                                        <span className="yay-icon" style={{ marginRight: "14px" }}>
                                            <Icon name={type.icon} />
                                        </span>
                                        <span>{type.name}</span>
                                        <span className="rui-yaybar-circle" />
                                    </TooltipHOC>
                                </a>
                            ) : (
                                <NavLink to={{ pathname: "/meta-app", state: { metaAppTypeName: type.name } }}>
                                    <span className="yay-icon">
                                        <Icon name={type.icon} />
                                    </span>
                                    <span>{type.name}</span>
                                    <span className="rui-yaybar-circle" />
                                </NavLink>
                            )}
                        </li>
                    );
                })}
            </>
        );
    }

    render() {
        const { settings } = this.props;

        return (
            <>
                <div
                    className={classnames(
                        "yaybar rui-yaybar yay-hide-to-small yay-gestures",
                        settings.sidebar_dark && !settings.night_mode ? "rui-yaybar-dark" : "",
                        settings.sidebar_static ? "yay-static" : "",
                        settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : ""
                    )}
                >
                    <div className="yay-wrap-menu">
                        <div className="yaybar-wrap">
                            <div id="yaybar-refresh" style={{ display: "none" }}></div>
                            <ul>
                                {this.renderSubmenus(settings.navigation_sidebar)}
                                {this.renderDynamicSubmenus()}

                                <li className="yay-label">
                                    <Trans>Account</Trans>
                                </li>
                                <li>
                                    <NavLink to="/profile">
                                        <span className="yay-icon">
                                            <Icon name={["far", "user"]} />
                                        </span>
                                        <span>
                                            <Trans>My profile</Trans>
                                        </span>
                                        <span className="rui-yaybar-circle"></span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sign-in" onClick={this.logOut}>
                                        <span className="yay-icon">
                                            <Icon name="log-out" />
                                        </span>
                                        <span>
                                            <Trans>Log Out</Trans>
                                        </span>
                                        <span className="rui-yaybar-circle"></span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="rui-yaybar-bg" />
            </>
        );
    }
}

export default withRouter(
    connect(({ settings, metaAppTypes }) => ({ settings, metaAppTypes }), {
        updateAuth,
        updatePermissions,
        updateStyles,
        updateSystemSettings,
        updateMetaAppTypes,
    })(PageYaybar)
);
