export const fontAwesomeIcons: ILabelValue[] = [
    {
        label: "0",
        value: "0",
    },
    {
        label: "1",
        value: "1",
    },
    {
        label: "2",
        value: "2",
    },
    {
        label: "3",
        value: "3",
    },
    {
        label: "4",
        value: "4",
    },
    {
        label: "5",
        value: "5",
    },
    {
        label: "6",
        value: "6",
    },
    {
        label: "7",
        value: "7",
    },
    {
        label: "8",
        value: "8",
    },
    {
        label: "9",
        value: "9",
    },
    {
        label: "A",
        value: "a",
    },
    {
        label: "Align Center",
        value: "align-center",
    },
    {
        label: "Align Justify",
        value: "align-justify",
    },
    {
        label: "Align Left",
        value: "align-left",
    },
    {
        label: "Align Right",
        value: "align-right",
    },
    {
        label: "Anchor",
        value: "anchor",
    },
    {
        label: "Angle Down",
        value: "angle-down",
    },
    {
        label: "Angle Left",
        value: "angle-left",
    },
    {
        label: "Angle Right",
        value: "angle-right",
    },
    {
        label: "Angles Down",
        value: "angles-down",
    },
    {
        label: "Angles Left",
        value: "angles-left",
    },
    {
        label: "Angles Right",
        value: "angles-right",
    },
    {
        label: "Angles Up",
        value: "angles-up",
    },
    {
        label: "Angle Up",
        value: "angle-up",
    },
    {
        label: "Ankh",
        value: "ankh",
    },
    {
        label: "Apple Whole",
        value: "apple-whole",
    },
    {
        label: "Archway",
        value: "archway",
    },
    {
        label: "Arrow Down",
        value: "arrow-down",
    },
    {
        label: "Arrow Down A Z",
        value: "arrow-down-a-z",
    },
    {
        label: "Arrow Down Long",
        value: "arrow-down-long",
    },
    {
        label: "Arrow Down Short Wide",
        value: "arrow-down-short-wide",
    },
    {
        label: "Arrow Down Wide Short",
        value: "arrow-down-wide-short",
    },
    {
        label: "Arrow Down Z A",
        value: "arrow-down-z-a",
    },
    {
        label: "Arrow Left",
        value: "arrow-left",
    },
    {
        label: "Arrow Left Long",
        value: "arrow-left-long",
    },
    {
        label: "Arrow Pointer",
        value: "arrow-pointer",
    },
    {
        label: "Arrow Right",
        value: "arrow-right",
    },
    {
        label: "Arrow Right Arrow Left",
        value: "arrow-right-arrow-left",
    },
    {
        label: "Arrow Right From Bracket",
        value: "arrow-right-from-bracket",
    },
    {
        label: "Arrow Right Long",
        value: "arrow-right-long",
    },
    {
        label: "Arrow Right To Bracket",
        value: "arrow-right-to-bracket",
    },
    {
        label: "Arrow Rotate Left",
        value: "arrow-rotate-left",
    },
    {
        label: "Arrow Rotate Right",
        value: "arrow-rotate-right",
    },
    {
        label: "Arrows Left Right",
        value: "arrows-left-right",
    },
    {
        label: "Arrows Rotate",
        value: "arrows-rotate",
    },
    {
        label: "Arrows Up Down",
        value: "arrows-up-down",
    },
    {
        label: "Arrows Up Down Left Right",
        value: "arrows-up-down-left-right",
    },
    {
        label: "Arrow Trend Down",
        value: "arrow-trend-down",
    },
    {
        label: "Arrow Trend Up",
        value: "arrow-trend-up",
    },
    {
        label: "Arrow Turn Down",
        value: "arrow-turn-down",
    },
    {
        label: "Arrow Turn Up",
        value: "arrow-turn-up",
    },
    {
        label: "Arrow Up",
        value: "arrow-up",
    },
    {
        label: "Arrow Up A Z",
        value: "arrow-up-a-z",
    },
    {
        label: "Arrow Up From Bracket",
        value: "arrow-up-from-bracket",
    },
    {
        label: "Arrow Up Long",
        value: "arrow-up-long",
    },
    {
        label: "Arrow Up Right From Square",
        value: "arrow-up-right-from-square",
    },
    {
        label: "Arrow Up Short Wide",
        value: "arrow-up-short-wide",
    },
    {
        label: "Arrow Up Wide Short",
        value: "arrow-up-wide-short",
    },
    {
        label: "Arrow Up Z A",
        value: "arrow-up-z-a",
    },
    {
        label: "Asterisk",
        value: "asterisk",
    },
    {
        label: "At",
        value: "at",
    },
    {
        label: "Atom",
        value: "atom",
    },
    {
        label: "Audio Description",
        value: "audio-description",
    },
    {
        label: "Austral Sign",
        value: "austral-sign",
    },
    {
        label: "Award",
        value: "award",
    },
    {
        label: "B",
        value: "b",
    },
    {
        label: "Baby",
        value: "baby",
    },
    {
        label: "Baby Carriage",
        value: "baby-carriage",
    },
    {
        label: "Backward",
        value: "backward",
    },
    {
        label: "Backward Fast",
        value: "backward-fast",
    },
    {
        label: "Backward Step",
        value: "backward-step",
    },
    {
        label: "Bacon",
        value: "bacon",
    },
    {
        label: "Bacteria",
        value: "bacteria",
    },
    {
        label: "Bacterium",
        value: "bacterium",
    },
    {
        label: "Bag Shopping",
        value: "bag-shopping",
    },
    {
        label: "Bahai",
        value: "bahai",
    },
    {
        label: "Baht Sign",
        value: "baht-sign",
    },
    {
        label: "Ban",
        value: "ban",
    },
    {
        label: "Bandage",
        value: "bandage",
    },
    {
        label: "Ban Smoking",
        value: "ban-smoking",
    },
    {
        label: "Barcode",
        value: "barcode",
    },
    {
        label: "Bars",
        value: "bars",
    },
    {
        label: "Bars Progress",
        value: "bars-progress",
    },
    {
        label: "Bars Staggered",
        value: "bars-staggered",
    },
    {
        label: "Baseball",
        value: "baseball",
    },
    {
        label: "Basketball",
        value: "basketball",
    },
    {
        label: "Basket Shopping",
        value: "basket-shopping",
    },
    {
        label: "Bath",
        value: "bath",
    },
    {
        label: "Battery Empty",
        value: "battery-empty",
    },
    {
        label: "Battery Full",
        value: "battery-full",
    },
    {
        label: "Battery Half",
        value: "battery-half",
    },
    {
        label: "Battery Quarter",
        value: "battery-quarter",
    },
    {
        label: "Battery Three Quarters",
        value: "battery-three-quarters",
    },
    {
        label: "Bed",
        value: "bed",
    },
    {
        label: "Bed Pulse",
        value: "bed-pulse",
    },
    {
        label: "Beer Mug Empty",
        value: "beer-mug-empty",
    },
    {
        label: "Bell Concierge",
        value: "bell-concierge",
    },
    {
        label: "Bezier Curve",
        value: "bezier-curve",
    },
    {
        label: "Bicycle",
        value: "bicycle",
    },
    {
        label: "Binoculars",
        value: "binoculars",
    },
    {
        label: "Biohazard",
        value: "biohazard",
    },
    {
        label: "Bitcoin Sign",
        value: "bitcoin-sign",
    },
    {
        label: "Blender",
        value: "blender",
    },
    {
        label: "Blender Phone",
        value: "blender-phone",
    },
    {
        label: "Blog",
        value: "blog",
    },
    {
        label: "Bold",
        value: "bold",
    },
    {
        label: "Bolt",
        value: "bolt",
    },
    {
        label: "Bomb",
        value: "bomb",
    },
    {
        label: "Bone",
        value: "bone",
    },
    {
        label: "Bong",
        value: "bong",
    },
    {
        label: "Book",
        value: "book",
    },
    {
        label: "Book Atlas",
        value: "book-atlas",
    },
    {
        label: "Book Bible",
        value: "book-bible",
    },
    {
        label: "Book Journal Whills",
        value: "book-journal-whills",
    },
    {
        label: "Book Medical",
        value: "book-medical",
    },
    {
        label: "Book Open",
        value: "book-open",
    },
    {
        label: "Book Open Reader",
        value: "book-open-reader",
    },
    {
        label: "Book Quran",
        value: "book-quran",
    },
    {
        label: "Book Skull",
        value: "book-skull",
    },
    {
        label: "Border All",
        value: "border-all",
    },
    {
        label: "Border None",
        value: "border-none",
    },
    {
        label: "Border Top Left",
        value: "border-top-left",
    },
    {
        label: "Bowling Ball",
        value: "bowling-ball",
    },
    {
        label: "Box",
        value: "box",
    },
    {
        label: "Box Archive",
        value: "box-archive",
    },
    {
        label: "Boxes Stacked",
        value: "boxes-stacked",
    },
    {
        label: "Box Open",
        value: "box-open",
    },
    {
        label: "Box Tissue",
        value: "box-tissue",
    },
    {
        label: "Braille",
        value: "braille",
    },
    {
        label: "Brain",
        value: "brain",
    },
    {
        label: "Brazilian Real Sign",
        value: "brazilian-real-sign",
    },
    {
        label: "Bread Slice",
        value: "bread-slice",
    },
    {
        label: "Briefcase",
        value: "briefcase",
    },
    {
        label: "Briefcase Medical",
        value: "briefcase-medical",
    },
    {
        label: "Broom",
        value: "broom",
    },
    {
        label: "Broom Ball",
        value: "broom-ball",
    },
    {
        label: "Brush",
        value: "brush",
    },
    {
        label: "Bug",
        value: "bug",
    },
    {
        label: "Bullhorn",
        value: "bullhorn",
    },
    {
        label: "Bullseye",
        value: "bullseye",
    },
    {
        label: "Burger",
        value: "burger",
    },
    {
        label: "Bus",
        value: "bus",
    },
    {
        label: "Business Time",
        value: "business-time",
    },
    {
        label: "Bus Simple",
        value: "bus-simple",
    },
    {
        label: "C",
        value: "c",
    },
    {
        label: "Cake Candles",
        value: "cake-candles",
    },
    {
        label: "Calculator",
        value: "calculator",
    },
    {
        label: "Calendar Day",
        value: "calendar-day",
    },
    {
        label: "Calendar Week",
        value: "calendar-week",
    },
    {
        label: "Camera",
        value: "camera",
    },
    {
        label: "Camera Retro",
        value: "camera-retro",
    },
    {
        label: "Camera Rotate",
        value: "camera-rotate",
    },
    {
        label: "Campground",
        value: "campground",
    },
    {
        label: "Candy Cane",
        value: "candy-cane",
    },
    {
        label: "Cannabis",
        value: "cannabis",
    },
    {
        label: "Capsules",
        value: "capsules",
    },
    {
        label: "Car",
        value: "car",
    },
    {
        label: "Caravan",
        value: "caravan",
    },
    {
        label: "Car Battery",
        value: "car-battery",
    },
    {
        label: "Caret Down",
        value: "caret-down",
    },
    {
        label: "Caret Left",
        value: "caret-left",
    },
    {
        label: "Caret Right",
        value: "caret-right",
    },
    {
        label: "Caret Up",
        value: "caret-up",
    },
    {
        label: "Car Rear",
        value: "car-rear",
    },
    {
        label: "Carrot",
        value: "carrot",
    },
    {
        label: "Car Side",
        value: "car-side",
    },
    {
        label: "Cart Arrow Down",
        value: "cart-arrow-down",
    },
    {
        label: "Cart Flatbed",
        value: "cart-flatbed",
    },
    {
        label: "Cart Flatbed Suitcase",
        value: "cart-flatbed-suitcase",
    },
    {
        label: "Cart Plus",
        value: "cart-plus",
    },
    {
        label: "Cart Shopping",
        value: "cart-shopping",
    },
    {
        label: "Cash Register",
        value: "cash-register",
    },
    {
        label: "Cat",
        value: "cat",
    },
    {
        label: "Cedi Sign",
        value: "cedi-sign",
    },
    {
        label: "Cent Sign",
        value: "cent-sign",
    },
    {
        label: "Certificate",
        value: "certificate",
    },
    {
        label: "Chair",
        value: "chair",
    },
    {
        label: "Chalkboard",
        value: "chalkboard",
    },
    {
        label: "Chalkboard User",
        value: "chalkboard-user",
    },
    {
        label: "Champagne Glasses",
        value: "champagne-glasses",
    },
    {
        label: "Charging Station",
        value: "charging-station",
    },
    {
        label: "Chart Area",
        value: "chart-area",
    },
    {
        label: "Chart Column",
        value: "chart-column",
    },
    {
        label: "Chart Gantt",
        value: "chart-gantt",
    },
    {
        label: "Chart Line",
        value: "chart-line",
    },
    {
        label: "Chart Pie",
        value: "chart-pie",
    },
    {
        label: "Check",
        value: "check",
    },
    {
        label: "Check Double",
        value: "check-double",
    },
    {
        label: "Check To Slot",
        value: "check-to-slot",
    },
    {
        label: "Cheese",
        value: "cheese",
    },
    {
        label: "Chess",
        value: "chess",
    },
    {
        label: "Chess Board",
        value: "chess-board",
    },
    {
        label: "Chevron Down",
        value: "chevron-down",
    },
    {
        label: "Chevron Left",
        value: "chevron-left",
    },
    {
        label: "Chevron Right",
        value: "chevron-right",
    },
    {
        label: "Chevron Up",
        value: "chevron-up",
    },
    {
        label: "Child",
        value: "child",
    },
    {
        label: "Church",
        value: "church",
    },
    {
        label: "Circle Arrow Down",
        value: "circle-arrow-down",
    },
    {
        label: "Circle Arrow Left",
        value: "circle-arrow-left",
    },
    {
        label: "Circle Arrow Right",
        value: "circle-arrow-right",
    },
    {
        label: "Circle Arrow Up",
        value: "circle-arrow-up",
    },
    {
        label: "Circle Chevron Down",
        value: "circle-chevron-down",
    },
    {
        label: "Circle Chevron Left",
        value: "circle-chevron-left",
    },
    {
        label: "Circle Chevron Right",
        value: "circle-chevron-right",
    },
    {
        label: "Circle Chevron Up",
        value: "circle-chevron-up",
    },
    {
        label: "Circle Dollar To Slot",
        value: "circle-dollar-to-slot",
    },
    {
        label: "Circle Exclamation",
        value: "circle-exclamation",
    },
    {
        label: "Circle H",
        value: "circle-h",
    },
    {
        label: "Circle Half Stroke",
        value: "circle-half-stroke",
    },
    {
        label: "Circle Info",
        value: "circle-info",
    },
    {
        label: "Circle Minus",
        value: "circle-minus",
    },
    {
        label: "Circle Notch",
        value: "circle-notch",
    },
    {
        label: "Circle Plus",
        value: "circle-plus",
    },
    {
        label: "Circle Radiation",
        value: "circle-radiation",
    },
    {
        label: "City",
        value: "city",
    },
    {
        label: "Clapperboard",
        value: "clapperboard",
    },
    {
        label: "Clipboard Check",
        value: "clipboard-check",
    },
    {
        label: "Clipboard List",
        value: "clipboard-list",
    },
    {
        label: "Clock Rotate Left",
        value: "clock-rotate-left",
    },
    {
        label: "Cloud",
        value: "cloud",
    },
    {
        label: "Cloud Arrow Down",
        value: "cloud-arrow-down",
    },
    {
        label: "Cloud Arrow Up",
        value: "cloud-arrow-up",
    },
    {
        label: "Cloud Meatball",
        value: "cloud-meatball",
    },
    {
        label: "Cloud Moon",
        value: "cloud-moon",
    },
    {
        label: "Cloud Moon Rain",
        value: "cloud-moon-rain",
    },
    {
        label: "Cloud Rain",
        value: "cloud-rain",
    },
    {
        label: "Cloud Showers Heavy",
        value: "cloud-showers-heavy",
    },
    {
        label: "Cloud Sun",
        value: "cloud-sun",
    },
    {
        label: "Cloud Sun Rain",
        value: "cloud-sun-rain",
    },
    {
        label: "Clover",
        value: "clover",
    },
    {
        label: "Code",
        value: "code",
    },
    {
        label: "Code Branch",
        value: "code-branch",
    },
    {
        label: "Code Commit",
        value: "code-commit",
    },
    {
        label: "Code Compare",
        value: "code-compare",
    },
    {
        label: "Code Fork",
        value: "code-fork",
    },
    {
        label: "Code Merge",
        value: "code-merge",
    },
    {
        label: "Code Pull Request",
        value: "code-pull-request",
    },
    {
        label: "Coins",
        value: "coins",
    },
    {
        label: "Colon Sign",
        value: "colon-sign",
    },
    {
        label: "Comment Dollar",
        value: "comment-dollar",
    },
    {
        label: "Comment Medical",
        value: "comment-medical",
    },
    {
        label: "Comments Dollar",
        value: "comments-dollar",
    },
    {
        label: "Comment Slash",
        value: "comment-slash",
    },
    {
        label: "Comment Sms",
        value: "comment-sms",
    },
    {
        label: "Compact Disc",
        value: "compact-disc",
    },
    {
        label: "Compass Drafting",
        value: "compass-drafting",
    },
    {
        label: "Compress",
        value: "compress",
    },
    {
        label: "Computer Mouse",
        value: "computer-mouse",
    },
    {
        label: "Cookie",
        value: "cookie",
    },
    {
        label: "Cookie Bite",
        value: "cookie-bite",
    },
    {
        label: "Couch",
        value: "couch",
    },
    {
        label: "Crop",
        value: "crop",
    },
    {
        label: "Crop Simple",
        value: "crop-simple",
    },
    {
        label: "Cross",
        value: "cross",
    },
    {
        label: "Crosshairs",
        value: "crosshairs",
    },
    {
        label: "Crow",
        value: "crow",
    },
    {
        label: "Crown",
        value: "crown",
    },
    {
        label: "Crutch",
        value: "crutch",
    },
    {
        label: "Cruzeiro Sign",
        value: "cruzeiro-sign",
    },
    {
        label: "Cube",
        value: "cube",
    },
    {
        label: "Cubes",
        value: "cubes",
    },
    {
        label: "D",
        value: "d",
    },
    {
        label: "Database",
        value: "database",
    },
    {
        label: "Delete Left",
        value: "delete-left",
    },
    {
        label: "Democrat",
        value: "democrat",
    },
    {
        label: "Desktop",
        value: "desktop",
    },
    {
        label: "Dharmachakra",
        value: "dharmachakra",
    },
    {
        label: "Diagram Project",
        value: "diagram-project",
    },
    {
        label: "Diamond",
        value: "diamond",
    },
    {
        label: "Diamond Turn Right",
        value: "diamond-turn-right",
    },
    {
        label: "Dice",
        value: "dice",
    },
    {
        label: "Dice D20",
        value: "dice-d20",
    },
    {
        label: "Dice D6",
        value: "dice-d6",
    },
    {
        label: "Dice Five",
        value: "dice-five",
    },
    {
        label: "Dice Four",
        value: "dice-four",
    },
    {
        label: "Dice One",
        value: "dice-one",
    },
    {
        label: "Dice Six",
        value: "dice-six",
    },
    {
        label: "Dice Three",
        value: "dice-three",
    },
    {
        label: "Dice Two",
        value: "dice-two",
    },
    {
        label: "Disease",
        value: "disease",
    },
    {
        label: "Divide",
        value: "divide",
    },
    {
        label: "Dna",
        value: "dna",
    },
    {
        label: "Dog",
        value: "dog",
    },
    {
        label: "Dollar Sign",
        value: "dollar-sign",
    },
    {
        label: "Dolly",
        value: "dolly",
    },
    {
        label: "Dong Sign",
        value: "dong-sign",
    },
    {
        label: "Door Closed",
        value: "door-closed",
    },
    {
        label: "Door Open",
        value: "door-open",
    },
    {
        label: "Dove",
        value: "dove",
    },
    {
        label: "Down Left And Up Right To Center",
        value: "down-left-and-up-right-to-center",
    },
    {
        label: "Download",
        value: "download",
    },
    {
        label: "Down Long",
        value: "down-long",
    },
    {
        label: "Dragon",
        value: "dragon",
    },
    {
        label: "Draw Polygon",
        value: "draw-polygon",
    },
    {
        label: "Droplet",
        value: "droplet",
    },
    {
        label: "Droplet Slash",
        value: "droplet-slash",
    },
    {
        label: "Drum",
        value: "drum",
    },
    {
        label: "Drum Steelpan",
        value: "drum-steelpan",
    },
    {
        label: "Drumstick Bite",
        value: "drumstick-bite",
    },
    {
        label: "Dumbbell",
        value: "dumbbell",
    },
    {
        label: "Dumpster",
        value: "dumpster",
    },
    {
        label: "Dumpster Fire",
        value: "dumpster-fire",
    },
    {
        label: "Dungeon",
        value: "dungeon",
    },
    {
        label: "E",
        value: "e",
    },
    {
        label: "Ear Deaf",
        value: "ear-deaf",
    },
    {
        label: "Ear Listen",
        value: "ear-listen",
    },
    {
        label: "Earth Africa",
        value: "earth-africa",
    },
    {
        label: "Earth Americas",
        value: "earth-americas",
    },
    {
        label: "Earth Asia",
        value: "earth-asia",
    },
    {
        label: "Earth Europe",
        value: "earth-europe",
    },
    {
        label: "Earth Oceania",
        value: "earth-oceania",
    },
    {
        label: "Egg",
        value: "egg",
    },
    {
        label: "Eject",
        value: "eject",
    },
    {
        label: "Elevator",
        value: "elevator",
    },
    {
        label: "Ellipsis",
        value: "ellipsis",
    },
    {
        label: "Ellipsis Vertical",
        value: "ellipsis-vertical",
    },
    {
        label: "Envelope Open Text",
        value: "envelope-open-text",
    },
    {
        label: "Envelopes Bulk",
        value: "envelopes-bulk",
    },
    {
        label: "Equals",
        value: "equals",
    },
    {
        label: "Eraser",
        value: "eraser",
    },
    {
        label: "Ethernet",
        value: "ethernet",
    },
    {
        label: "Euro Sign",
        value: "euro-sign",
    },
    {
        label: "Exclamation",
        value: "exclamation",
    },
    {
        label: "Expand",
        value: "expand",
    },
    {
        label: "Eye Dropper",
        value: "eye-dropper",
    },
    {
        label: "Eye Low Vision",
        value: "eye-low-vision",
    },
    {
        label: "F",
        value: "f",
    },
    {
        label: "Fan",
        value: "fan",
    },
    {
        label: "Faucet",
        value: "faucet",
    },
    {
        label: "Fax",
        value: "fax",
    },
    {
        label: "Feather",
        value: "feather",
    },
    {
        label: "Feather Pointed",
        value: "feather-pointed",
    },
    {
        label: "File Arrow Down",
        value: "file-arrow-down",
    },
    {
        label: "File Arrow Up",
        value: "file-arrow-up",
    },
    {
        label: "File Contract",
        value: "file-contract",
    },
    {
        label: "File Csv",
        value: "file-csv",
    },
    {
        label: "File Export",
        value: "file-export",
    },
    {
        label: "File Import",
        value: "file-import",
    },
    {
        label: "File Invoice",
        value: "file-invoice",
    },
    {
        label: "File Invoice Dollar",
        value: "file-invoice-dollar",
    },
    {
        label: "File Medical",
        value: "file-medical",
    },
    {
        label: "File Prescription",
        value: "file-prescription",
    },
    {
        label: "File Signature",
        value: "file-signature",
    },
    {
        label: "File Waveform",
        value: "file-waveform",
    },
    {
        label: "Fill",
        value: "fill",
    },
    {
        label: "Fill Drip",
        value: "fill-drip",
    },
    {
        label: "Film",
        value: "film",
    },
    {
        label: "Filter",
        value: "filter",
    },
    {
        label: "Filter Circle Dollar",
        value: "filter-circle-dollar",
    },
    {
        label: "Filter Circle Xmark",
        value: "filter-circle-xmark",
    },
    {
        label: "Fingerprint",
        value: "fingerprint",
    },
    {
        label: "Fire",
        value: "fire",
    },
    {
        label: "Fire Extinguisher",
        value: "fire-extinguisher",
    },
    {
        label: "Fire Flame Curved",
        value: "fire-flame-curved",
    },
    {
        label: "Fire Flame Simple",
        value: "fire-flame-simple",
    },
    {
        label: "Fish",
        value: "fish",
    },
    {
        label: "Flag Checkered",
        value: "flag-checkered",
    },
    {
        label: "Flag Usa",
        value: "flag-usa",
    },
    {
        label: "Flask",
        value: "flask",
    },
    {
        label: "Florin Sign",
        value: "florin-sign",
    },
    {
        label: "Folder Minus",
        value: "folder-minus",
    },
    {
        label: "Folder Plus",
        value: "folder-plus",
    },
    {
        label: "Folder Tree",
        value: "folder-tree",
    },
    {
        label: "Font",
        value: "font",
    },
    {
        label: "Football",
        value: "football",
    },
    {
        label: "Forward",
        value: "forward",
    },
    {
        label: "Forward Fast",
        value: "forward-fast",
    },
    {
        label: "Forward Step",
        value: "forward-step",
    },
    {
        label: "Franc Sign",
        value: "franc-sign",
    },
    {
        label: "Frog",
        value: "frog",
    },
    {
        label: "G",
        value: "g",
    },
    {
        label: "Gamepad",
        value: "gamepad",
    },
    {
        label: "Gas Pump",
        value: "gas-pump",
    },
    {
        label: "Gauge",
        value: "gauge",
    },
    {
        label: "Gauge High",
        value: "gauge-high",
    },
    {
        label: "Gauge Simple",
        value: "gauge-simple",
    },
    {
        label: "Gauge Simple High",
        value: "gauge-simple-high",
    },
    {
        label: "Gavel",
        value: "gavel",
    },
    {
        label: "Gear",
        value: "gear",
    },
    {
        label: "Gears",
        value: "gears",
    },
    {
        label: "Genderless",
        value: "genderless",
    },
    {
        label: "Ghost",
        value: "ghost",
    },
    {
        label: "Gift",
        value: "gift",
    },
    {
        label: "Gifts",
        value: "gifts",
    },
    {
        label: "Glasses",
        value: "glasses",
    },
    {
        label: "Globe",
        value: "globe",
    },
    {
        label: "Golf Ball Tee",
        value: "golf-ball-tee",
    },
    {
        label: "Gopuram",
        value: "gopuram",
    },
    {
        label: "Graduation Cap",
        value: "graduation-cap",
    },
    {
        label: "Greater Than",
        value: "greater-than",
    },
    {
        label: "Greater Than Equal",
        value: "greater-than-equal",
    },
    {
        label: "Grip",
        value: "grip",
    },
    {
        label: "Grip Lines",
        value: "grip-lines",
    },
    {
        label: "Grip Lines Vertical",
        value: "grip-lines-vertical",
    },
    {
        label: "Grip Vertical",
        value: "grip-vertical",
    },
    {
        label: "Guarani Sign",
        value: "guarani-sign",
    },
    {
        label: "Guitar",
        value: "guitar",
    },
    {
        label: "Gun",
        value: "gun",
    },
    {
        label: "H",
        value: "h",
    },
    {
        label: "Hammer",
        value: "hammer",
    },
    {
        label: "Hamsa",
        value: "hamsa",
    },
    {
        label: "Hand Dots",
        value: "hand-dots",
    },
    {
        label: "Hand Fist",
        value: "hand-fist",
    },
    {
        label: "Hand Holding",
        value: "hand-holding",
    },
    {
        label: "Hand Holding Dollar",
        value: "hand-holding-dollar",
    },
    {
        label: "Hand Holding Droplet",
        value: "hand-holding-droplet",
    },
    {
        label: "Hand Holding Heart",
        value: "hand-holding-heart",
    },
    {
        label: "Hand Holding Medical",
        value: "hand-holding-medical",
    },
    {
        label: "Hand Middle Finger",
        value: "hand-middle-finger",
    },
    {
        label: "Hands",
        value: "hands",
    },
    {
        label: "Hands Asl Interpreting",
        value: "hands-asl-interpreting",
    },
    {
        label: "Hands Bubbles",
        value: "hands-bubbles",
    },
    {
        label: "Hands Clapping",
        value: "hands-clapping",
    },
    {
        label: "Handshake Angle",
        value: "handshake-angle",
    },
    {
        label: "Handshake Simple Slash",
        value: "handshake-simple-slash",
    },
    {
        label: "Handshake Slash",
        value: "handshake-slash",
    },
    {
        label: "Hands Holding",
        value: "hands-holding",
    },
    {
        label: "Hand Sparkles",
        value: "hand-sparkles",
    },
    {
        label: "Hands Praying",
        value: "hands-praying",
    },
    {
        label: "Hanukiah",
        value: "hanukiah",
    },
    {
        label: "Hashtag",
        value: "hashtag",
    },
    {
        label: "Hat Cowboy",
        value: "hat-cowboy",
    },
    {
        label: "Hat Cowboy Side",
        value: "hat-cowboy-side",
    },
    {
        label: "Hat Wizard",
        value: "hat-wizard",
    },
    {
        label: "Heading",
        value: "heading",
    },
    {
        label: "Headphones",
        value: "headphones",
    },
    {
        label: "Headphones Simple",
        value: "headphones-simple",
    },
    {
        label: "Headset",
        value: "headset",
    },
    {
        label: "Head Side Cough",
        value: "head-side-cough",
    },
    {
        label: "Head Side Cough Slash",
        value: "head-side-cough-slash",
    },
    {
        label: "Head Side Mask",
        value: "head-side-mask",
    },
    {
        label: "Head Side Virus",
        value: "head-side-virus",
    },
    {
        label: "Heart Crack",
        value: "heart-crack",
    },
    {
        label: "Heart Pulse",
        value: "heart-pulse",
    },
    {
        label: "Helicopter",
        value: "helicopter",
    },
    {
        label: "Helmet Safety",
        value: "helmet-safety",
    },
    {
        label: "Highlighter",
        value: "highlighter",
    },
    {
        label: "Hippo",
        value: "hippo",
    },
    {
        label: "Hockey Puck",
        value: "hockey-puck",
    },
    {
        label: "Holly Berry",
        value: "holly-berry",
    },
    {
        label: "Horse",
        value: "horse",
    },
    {
        label: "Horse Head",
        value: "horse-head",
    },
    {
        label: "Hospital User",
        value: "hospital-user",
    },
    {
        label: "Hotdog",
        value: "hotdog",
    },
    {
        label: "Hotel",
        value: "hotel",
    },
    {
        label: "Hot Tub Person",
        value: "hot-tub-person",
    },
    {
        label: "Hourglass End",
        value: "hourglass-end",
    },
    {
        label: "Hourglass Start",
        value: "hourglass-start",
    },
    {
        label: "House",
        value: "house",
    },
    {
        label: "House Chimney",
        value: "house-chimney",
    },
    {
        label: "House Chimney Crack",
        value: "house-chimney-crack",
    },
    {
        label: "House Chimney Medical",
        value: "house-chimney-medical",
    },
    {
        label: "House Chimney User",
        value: "house-chimney-user",
    },
    {
        label: "House Crack",
        value: "house-crack",
    },
    {
        label: "House Laptop",
        value: "house-laptop",
    },
    {
        label: "House Medical",
        value: "house-medical",
    },
    {
        label: "House User",
        value: "house-user",
    },
    {
        label: "Hryvnia Sign",
        value: "hryvnia-sign",
    },
    {
        label: "I",
        value: "i",
    },
    {
        label: "Ice Cream",
        value: "ice-cream",
    },
    {
        label: "Icicles",
        value: "icicles",
    },
    {
        label: "Icons",
        value: "icons",
    },
    {
        label: "I Cursor",
        value: "i-cursor",
    },
    {
        label: "Id Card Clip",
        value: "id-card-clip",
    },
    {
        label: "Igloo",
        value: "igloo",
    },
    {
        label: "Image Portrait",
        value: "image-portrait",
    },
    {
        label: "Inbox",
        value: "inbox",
    },
    {
        label: "Indent",
        value: "indent",
    },
    {
        label: "Indian Rupee Sign",
        value: "indian-rupee-sign",
    },
    {
        label: "Industry",
        value: "industry",
    },
    {
        label: "Infinity",
        value: "infinity",
    },
    {
        label: "Info",
        value: "info",
    },
    {
        label: "Italic",
        value: "italic",
    },
    {
        label: "J",
        value: "j",
    },
    {
        label: "Jedi",
        value: "jedi",
    },
    {
        label: "Jet Fighter",
        value: "jet-fighter",
    },
    {
        label: "Joint",
        value: "joint",
    },
    {
        label: "K",
        value: "k",
    },
    {
        label: "Kaaba",
        value: "kaaba",
    },
    {
        label: "Key",
        value: "key",
    },
    {
        label: "Khanda",
        value: "khanda",
    },
    {
        label: "Kip Sign",
        value: "kip-sign",
    },
    {
        label: "Kit Medical",
        value: "kit-medical",
    },
    {
        label: "Kiwi Bird",
        value: "kiwi-bird",
    },
    {
        label: "L",
        value: "l",
    },
    {
        label: "Landmark",
        value: "landmark",
    },
    {
        label: "Language",
        value: "language",
    },
    {
        label: "Laptop",
        value: "laptop",
    },
    {
        label: "Laptop Code",
        value: "laptop-code",
    },
    {
        label: "Laptop Medical",
        value: "laptop-medical",
    },
    {
        label: "Lari Sign",
        value: "lari-sign",
    },
    {
        label: "Layer Group",
        value: "layer-group",
    },
    {
        label: "Leaf",
        value: "leaf",
    },
    {
        label: "Left Long",
        value: "left-long",
    },
    {
        label: "Left Right",
        value: "left-right",
    },
    {
        label: "Less Than",
        value: "less-than",
    },
    {
        label: "Less Than Equal",
        value: "less-than-equal",
    },
    {
        label: "Link",
        value: "link",
    },
    {
        label: "Link Slash",
        value: "link-slash",
    },
    {
        label: "Lira Sign",
        value: "lira-sign",
    },
    {
        label: "List",
        value: "list",
    },
    {
        label: "List Check",
        value: "list-check",
    },
    {
        label: "List Ol",
        value: "list-ol",
    },
    {
        label: "List Ul",
        value: "list-ul",
    },
    {
        label: "Litecoin Sign",
        value: "litecoin-sign",
    },
    {
        label: "Location Arrow",
        value: "location-arrow",
    },
    {
        label: "Location Crosshairs",
        value: "location-crosshairs",
    },
    {
        label: "Location Dot",
        value: "location-dot",
    },
    {
        label: "Location Pin",
        value: "location-pin",
    },
    {
        label: "Lock",
        value: "lock",
    },
    {
        label: "Lock Open",
        value: "lock-open",
    },
    {
        label: "Lungs",
        value: "lungs",
    },
    {
        label: "Lungs Virus",
        value: "lungs-virus",
    },
    {
        label: "M",
        value: "m",
    },
    {
        label: "Magnet",
        value: "magnet",
    },
    {
        label: "Magnifying Glass",
        value: "magnifying-glass",
    },
    {
        label: "Magnifying Glass Dollar",
        value: "magnifying-glass-dollar",
    },
    {
        label: "Magnifying Glass Location",
        value: "magnifying-glass-location",
    },
    {
        label: "Magnifying Glass Minus",
        value: "magnifying-glass-minus",
    },
    {
        label: "Magnifying Glass Plus",
        value: "magnifying-glass-plus",
    },
    {
        label: "Manat Sign",
        value: "manat-sign",
    },
    {
        label: "Map Location",
        value: "map-location",
    },
    {
        label: "Map Location Dot",
        value: "map-location-dot",
    },
    {
        label: "Map Pin",
        value: "map-pin",
    },
    {
        label: "Marker",
        value: "marker",
    },
    {
        label: "Mars",
        value: "mars",
    },
    {
        label: "Mars And Venus",
        value: "mars-and-venus",
    },
    {
        label: "Mars Double",
        value: "mars-double",
    },
    {
        label: "Mars Stroke",
        value: "mars-stroke",
    },
    {
        label: "Mars Stroke Right",
        value: "mars-stroke-right",
    },
    {
        label: "Mars Stroke Up",
        value: "mars-stroke-up",
    },
    {
        label: "Martini Glass",
        value: "martini-glass",
    },
    {
        label: "Martini Glass Citrus",
        value: "martini-glass-citrus",
    },
    {
        label: "Martini Glass Empty",
        value: "martini-glass-empty",
    },
    {
        label: "Mask",
        value: "mask",
    },
    {
        label: "Mask Face",
        value: "mask-face",
    },
    {
        label: "Masks Theater",
        value: "masks-theater",
    },
    {
        label: "Maximize",
        value: "maximize",
    },
    {
        label: "Medal",
        value: "medal",
    },
    {
        label: "Memory",
        value: "memory",
    },
    {
        label: "Menorah",
        value: "menorah",
    },
    {
        label: "Mercury",
        value: "mercury",
    },
    {
        label: "Meteor",
        value: "meteor",
    },
    {
        label: "Microchip",
        value: "microchip",
    },
    {
        label: "Microphone",
        value: "microphone",
    },
    {
        label: "Microphone Lines",
        value: "microphone-lines",
    },
    {
        label: "Microphone Lines Slash",
        value: "microphone-lines-slash",
    },
    {
        label: "Microphone Slash",
        value: "microphone-slash",
    },
    {
        label: "Microscope",
        value: "microscope",
    },
    {
        label: "Mill Sign",
        value: "mill-sign",
    },
    {
        label: "Minimize",
        value: "minimize",
    },
    {
        label: "Minus",
        value: "minus",
    },
    {
        label: "Mitten",
        value: "mitten",
    },
    {
        label: "Mobile Button",
        value: "mobile-button",
    },
    {
        label: "Mobile Screen Button",
        value: "mobile-screen-button",
    },
    {
        label: "Money Bill",
        value: "money-bill",
    },
    {
        label: "Money Bill Wave",
        value: "money-bill-wave",
    },
    {
        label: "Money Check",
        value: "money-check",
    },
    {
        label: "Money Check Dollar",
        value: "money-check-dollar",
    },
    {
        label: "Monument",
        value: "monument",
    },
    {
        label: "Mortar Pestle",
        value: "mortar-pestle",
    },
    {
        label: "Mosque",
        value: "mosque",
    },
    {
        label: "Motorcycle",
        value: "motorcycle",
    },
    {
        label: "Mountain",
        value: "mountain",
    },
    {
        label: "Mug Hot",
        value: "mug-hot",
    },
    {
        label: "Mug Saucer",
        value: "mug-saucer",
    },
    {
        label: "Music",
        value: "music",
    },
    {
        label: "N",
        value: "n",
    },
    {
        label: "Naira Sign",
        value: "naira-sign",
    },
    {
        label: "Network Wired",
        value: "network-wired",
    },
    {
        label: "Neuter",
        value: "neuter",
    },
    {
        label: "Not Equal",
        value: "not-equal",
    },
    {
        label: "Notes Medical",
        value: "notes-medical",
    },
    {
        label: "O",
        value: "o",
    },
    {
        label: "Oil Can",
        value: "oil-can",
    },
    {
        label: "Om",
        value: "om",
    },
    {
        label: "Otter",
        value: "otter",
    },
    {
        label: "Outdent",
        value: "outdent",
    },
    {
        label: "P",
        value: "p",
    },
    {
        label: "Pager",
        value: "pager",
    },
    {
        label: "Paint Roller",
        value: "paint-roller",
    },
    {
        label: "Palette",
        value: "palette",
    },
    {
        label: "Pallet",
        value: "pallet",
    },
    {
        label: "Panorama",
        value: "panorama",
    },
    {
        label: "Paperclip",
        value: "paperclip",
    },
    {
        label: "Parachute Box",
        value: "parachute-box",
    },
    {
        label: "Paragraph",
        value: "paragraph",
    },
    {
        label: "Passport",
        value: "passport",
    },
    {
        label: "Pause",
        value: "pause",
    },
    {
        label: "Paw",
        value: "paw",
    },
    {
        label: "Peace",
        value: "peace",
    },
    {
        label: "Pen",
        value: "pen",
    },
    {
        label: "Pencil",
        value: "pencil",
    },
    {
        label: "Pen Clip",
        value: "pen-clip",
    },
    {
        label: "Pen Fancy",
        value: "pen-fancy",
    },
    {
        label: "Pen Nib",
        value: "pen-nib",
    },
    {
        label: "Pen Ruler",
        value: "pen-ruler",
    },
    {
        label: "People Arrows",
        value: "people-arrows",
    },
    {
        label: "People Carry Box",
        value: "people-carry-box",
    },
    {
        label: "Pepper Hot",
        value: "pepper-hot",
    },
    {
        label: "Percent",
        value: "percent",
    },
    {
        label: "Person",
        value: "person",
    },
    {
        label: "Person Biking",
        value: "person-biking",
    },
    {
        label: "Person Booth",
        value: "person-booth",
    },
    {
        label: "Person Dots From Line",
        value: "person-dots-from-line",
    },
    {
        label: "Person Dress",
        value: "person-dress",
    },
    {
        label: "Person Hiking",
        value: "person-hiking",
    },
    {
        label: "Person Praying",
        value: "person-praying",
    },
    {
        label: "Person Running",
        value: "person-running",
    },
    {
        label: "Person Skating",
        value: "person-skating",
    },
    {
        label: "Person Skiing",
        value: "person-skiing",
    },
    {
        label: "Person Skiing Nordic",
        value: "person-skiing-nordic",
    },
    {
        label: "Person Snowboarding",
        value: "person-snowboarding",
    },
    {
        label: "Person Swimming",
        value: "person-swimming",
    },
    {
        label: "Person Walking",
        value: "person-walking",
    },
    {
        label: "Person Walking With Cane",
        value: "person-walking-with-cane",
    },
    {
        label: "Peseta Sign",
        value: "peseta-sign",
    },
    {
        label: "Peso Sign",
        value: "peso-sign",
    },
    {
        label: "Phone",
        value: "phone",
    },
    {
        label: "Phone Flip",
        value: "phone-flip",
    },
    {
        label: "Phone Slash",
        value: "phone-slash",
    },
    {
        label: "Phone Volume",
        value: "phone-volume",
    },
    {
        label: "Photo Film",
        value: "photo-film",
    },
    {
        label: "Piggy Bank",
        value: "piggy-bank",
    },
    {
        label: "Pills",
        value: "pills",
    },
    {
        label: "Pizza Slice",
        value: "pizza-slice",
    },
    {
        label: "Place Of Worship",
        value: "place-of-worship",
    },
    {
        label: "Plane",
        value: "plane",
    },
    {
        label: "Plane Arrival",
        value: "plane-arrival",
    },
    {
        label: "Plane Departure",
        value: "plane-departure",
    },
    {
        label: "Plane Slash",
        value: "plane-slash",
    },
    {
        label: "Play",
        value: "play",
    },
    {
        label: "Plug",
        value: "plug",
    },
    {
        label: "Plus",
        value: "plus",
    },
    {
        label: "Plus Minus",
        value: "plus-minus",
    },
    {
        label: "Podcast",
        value: "podcast",
    },
    {
        label: "Poo",
        value: "poo",
    },
    {
        label: "Poop",
        value: "poop",
    },
    {
        label: "Poo Storm",
        value: "poo-storm",
    },
    {
        label: "Power Off",
        value: "power-off",
    },
    {
        label: "Prescription",
        value: "prescription",
    },
    {
        label: "Prescription Bottle",
        value: "prescription-bottle",
    },
    {
        label: "Prescription Bottle Medical",
        value: "prescription-bottle-medical",
    },
    {
        label: "Print",
        value: "print",
    },
    {
        label: "Pump Medical",
        value: "pump-medical",
    },
    {
        label: "Pump Soap",
        value: "pump-soap",
    },
    {
        label: "Puzzle Piece",
        value: "puzzle-piece",
    },
    {
        label: "Q",
        value: "q",
    },
    {
        label: "Qrcode",
        value: "qrcode",
    },
    {
        label: "Question",
        value: "question",
    },
    {
        label: "Quote Left",
        value: "quote-left",
    },
    {
        label: "Quote Right",
        value: "quote-right",
    },
    {
        label: "R",
        value: "r",
    },
    {
        label: "Radiation",
        value: "radiation",
    },
    {
        label: "Rainbow",
        value: "rainbow",
    },
    {
        label: "Receipt",
        value: "receipt",
    },
    {
        label: "Record Vinyl",
        value: "record-vinyl",
    },
    {
        label: "Rectangle Ad",
        value: "rectangle-ad",
    },
    {
        label: "Recycle",
        value: "recycle",
    },
    {
        label: "Repeat",
        value: "repeat",
    },
    {
        label: "Reply",
        value: "reply",
    },
    {
        label: "Reply All",
        value: "reply-all",
    },
    {
        label: "Republican",
        value: "republican",
    },
    {
        label: "Restroom",
        value: "restroom",
    },
    {
        label: "Retweet",
        value: "retweet",
    },
    {
        label: "Ribbon",
        value: "ribbon",
    },
    {
        label: "Right From Bracket",
        value: "right-from-bracket",
    },
    {
        label: "Right Left",
        value: "right-left",
    },
    {
        label: "Right Long",
        value: "right-long",
    },
    {
        label: "Right To Bracket",
        value: "right-to-bracket",
    },
    {
        label: "Ring",
        value: "ring",
    },
    {
        label: "Road",
        value: "road",
    },
    {
        label: "Robot",
        value: "robot",
    },
    {
        label: "Rocket",
        value: "rocket",
    },
    {
        label: "Rotate",
        value: "rotate",
    },
    {
        label: "Rotate Left",
        value: "rotate-left",
    },
    {
        label: "Rotate Right",
        value: "rotate-right",
    },
    {
        label: "Route",
        value: "route",
    },
    {
        label: "Rss",
        value: "rss",
    },
    {
        label: "Ruble Sign",
        value: "ruble-sign",
    },
    {
        label: "Ruler",
        value: "ruler",
    },
    {
        label: "Ruler Combined",
        value: "ruler-combined",
    },
    {
        label: "Ruler Horizontal",
        value: "ruler-horizontal",
    },
    {
        label: "Ruler Vertical",
        value: "ruler-vertical",
    },
    {
        label: "Rupee Sign",
        value: "rupee-sign",
    },
    {
        label: "Rupiah Sign",
        value: "rupiah-sign",
    },
    {
        label: "S",
        value: "s",
    },
    {
        label: "Sailboat",
        value: "sailboat",
    },
    {
        label: "Satellite",
        value: "satellite",
    },
    {
        label: "Satellite Dish",
        value: "satellite-dish",
    },
    {
        label: "Scale Balanced",
        value: "scale-balanced",
    },
    {
        label: "Scale Unbalanced",
        value: "scale-unbalanced",
    },
    {
        label: "Scale Unbalanced Flip",
        value: "scale-unbalanced-flip",
    },
    {
        label: "School",
        value: "school",
    },
    {
        label: "Scissors",
        value: "scissors",
    },
    {
        label: "Screwdriver",
        value: "screwdriver",
    },
    {
        label: "Screwdriver Wrench",
        value: "screwdriver-wrench",
    },
    {
        label: "Scroll",
        value: "scroll",
    },
    {
        label: "Scroll Torah",
        value: "scroll-torah",
    },
    {
        label: "Sd Card",
        value: "sd-card",
    },
    {
        label: "Section",
        value: "section",
    },
    {
        label: "Seedling",
        value: "seedling",
    },
    {
        label: "Server",
        value: "server",
    },
    {
        label: "Shapes",
        value: "shapes",
    },
    {
        label: "Share",
        value: "share",
    },
    {
        label: "Share Nodes",
        value: "share-nodes",
    },
    {
        label: "Shekel Sign",
        value: "shekel-sign",
    },
    {
        label: "Shield",
        value: "shield",
    },
    {
        label: "Shield Virus",
        value: "shield-virus",
    },
    {
        label: "Ship",
        value: "ship",
    },
    {
        label: "Shirt",
        value: "shirt",
    },
    {
        label: "Shoe Prints",
        value: "shoe-prints",
    },
    {
        label: "Shop",
        value: "shop",
    },
    {
        label: "Shop Slash",
        value: "shop-slash",
    },
    {
        label: "Shower",
        value: "shower",
    },
    {
        label: "Shrimp",
        value: "shrimp",
    },
    {
        label: "Shuffle",
        value: "shuffle",
    },
    {
        label: "Shuttle Space",
        value: "shuttle-space",
    },
    {
        label: "Signal",
        value: "signal",
    },
    {
        label: "Signature",
        value: "signature",
    },
    {
        label: "Sign Hanging",
        value: "sign-hanging",
    },
    {
        label: "Signs Post",
        value: "signs-post",
    },
    {
        label: "Sim Card",
        value: "sim-card",
    },
    {
        label: "Sink",
        value: "sink",
    },
    {
        label: "Sitemap",
        value: "sitemap",
    },
    {
        label: "Skull",
        value: "skull",
    },
    {
        label: "Skull Crossbones",
        value: "skull-crossbones",
    },
    {
        label: "Slash",
        value: "slash",
    },
    {
        label: "Sleigh",
        value: "sleigh",
    },
    {
        label: "Sliders",
        value: "sliders",
    },
    {
        label: "Smog",
        value: "smog",
    },
    {
        label: "Smoking",
        value: "smoking",
    },
    {
        label: "Snowman",
        value: "snowman",
    },
    {
        label: "Snowplow",
        value: "snowplow",
    },
    {
        label: "Soap",
        value: "soap",
    },
    {
        label: "Socks",
        value: "socks",
    },
    {
        label: "Solar Panel",
        value: "solar-panel",
    },
    {
        label: "Sort",
        value: "sort",
    },
    {
        label: "Sort Down",
        value: "sort-down",
    },
    {
        label: "Sort Up",
        value: "sort-up",
    },
    {
        label: "Spa",
        value: "spa",
    },
    {
        label: "Spaghetti Monster Flying",
        value: "spaghetti-monster-flying",
    },
    {
        label: "Spell Check",
        value: "spell-check",
    },
    {
        label: "Spider",
        value: "spider",
    },
    {
        label: "Spinner",
        value: "spinner",
    },
    {
        label: "Splotch",
        value: "splotch",
    },
    {
        label: "Spoon",
        value: "spoon",
    },
    {
        label: "Spray Can",
        value: "spray-can",
    },
    {
        label: "Spray Can Sparkles",
        value: "spray-can-sparkles",
    },
    {
        label: "Square Arrow Up Right",
        value: "square-arrow-up-right",
    },
    {
        label: "Square Envelope",
        value: "square-envelope",
    },
    {
        label: "Square H",
        value: "square-h",
    },
    {
        label: "Square Parking",
        value: "square-parking",
    },
    {
        label: "Square Pen",
        value: "square-pen",
    },
    {
        label: "Square Phone",
        value: "square-phone",
    },
    {
        label: "Square Phone Flip",
        value: "square-phone-flip",
    },
    {
        label: "Square Poll Horizontal",
        value: "square-poll-horizontal",
    },
    {
        label: "Square Poll Vertical",
        value: "square-poll-vertical",
    },
    {
        label: "Square Root Variable",
        value: "square-root-variable",
    },
    {
        label: "Square Rss",
        value: "square-rss",
    },
    {
        label: "Square Share Nodes",
        value: "square-share-nodes",
    },
    {
        label: "Square Up Right",
        value: "square-up-right",
    },
    {
        label: "Square Xmark",
        value: "square-xmark",
    },
    {
        label: "Stairs",
        value: "stairs",
    },
    {
        label: "Stamp",
        value: "stamp",
    },
    {
        label: "Star And Crescent",
        value: "star-and-crescent",
    },
    {
        label: "Star Of David",
        value: "star-of-david",
    },
    {
        label: "Star Of Life",
        value: "star-of-life",
    },
    {
        label: "Sterling Sign",
        value: "sterling-sign",
    },
    {
        label: "Stethoscope",
        value: "stethoscope",
    },
    {
        label: "Stop",
        value: "stop",
    },
    {
        label: "Stopwatch",
        value: "stopwatch",
    },
    {
        label: "Store",
        value: "store",
    },
    {
        label: "Store Slash",
        value: "store-slash",
    },
    {
        label: "Street View",
        value: "street-view",
    },
    {
        label: "Strikethrough",
        value: "strikethrough",
    },
    {
        label: "Stroopwafel",
        value: "stroopwafel",
    },
    {
        label: "Subscript",
        value: "subscript",
    },
    {
        label: "Suitcase",
        value: "suitcase",
    },
    {
        label: "Suitcase Medical",
        value: "suitcase-medical",
    },
    {
        label: "Suitcase Rolling",
        value: "suitcase-rolling",
    },
    {
        label: "Superscript",
        value: "superscript",
    },
    {
        label: "Swatchbook",
        value: "swatchbook",
    },
    {
        label: "Synagogue",
        value: "synagogue",
    },
    {
        label: "Syringe",
        value: "syringe",
    },
    {
        label: "T",
        value: "t",
    },
    {
        label: "Table",
        value: "table",
    },
    {
        label: "Table Cells",
        value: "table-cells",
    },
    {
        label: "Table Cells Large",
        value: "table-cells-large",
    },
    {
        label: "Table Columns",
        value: "table-columns",
    },
    {
        label: "Table List",
        value: "table-list",
    },
    {
        label: "Tablet Button",
        value: "tablet-button",
    },
    {
        label: "Table Tennis Paddle Ball",
        value: "table-tennis-paddle-ball",
    },
    {
        label: "Tablets",
        value: "tablets",
    },
    {
        label: "Tablet Screen Button",
        value: "tablet-screen-button",
    },
    {
        label: "Tachograph Digital",
        value: "tachograph-digital",
    },
    {
        label: "Tag",
        value: "tag",
    },
    {
        label: "Tags",
        value: "tags",
    },
    {
        label: "Tape",
        value: "tape",
    },
    {
        label: "Taxi",
        value: "taxi",
    },
    {
        label: "Teeth",
        value: "teeth",
    },
    {
        label: "Teeth Open",
        value: "teeth-open",
    },
    {
        label: "Temperature Empty",
        value: "temperature-empty",
    },
    {
        label: "Temperature Full",
        value: "temperature-full",
    },
    {
        label: "Temperature Half",
        value: "temperature-half",
    },
    {
        label: "Temperature High",
        value: "temperature-high",
    },
    {
        label: "Temperature Low",
        value: "temperature-low",
    },
    {
        label: "Temperature Quarter",
        value: "temperature-quarter",
    },
    {
        label: "Temperature Three Quarters",
        value: "temperature-three-quarters",
    },
    {
        label: "Tenge Sign",
        value: "tenge-sign",
    },
    {
        label: "Terminal",
        value: "terminal",
    },
    {
        label: "Text Height",
        value: "text-height",
    },
    {
        label: "Text Slash",
        value: "text-slash",
    },
    {
        label: "Text Width",
        value: "text-width",
    },
    {
        label: "Thermometer",
        value: "thermometer",
    },
    {
        label: "Thumbtack",
        value: "thumbtack",
    },
    {
        label: "Ticket",
        value: "ticket",
    },
    {
        label: "Ticket Simple",
        value: "ticket-simple",
    },
    {
        label: "Timeline",
        value: "timeline",
    },
    {
        label: "Toggle Off",
        value: "toggle-off",
    },
    {
        label: "Toggle On",
        value: "toggle-on",
    },
    {
        label: "Toilet",
        value: "toilet",
    },
    {
        label: "Toilet Paper",
        value: "toilet-paper",
    },
    {
        label: "Toilet Paper Slash",
        value: "toilet-paper-slash",
    },
    {
        label: "Toolbox",
        value: "toolbox",
    },
    {
        label: "Tooth",
        value: "tooth",
    },
    {
        label: "Torii Gate",
        value: "torii-gate",
    },
    {
        label: "Tower Broadcast",
        value: "tower-broadcast",
    },
    {
        label: "Tractor",
        value: "tractor",
    },
    {
        label: "Trademark",
        value: "trademark",
    },
    {
        label: "Traffic Light",
        value: "traffic-light",
    },
    {
        label: "Trailer",
        value: "trailer",
    },
    {
        label: "Train",
        value: "train",
    },
    {
        label: "Train Subway",
        value: "train-subway",
    },
    {
        label: "Train Tram",
        value: "train-tram",
    },
    {
        label: "Transgender",
        value: "transgender",
    },
    {
        label: "Trash",
        value: "trash",
    },
    {
        label: "Trash Arrow Up",
        value: "trash-arrow-up",
    },
    {
        label: "Trash Can Arrow Up",
        value: "trash-can-arrow-up",
    },
    {
        label: "Tree",
        value: "tree",
    },
    {
        label: "Triangle Exclamation",
        value: "triangle-exclamation",
    },
    {
        label: "Trophy",
        value: "trophy",
    },
    {
        label: "Truck",
        value: "truck",
    },
    {
        label: "Truck Fast",
        value: "truck-fast",
    },
    {
        label: "Truck Medical",
        value: "truck-medical",
    },
    {
        label: "Truck Monster",
        value: "truck-monster",
    },
    {
        label: "Truck Moving",
        value: "truck-moving",
    },
    {
        label: "Truck Pickup",
        value: "truck-pickup",
    },
    {
        label: "Truck Ramp Box",
        value: "truck-ramp-box",
    },
    {
        label: "Tty",
        value: "tty",
    },
    {
        label: "Turkish Lira Sign",
        value: "turkish-lira-sign",
    },
    {
        label: "Turn Down",
        value: "turn-down",
    },
    {
        label: "Turn Up",
        value: "turn-up",
    },
    {
        label: "Tv",
        value: "tv",
    },
    {
        label: "U",
        value: "u",
    },
    {
        label: "Umbrella",
        value: "umbrella",
    },
    {
        label: "Umbrella Beach",
        value: "umbrella-beach",
    },
    {
        label: "Underline",
        value: "underline",
    },
    {
        label: "Universal Access",
        value: "universal-access",
    },
    {
        label: "Unlock",
        value: "unlock",
    },
    {
        label: "Unlock Keyhole",
        value: "unlock-keyhole",
    },
    {
        label: "Up Down",
        value: "up-down",
    },
    {
        label: "Up Down Left Right",
        value: "up-down-left-right",
    },
    {
        label: "Upload",
        value: "upload",
    },
    {
        label: "Up Long",
        value: "up-long",
    },
    {
        label: "Up Right And Down Left From Center",
        value: "up-right-and-down-left-from-center",
    },
    {
        label: "Up Right From Square",
        value: "up-right-from-square",
    },
    {
        label: "User Astronaut",
        value: "user-astronaut",
    },
    {
        label: "User Check",
        value: "user-check",
    },
    {
        label: "User Clock",
        value: "user-clock",
    },
    {
        label: "User Doctor",
        value: "user-doctor",
    },
    {
        label: "User Gear",
        value: "user-gear",
    },
    {
        label: "User Graduate",
        value: "user-graduate",
    },
    {
        label: "User Group",
        value: "user-group",
    },
    {
        label: "User Injured",
        value: "user-injured",
    },
    {
        label: "User Large",
        value: "user-large",
    },
    {
        label: "User Large Slash",
        value: "user-large-slash",
    },
    {
        label: "User Lock",
        value: "user-lock",
    },
    {
        label: "User Minus",
        value: "user-minus",
    },
    {
        label: "User Ninja",
        value: "user-ninja",
    },
    {
        label: "User Nurse",
        value: "user-nurse",
    },
    {
        label: "User Pen",
        value: "user-pen",
    },
    {
        label: "User Plus",
        value: "user-plus",
    },
    {
        label: "Users",
        value: "users",
    },
    {
        label: "User Secret",
        value: "user-secret",
    },
    {
        label: "Users Gear",
        value: "users-gear",
    },
    {
        label: "User Shield",
        value: "user-shield",
    },
    {
        label: "User Slash",
        value: "user-slash",
    },
    {
        label: "Users Slash",
        value: "users-slash",
    },
    {
        label: "User Tag",
        value: "user-tag",
    },
    {
        label: "User Tie",
        value: "user-tie",
    },
    {
        label: "User Xmark",
        value: "user-xmark",
    },
    {
        label: "Utensils",
        value: "utensils",
    },
    {
        label: "V",
        value: "v",
    },
    {
        label: "Van Shuttle",
        value: "van-shuttle",
    },
    {
        label: "Vault",
        value: "vault",
    },
    {
        label: "Vector Square",
        value: "vector-square",
    },
    {
        label: "Venus",
        value: "venus",
    },
    {
        label: "Venus Double",
        value: "venus-double",
    },
    {
        label: "Venus Mars",
        value: "venus-mars",
    },
    {
        label: "Vest",
        value: "vest",
    },
    {
        label: "Vest Patches",
        value: "vest-patches",
    },
    {
        label: "Vial",
        value: "vial",
    },
    {
        label: "Vials",
        value: "vials",
    },
    {
        label: "Video",
        value: "video",
    },
    {
        label: "Video Slash",
        value: "video-slash",
    },
    {
        label: "Vihara",
        value: "vihara",
    },
    {
        label: "Virus",
        value: "virus",
    },
    {
        label: "Viruses",
        value: "viruses",
    },
    {
        label: "Virus Slash",
        value: "virus-slash",
    },
    {
        label: "Voicemail",
        value: "voicemail",
    },
    {
        label: "Volleyball",
        value: "volleyball",
    },
    {
        label: "Volume High",
        value: "volume-high",
    },
    {
        label: "Volume Low",
        value: "volume-low",
    },
    {
        label: "Volume Off",
        value: "volume-off",
    },
    {
        label: "Volume Xmark",
        value: "volume-xmark",
    },
    {
        label: "Vr Cardboard",
        value: "vr-cardboard",
    },
    {
        label: "W",
        value: "w",
    },
    {
        label: "Wallet",
        value: "wallet",
    },
    {
        label: "Wand Magic",
        value: "wand-magic",
    },
    {
        label: "Wand Magic Sparkles",
        value: "wand-magic-sparkles",
    },
    {
        label: "Warehouse",
        value: "warehouse",
    },
    {
        label: "Water",
        value: "water",
    },
    {
        label: "Water Ladder",
        value: "water-ladder",
    },
    {
        label: "Wave Square",
        value: "wave-square",
    },
    {
        label: "Weight Hanging",
        value: "weight-hanging",
    },
    {
        label: "Weight Scale",
        value: "weight-scale",
    },
    {
        label: "Wheelchair",
        value: "wheelchair",
    },
    {
        label: "Whiskey Glass",
        value: "whiskey-glass",
    },
    {
        label: "Wifi",
        value: "wifi",
    },
    {
        label: "Wind",
        value: "wind",
    },
    {
        label: "Wine Bottle",
        value: "wine-bottle",
    },
    {
        label: "Wine Glass",
        value: "wine-glass",
    },
    {
        label: "Wine Glass Empty",
        value: "wine-glass-empty",
    },
    {
        label: "Won Sign",
        value: "won-sign",
    },
    {
        label: "Wrench",
        value: "wrench",
    },
    {
        label: "X",
        value: "x",
    },
    {
        label: "Xmark",
        value: "xmark",
    },
    {
        label: "X Ray",
        value: "x-ray",
    },
    {
        label: "Y",
        value: "y",
    },
    {
        label: "Yen Sign",
        value: "yen-sign",
    },
    {
        label: "Yin Yang",
        value: "yin-yang",
    },
    {
        label: "Z",
        value: "z",
    },
];
