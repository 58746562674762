import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Input } from "reactstrap";
import Icon from "../icon";
import { withTranslation } from "react-i18next";
import CustomModalInput from "../custom-modal-elements/custom-modal-input";

// TODO implement logic to connect with backend
// Should search within code and external_code

const FilterWithBarcode = (props) => {
    const wrapperRef = useRef(null);
    const [_filters, setFilters] = useState([] as any);

    const useOutsideHider = (ref) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.handleShowFilter();
                }
            };

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);

            // Unbind the event listener on clean up
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }, [ref]);
    };

    useEffect(() => {
        const filters = [{ id: 0, column: "id", value: props.barcodeValue }];
        setFilters(filters);
    }, [props.filters]);

    useOutsideHider(wrapperRef);

    const handleChange = (e) => {
        const value = e.target.value;
        const filters = _filters;
        if (value) {
            filters[0]["value"] = value;
        }
        setFilters(filters);
    };

    const handleRefresh = () => {
        props.handleReload(_filters);
    };

    const handleDeleteFilter = () => {
        const filters = [{ id: 0, column: "", value: "" }];
        props.handleReload(filters);
    };

    const handleEnterPress = (event) => {
        const key = event.keyCode || event.charCode;
        if (key == 13) {
            handleRefresh();
        }
    };

    return (
        <div className="popup" ref={wrapperRef}>
            <Row>
                <Col sm="1">
                    <Icon className="icon ml-10 mt-20 mb-5" name={["fas", "barcode"]} color="#aaa" />
                </Col>
                <Col sm="9">
                    <CustomModalInput
                        handleKeyDown={handleEnterPress}
                        className="mt-10"
                        noLabel
                        handleChange={handleChange}
                        placeholder={props.t("Scan barcode") + "..."}
                        defaultValue={props.barcodeValue}
                    />
                </Col>
                <Col sm="2">
                    <span className="btn btn-custom-round ml-5 mt-20 mr-10 mb-5" onClick={handleRefresh} title={props.t("Filter")}>
                        <Icon className="icon" name={["fas", "search"]} color="#aaa" />
                    </span>
                    <span
                        className="btn btn-custom-round ml-5 mt-20 mr-10 mb-5"
                        onClick={handleDeleteFilter}
                        title={props.t("Remove filter")}
                    >
                        <Icon name={["fas", "minus"]} color="#ef5164ee" />
                    </span>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(FilterWithBarcode);
