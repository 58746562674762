import React, { MutableRefObject, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

import { handleKeyMoveFirstChildElement, handleKeyMoveList } from "../../../utils/moveFunctions";
import CustomModalHeader from "../../custom-modal-elements/custom-modal-header";
import { highlightTextIncluded } from "../highlightTextIncluded";
import CustomSpinner from "../../custom-spinner/custom-spinner";
import { TooltipHOC } from "../../gt-tooltip/gt-tooltip-hoc";
import { useGlobalSearch } from "../hook/useGlobalSearch";
import PrettyCell from "../../prime-data-table/body-components/pretty-cell/pretty-cell";
import { mapModelToRoute } from "../mapModelToRoute";
import { ISearchData } from "../interface";
import { NoResults } from "./noResults";
import Icon from "../../icon";

interface IModalGlobalSearchProps {
    isModalOpen: boolean;
    closeModal: () => void;
    inputRef: MutableRefObject<HTMLInputElement | null>;
    navbarInput: string;
    searchData: ISearchData;
}
export const ModalGlobalSearch = ({ isModalOpen, closeModal, inputRef, navbarInput, searchData }: IModalGlobalSearchProps) => {
    const [inputValue, setInputValue] = useState<string>(navbarInput);

    const ref = useRef<HTMLDivElement | null>(null);

    const handleChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
    };

    const { t } = useTranslation();

    const { results, isLoading } = useGlobalSearch({ input: inputValue });

    return (
        <Modal isOpen={isModalOpen} toggle={closeModal} fade>
            <CustomModalHeader handlecloseModal={closeModal} name="Search" />
            <ModalBody>
                <form className="global-search" action="#" onSubmit={(e) => e.preventDefault()}>
                    <button type="button" className="btn btn-custom-round ">
                        <Icon name="search" />
                    </button>
                    <div className="search-input-container">
                        <input
                            type="search"
                            className="form-control form-control-clean modal-search"
                            placeholder="Type to search..."
                            ref={inputRef}
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={(e) => handleKeyMoveFirstChildElement(e, ref)}
                        />
                    </div>
                </form>
                {isLoading ? (
                    <CustomSpinner />
                ) : Object.values(results || searchData).every((value) => !value.length) ? (
                    <NoResults />
                ) : (
                    <div className="results" ref={ref}>
                        {Object.entries(results || searchData).map(([key, value]) =>
                            value.map((r) => (
                                <Link
                                    to={mapModelToRoute(key, r)}
                                    className="result-link"
                                    onClick={closeModal}
                                    onKeyDown={handleKeyMoveList}
                                >
                                    <div className="result-grid">
                                        <div>
                                            <h1 className="result-item">{r.code || `${t("Result")}: ${r.analyte}`}</h1>
                                            <strong>{t("Found in")}:</strong>
                                            <div className="result-match">
                                                {r.matches.map((match) => (
                                                    <span>
                                                        {`${match?.parentField || ""} ${match?.field}:`}&nbsp;
                                                        <TooltipHOC tooltipContent={match?.value}>
                                                            {highlightTextIncluded(match?.value, inputValue)}
                                                        </TooltipHOC>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="result-key">
                                            {t(key)}

                                            <TooltipHOC tooltipContent={key == "sample" ? "Sample status" : "Order status"}>
                                                <PrettyCell text={r?.status} />
                                            </TooltipHOC>
                                            <TooltipHOC tooltipContent={"Created date"}>
                                                <div>{r.loggedDt || r.createdDt}</div>
                                            </TooltipHOC>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};
