import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { Form, Modal, ModalBody } from "reactstrap";
import Axios from "axios";

import { tableUrls } from "components/prime-data-table/utils/table-urls";
import { handleErrorDelete } from "utils/handleDeleteError";
import * as I from "components/prime-data-table/interfaces";
import * as GT from "components";

interface IAdditionalColumnsModalProps {
    mode: "add" | "edit";
    closeModal: () => void;
    meta: I.IMeta;
    columnField: string;
    handleAdditionalColumns: I.HandleAdditionalColumns;
}

const extraFieldsAllPossibleKeys: I.ExtraFieldsKeys[] = [
    "extraField1",
    "extraField2",
    "extraField3",
    "extraField4",
    "extraField5",
    "extraField6",
    "extraField7",
    "extraField8",
    "extraField9",
    "extraField10",
];

const typeOptions: ILabelValue<I.ExtraFieldTypes, "String" | "Date" | "Integer" | "Number">[] = [
    { label: "String", value: "str" },
    { label: "Date", value: "date" },
    { label: "Integer", value: "int" },
    { label: "Number", value: "float" },
];

export const AddEditAdditionalColumnsModal = ({
    closeModal,
    meta,
    columnField,
    handleAdditionalColumns,
    mode,
}: IAdditionalColumnsModalProps) => {
    const extraFields = meta.ExtraFields;

    const [data, setData] = useState<Partial<I.ExtraField> | undefined>(mode === "edit" ? extraFields[columnField] : undefined);

    const [errors, setErrors] = useState<IErrors>({});

    const handleCloseModal = () => closeModal();

    const handleSubmitData = async (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        let _errors: IErrors = {};

        if (!data?.name && !data?.type) _errors = { name: "Column name is required", type: "Column type is required" };
        else if (!data?.name) _errors = { name: "Column name is required" };
        else if (!data?.type) _errors = { type: "Column type is required" };

        if (Object.keys(_errors).length > 0) {
            setErrors(_errors);
            return;
        }

        try {
            const _data = data as I.ExtraField;
            let _extraFields: I.ExtraFields = { ...extraFields };
            let column: I.IPrimeTableColumn = {
                sortable: true,
                noHeaderTranslation: true,
                additionalType: data?.type,
                header: _data.name,
                field: columnField,
            };

            if (mode == "edit")
                _extraFields[columnField] = data?.type != extraFields[columnField]?.type ? { ..._data, clean: true } : _data;
            else {
                const possibleKey: I.ExtraFieldsKeys = extraFieldsAllPossibleKeys.filter(
                    (possibleKey) => !Object.keys(extraFields).includes(possibleKey)
                )[0];

                _extraFields[possibleKey] = data as I.ExtraField;
                column.field = possibleKey;
            }

            await Axios.post(tableUrls.additionalColumns, { info: _extraFields, modelLabel: meta.ModelInfo?.appModelLabel });

            handleAdditionalColumns(
                mode == "edit"
                    ? { payload: { column: column }, action: I.ADDITIONAL_COLUMNS_EVENT_ACTION.EDIT }
                    : {
                          payload: { column: column, selectedContextColumnField: columnField },
                          action: I.ADDITIONAL_COLUMNS_EVENT_ACTION.ADD,
                      }
            );

            closeModal();
        } catch (e: any) {
            e.response?.status == "400" && setErrors(e.response.data);

            if (e.response?.status == "403") return e.response.data;
            else return e.response.status;
        }
    };

    const handleChangeState = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value || "" });
        errors[name] && setErrors(handleErrorDelete(errors, name));
    };

    return (
        <Modal isOpen={true} toggle={() => closeModal()} fade>
            <Form onSubmit={handleSubmitData}>
                <GT.ModalHeader handlecloseModal={handleCloseModal} name="Additional columns" />
                <ModalBody>
                    <GT.CustomInput
                        autofocus
                        errors={errors.name}
                        handleChange={handleChangeState}
                        labelText="Name"
                        type="text"
                        name="name"
                        value={data?.name || ""}
                    />
                    <GT.CustomSelect
                        translateOptions
                        errors={errors.type}
                        name="type"
                        labelName="Type"
                        options={typeOptions}
                        handleChange={handleChangeState}
                        value={typeOptions?.find((o) => o.value == data?.type) || null}
                    />
                </ModalBody>
                <GT.ModalFooter closeModal={handleCloseModal} cancelName="Cancel" okName="Save" />
            </Form>
        </Modal>
    );
};
