import { useEffect, useState } from "react";
import Axios from "axios";

interface IUseFormData<T, K> {
    url: string | string[] | undefined;
    mapFormData?: (arg: K) => T;
    afterFetch?: Function;
    triggerValues?: any[];
    shouldFetch?: any;
    params?: any;
    defaultEmptyValue?: any;
}

export function useFormData<T = any, K = T>({
    url,
    mapFormData,
    afterFetch,
    triggerValues = [],
    shouldFetch = undefined,
    params = {},
    defaultEmptyValue = null,
}: IUseFormData<T, K>) {
    const [formData, setFormData] = useState<T | undefined | null>(undefined);

    const getFormData = async () => {
        if (!url) {
            setFormData(defaultEmptyValue);
            afterFetch && afterFetch(defaultEmptyValue);
            return;
        }
        try {
            let data;

            if (typeof url == "string") {
                const response = await Axios.get(url, params);
                data = response.data.data ? response.data.data : response.data;
            } else {
                data = await Promise.all(url.map((url, index) => Axios.get(url, params?.[index])));
                data = data.map((response) => response.data);
            }

            const formDataToSet = mapFormData ? mapFormData(data) : data;

            setFormData(formDataToSet);
            afterFetch && afterFetch(formDataToSet);
        } catch {}
    };

    useEffect(() => {
        shouldFetch != false && getFormData();
    }, [...triggerValues, shouldFetch]);

    return { formData, setFormData, refreshFormData: getFormData };
}
