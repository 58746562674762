// internal
import React, { useEffect , useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from 'react-i18next';
import { XCircle } from "react-feather";
import Icon from "../icon";

// external
import ExportCSV from "./csv-export";
import CustomFilterMenu from "./custom-filter";
import FilterWithBarcode from "./filter-barcode"
import AuditWindow from "../audit-window/index";
import { IDataSet } from "./interface";

// style
import './style.scss'
import { initColumnResizer, destroyColumnResizer, updateColumnResizer } from "./column-resize";

const CustomDataTable = (props) => {
    const [dataSet, setDataSet] = useState([] as IDataSet[])
    const [columns, setColumns] = useState([])
    const [lastSelIndex, setlastSelIndex] = useState(-1)
    const [showMenuFilter, setShowMenuFilter] = useState(false)
    const [selectedIds, setSelectedIds] = useState([] as any)
    const [filters, setFilters] = useState([] as any)
    const [barcodeValue, setBarcodeValue] = useState('')
    const wrapperRef = useRef(null)
    const [showScanBarcode, setShowScanBarcode] = useState(false)
    const [pageSize, setPageSize] = useState<number>(10)

    useEffect(() => {
        const _dataSet = props.dataSet
        if (!(props.notSelectable || _dataSet.length < 1 || props.notSelectFirst) && !_dataSet.filter(o=>o.isSelected).length ){
            _dataSet[0].isSelected = true;
            setlastSelIndex(0)
            if (props.handleSelected) props.handleSelected([_dataSet[0].id], 0)
            setSelectedIds(_dataSet[0][props.keyField || 'id'])
        }else{
            setlastSelIndex(-1)
        }
        
        setDataSet(_dataSet)

        if(props.refreshTable)
            setColumns(props.columns.map( c => ({ ...c , name: props.t(c.name) }) ))

        if (_dataSet.length > 0){
            // Initialize column resizer (binds events)
            initColumnResizer(wrapperRef.current)
            updateColumnResizer(wrapperRef.current)
        }
    }, [props.dataSet]);

    useEffect(() => {
        // Clean up column resizer (unbinds events)
        return () => destroyColumnResizer(wrapperRef.current)
    }, [])

    useEffect(() => { 
        setColumns(props.columns.map( c => ({ ...c , name: props.t(c.name) }) ))
        
        // Initialize column resizer (binds events)
        initColumnResizer(wrapperRef.current)
        
    }, [props.columns]);

    const _setPage = async (page) => {
        const search = `?pageSize=${props.meta.pageSize}&page=${page}`;
        !props.clientPagination && await props.handleReload(search);
        updateColumnResizer(wrapperRef.current)
    };

    const _setSizePage = async (pageSize) => {
        const search = `?pageSize=${pageSize}&page=1`;
        !props.clientPagination && await props.handleReload(search);
        setPageSize(pageSize);
        updateColumnResizer(wrapperRef.current)
    };

    const _handleClicked = (row, e) => {
        let data = dataSet
        let selectorId = props.keyField || 'id'
        let index = data.findIndex((o) => o[selectorId] == row[selectorId])
        let prevIndex = lastSelIndex;

        if (e.shiftKey && !props.singleSelect) {
            let i = index;
            if (prevIndex < i) {
                [i, prevIndex] = [prevIndex + 1, i + 1];
            }
            for (; i < prevIndex; i++) {
                data[i].isSelected = !data[i].isSelected;
            }
        } else {
            if (!e.ctrlKey || props.singleSelect) {
                if (index == prevIndex) return;
                data = data.map((o) => ({ ...o, isSelected: false }));
            }
            data[index].isSelected = !data[index].isSelected;
        }

        setDataSet(data)
        setlastSelIndex(index)
        
        const _selectedIds = data.filter((o) => o.isSelected).map((o) => o[selectorId])
        setSelectedIds(_selectedIds)

        if (props.handleSelected) props.handleSelected(_selectedIds, index);
    };

    const handleShowFilter = () => {

        if(filters.length == 0){
            const urlParams = new URLSearchParams(window.location.search)
            if(urlParams.has('barCode')){
                setShowMenuFilter(!showMenuFilter)
                return
            }
            const search = urlParams.getAll('search')
            const search_field = urlParams.getAll('search_field')
            
            setFilters(search_field.map( (o, i) => {
                const col_cond = o.split('__')
                return { column: col_cond[0], condition: col_cond[1] && '__'+col_cond[1], value:search[i] }
            }))
        }
     
        setShowMenuFilter(!showMenuFilter)
    }
    const handleShowBarCodeFilter = () => {
        setShowScanBarcode(!showScanBarcode)
    }

    const handleCloseMenuFilter = (filters) => {
        setShowMenuFilter(false)
        const search = filters.map( o=> (o.value ? `search_field=${o.column}${o.condition}&search=${o.value}&pageSize=${pageSize}` : null))
        !props.clientPagination && props.handleReload('?'+search.join('&'))
        setFilters(filters)
    }
    const handleCloseMenuFilterWithBarcode = (barcodeFilters) => {
        setShowScanBarcode(false)
        const search = barcodeFilters.map( o=> (o.value ? `search_field=${o.column}&search=${o.value}` : null))
        !props.clientPagination && props.handleReload('?'+search.join('&')+'&barCode=1')
        try{
            setBarcodeValue(barcodeFilters[0].value)
        }
        catch (err){
            console.log(err)
        }
        }

    const noDataComponent = (
        <div className="rdt_noDataComponent">  
            <div className="rdt_xcircleContainer"> 
                <XCircle/> 
            </div>
            <div> 
                { props.t('There are no records to display') } 
            </div>
        </div>
    )

    const getValueFromNestedObj = (obj: object, field : string) => {
        if (field?.indexOf('.') == -1) return obj[field]
        const keys = (field??'').split('.')
        keys.forEach( key => obj = obj[key] )
        return obj || ''
    }

    // KM: custom function so we can sort numberical varlues properly (e.g. 33 > 2)
    //     in the future we can handle server-sided sorting in here as well
    const sortFunction = (rows, field, direction) => {
        
        const dir = direction == 'asc' ? -1 : 1
        if(!(rows.map(e => e[field]).some(isNaN))){

            return rows.sort( ( a, b ) => {
                const a_val = getValueFromNestedObj(a, field)
                const b_val = getValueFromNestedObj(b, field)
                const af = isNaN(Number(a_val)) ? a_val.toLowerCase() : Number(a_val)
                const bf = isNaN(Number(b_val)) ? b_val.toLowerCase() : Number(b_val)
                if ( af < bf ) return dir * -1
                if ( af > bf ) return dir * 1
                return 0
            })
        }
        else{
            return rows.sort( ( a, b ) => {
                const af = getValueFromNestedObj(a, field)?.toLowerCase() || ''
                const bf = getValueFromNestedObj(b, field)?.toLowerCase() || ''
                if ( af < bf ) return dir * -1
                if ( af > bf ) return dir * 1
                return 0
            })
        }
    }

    return (
        <>

        <div className="floating-icons">

            {/* darek darek darek - [true] show only if you have export csv permission? */}
            { true && props.exportCSV && dataSet.length > 0 && <ExportCSV /* columns={columns} */ dataSet={dataSet} dataColumns={columns}/>}

            {/* darek darek darek - [true] show only if you have audit permission? */}
            { true && props.auditData && (
                <>
                    <div className='floating-icon mr-10'>
                        <AuditWindow auditData={props.auditData} instanceIds={selectedIds} tableColumns={columns}/>
                    </div>
                </>
            )}

            { !props.hideFilter && (filters.length || dataSet.length > 0) &&
                <div className='floating-icon filter-data-table-container'>
                    <span title={props.t("Filter")} className="btn btn-custom-round mr-10 mt-5" onMouseDown={ handleShowFilter }>
                        <Icon name={['fas',"filter"]} color={ showMenuFilter ? '#725ec3' : ''}/>
                    </span>
                    {showMenuFilter && <CustomFilterMenu handleShowFilter={handleShowFilter} columns={columns} handleReload={handleCloseMenuFilter} filters={filters}/> }
                </div>
            }
            { props.displayScanBarcode &&
                <div className='floating-icon filter-data-table-container'>
                    <span title={props.t("Filter with barcode")} className="btn btn-custom-round ml-10 mr-10 mt-5" onMouseDown={ () => setShowScanBarcode(!showScanBarcode) }>
                        <Icon name={['fas',"barcode"]} color={ showScanBarcode ? '#725ec3' : ''}/>
                    </span>
                    {showScanBarcode && <FilterWithBarcode handleShowFilter={handleShowBarCodeFilter} handleReload={handleCloseMenuFilterWithBarcode} barcodeValue = {barcodeValue}/> }
                </div>
            }
        </div>

        <div ref={wrapperRef}>
        <DataTable
            fixedHeader={props.fixedHeader}
            fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
            className={`custom-data-table mt-10 ${props.className || ''} ${showMenuFilter || showScanBarcode ? 'table-filtering' : ''}`}
            noHeader
            pagination={props.noPagination ? false : true}
            { ...(props.clientPagination ? {
                paginationPerPage: props.paginationPerPage || 10
            } : {
                paginationServer: true,
                paginationDefaultPage:props.meta && props.meta.page,
                paginationTotalRows:props.meta && props.meta.count,
                paginationPerPage:props.meta && props.meta.pageSize
            })}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            onChangeRowsPerPage={(pageSize) => _setSizePage(pageSize)}
            onChangePage={(page) => _setPage(page)}
            onRowClicked={!props.notSelectable ? _handleClicked : (_) => {}}
            onRowDoubleClicked={props.handleDoubleClick}
            conditionalRowStyles={props.customRowStyle ? props.customRowStyle(props.notSelectable) : conditionalRowStyles(props.notSelectable, props.handleDoubleClick ? true : false)}
            dense
            columns={columns}
            data={dataSet}
            sortIcon={<Icon className="ml-4" name={'chevron-down'} />}
            disabled={props.disabled}
            noDataComponent={noDataComponent}
            paginationComponentOptions={{ 
                rowsPerPageText: props.t('Rows per page'), 
                rangeSeparatorText: props.t('of')
            }}
            keyField={props.keyField || 'id'}
            sortFunction={sortFunction}
        />
        </div>
    </>
    );

}

const conditionalRowStyles = (isNotSelectable, isClicable) => {
    
    let selectedStyle = {
        when: (row) => row.isSelected,
        style: {
            userSelect: 'none',
            borderTopStyle: 'hidden !important',
            backgroundColor: "#2baf78",
            color: '#fff !important;',
            fontWeight: 'bold !important;',
            "input": {
                color: '#fff !important;',
            },
            "select": {
                color: '#fff !important;',
            },
            "div": {
                color: '#fff !important;',
            },
            "textarea": {
                color: '#fff !important;',
            },
            ".rdt_TableCell": {
                "> div:not(.flexible-cell)": {
                    wordBreak: 'break-word !important',
                    whiteSpace: 'break-spaces !important',
                    padding: '4px 0',
                },
                ".table-cell-select": {
                    padding: '0 !important',
                },
                ":not(:last-child)": {
                    borderRight: '1px solid #2cb77d',
                },
            },
            "&:hover": {
                backgroundColor: "#1FB777",
                ".rdt_TableCell": {
                    ":not(:last-child)": {
                        borderRight: '1px solid #2bad76',
                    },
                }
            }
        },
    } as any
    
    let notSelectedStyle = {
        when: (row) => !row.isSelected,
        style: {
            "&:hover": {
                backgroundColor: "rgba(1, 1, 1, 0.05)",
                cursor: "pointer"
            },
            ".rdt_TableCell": {
                "> div:not(.flexible-cell)": {
                    maxHeight: '27px' 
                },
                ":not(:last-child)": {
                    borderRight: '1px solid #e6ecf0',
                },
            },
        },
    } as any

    if (isNotSelectable && !isClicable){
        notSelectedStyle.style["&:hover"] = { cursor: "default" }
    }

    return [ selectedStyle, notSelectedStyle ]
}

export default withTranslation()(CustomDataTable);
